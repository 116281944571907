// menu.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('menu', menu);

    // Inject dependencies.
    menu.$inject = [
        'MenuService',
        '$location',
        '$timeout',
        '$state',
        'BASEURL',
        'MediaQueryService',
        '$rootScope'
    ];

    function menu() {
        var directive = {
            controller: controller,
            controllerAs: 'vm',
            restrict: 'E',
            transclude: true,
            templateUrl: '/templates/menu.html'
        };

        return directive;

        function controller(
            $scope,
            MenuService,
            $location,
            $timeout,
            $state,
            BASEURL,
            MediaQueryService,
            $rootScope
        ) {
            $scope.BASEURL = BASEURL;
            $scope.hover = true;
            $scope.media = MediaQueryService.getMedia(); // Set mobile flag

            MenuService.getMenu().then(
                function(data) {
                    $scope.menu = data;
                },
                function(data) {
                    $scope.error = data;
                    $scope.menu = [];
                }
            );

            // Wait for the $state to be initialized correctly and then assign it to scope variable
            $timeout(function() {
                $scope.state = $state;
            }, 200);

            $scope.showMenu = function() {
                $timeout(function() {
                    $rootScope.showMenuFlag = !$rootScope.showMenuFlag;

                    if ($rootScope.showMenuFlag) {
                        $('menu li.menu-entry.has-submenu.active>a.has-submenu').click();
                    }
                });
            };

            $scope.$on('showMenuEvent', function() {
                $scope.showMenu();
            });

            /**
             * Open submenu or link directly
             */
            $scope.openMenu = function(event, element) {
                if (element.submenu) {
                    if ($scope.hover) {
                        $scope.closeSubMenu();
                        $('menu').addClass('submenu-active');
                        $(event.target).parent().addClass('show-submenu');
                    }
                } else {

                    $scope.closeSubMenu();
                    $timeout(function() {
                        $state.go(element.stateName, {}, {
                            reload: true
                        });
                    });

                    $rootScope.showMenuFlag = false;

                    // Disable hover to avoid mouseenter direct after click on submenu.
                    $scope.hover = false;

                    $timeout(function() {
                        $scope.hover = true;
                    }, 150);
                }
            };

            //Close submenu
            $scope.closeSubMenu = function() {
                $('menu').removeClass('submenu-active');
                $('menu .show-submenu').removeClass('show-submenu');
            };

            // Close menu on mouseleave
            $('menu').mouseleave(function() {
                if ($scope.media === 'regular') {
                    $scope.closeSubMenu();
                }
            });

            $scope.toggleTitle = function(event, set) {
                var el = angular.element(event.target);

                if (set) {  // set title
                    el.attr('title', el.attr('data-title'));
                } else {    // unset title
                    el.attr('data-title', el.attr('title'));
                    el.removeAttr('title');
                }
            };
        }
    }
})();