// flyoutDocuments.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('flyoutDocuments', flyoutDocuments);

    // Inject dependencies.
    flyoutDocuments.$inject = ['ApplicationService', 'HelperService'];

    function flyoutDocuments(ApplicationService, HelperService) {
        var directive = {
            link: link,
            restrict: 'E',
            templateUrl: '/templates/flyoutDocuments.html'
        };

        return directive;

        function link(vm, element, attr) {

            // Get current locale for sortation of language keys
            ApplicationService.getSiteConfig().then(function(data) {
                vm.currentLocale = data.locale.slice(0, 2);
            });

            // For product lists show link to product detail page
            if (attr.showProductLink) {
                vm.showFlyoutProductLink = true;
            }

            // Add listener to close flyout
            var clickHandler = function(e) {
                if (!$(e.target).hasClass('flyout-trigger')) {
                    e.stopPropagation();
                    if (attr.scope) {
                        vm[attr.scope].showFlyOutDocuments = false;
                    } else {
                        vm.showFlyOutDocuments = false;
                    }

                    // Reset active button
                    $('.flyout-trigger button.active').removeClass('active');

                    vm.$apply();
                }
            };

            /**
             * Sort object by language
             * @param {Object} data – Object (languageKey, documentId)
             */
            vm.sortByLanguage = function(data) {
                data = window.sortObjectByKey(data);
                return data;
            };

            // Add event
            $(window).click(clickHandler);

            // Remove listener
            vm.$on('$destroy', function() {
                $(window).off('click', clickHandler);
            });

            // Open external file
            vm.openDocument = function(xuuid, documentType) {
                HelperService.openDocument(xuuid, documentType);
            };
        }
    }
})();