(function() {
    'use strict';

    angular
        .module('app.filters')
        .filter('formatTimestamp', formatTimestamp);

    function formatTimestamp() {

        /**
         * Format timestamp
         * Copy and paste of old push implementation
         *
         * @param {Number} timestampInMillis
         * @param {Number} utcDifference
         * @param {String} format
         *
         * @returns {String}
         */
        return function(timestampInMillis, utcDifference, format) {

            // Internal helper function
            var zeroPad = function(str, length) {
                var padded = ('0000000000' + '' + str);

                return padded.substring(padded.length - length);
            },

                date = new Date(timestampInMillis + utcDifference * 3600000),
                    result = '',
                    i;

            for (i = 0; i < format.length; i += 1) {
                switch (format.charAt(i)) {
                    case 'd':
                        result += zeroPad(date.getUTCDate(), 2);

                        break;
                    case 'j':
                        result += date.getUTCDate();

                        break;
                    case 'm':
                        result += zeroPad((date.getUTCMonth() + 1), 2);

                        break;
                    case 'n':
                        result += (date.getUTCMonth() + 1);

                        break;
                    case 'Y':
                        result += date.getUTCFullYear();

                        break;
                    case 'y':
                        result += String(date.getUTCFullYear()).substr(2);

                        break;
                    case 'G':
                        result += date.getUTCHours();

                        break;
                    case 'H':
                        result += zeroPad(date.getUTCHours(), 2);

                        break;
                    case 'i':
                        result += zeroPad(date.getUTCMinutes(), 2);

                        break;
                    case 's':
                        result += zeroPad(date.getUTCSeconds(), 2);

                        break;
                    default:
                        result += format.charAt(i);
                }
            }

            return result;
        };
    }
}());