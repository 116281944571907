// productFilterGroup.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('productFilterGroup', productFilterGroup);

    // Inject dependencies.
    productFilterGroup.$inject = [
        '$timeout',
        'MediaQueryService'
    ];

    function productFilterGroup(
        $timeout,
        MediaQueryService
    ) {
        var directive = {
            controller: controller,
            link: link,
            restrict: 'E',
            templateUrl: '/templates/productsFilterGroup.html',
            scope: {
                data: '=',
                filterData: '=',
                submit: '=',
                toggle: '=',
                isRenderAllowed: '='
            }
        };

        function link(scope, element) {

            // Submit filter if data has
            scope.$watch(function() {
                return element.attr('class');
            }, function() {
                if (scope.changed) {
                    scope.clickOutside();
                }
            });
        }

        function controller($scope) {
            var container = 'product-filter-group',
                jContainer = $(container);

            $scope.changed = false;
            $scope.filterTemp = [];
            $scope.media = MediaQueryService.getMedia();

            // Submit changes
            $scope.apply = function() {
                jContainer.removeClass('open');
                $scope.filterData = $scope.filterTemp;
                $scope.changed = false;

                // Submit changes
                $timeout(function() {
                    $scope.submit();
                    $scope.searchFilter = '';
                }, 5);
            };

            // Update filter data object
            $scope.change = function() {
                $scope.changed = true;
                synchronizeData();
            };

            // Close on mouseleave
            $scope.clickOutside = function() {

                // If something changed apply changes also on click outside
                if ($scope.changed) {
                    $scope.apply();
                }

                jContainer.removeClass('open');

                // Reset filter after a while to prevent flickering 
                $timeout(function() {
                    $scope.searchFilter = '';
                }, 5);
            };

            // Cancel button event
            $scope.clickCancel = function() {

                // Make sure the scope is not changed to prevent re-fetching from API
                $scope.changed = false;

                // Close the underlying container
                jContainer.removeClass('open');

                // Clear Selections
                var field,
                    currentSelections = [],
                    selection,
                    listItem;

                // Get active checkboxes
                for (selection in $scope.data.active) {
                    if (typeof $scope.data.active[selection].value !== 'undefined'){
                        currentSelections.push($scope.data.active[selection].value);
                    }


                }

                // Uncheck only those checkboxes that were not checked previously / active
                for (field in $scope.data.list) {
                    for (listItem in $scope.data.list[field].list) {
                        if (!currentSelections.includes($scope.data.list[field].list[listItem].label)) {
                            $scope.data.list[field].list[listItem].selected = false;
                        }
                    }

                }

                // Make sure our active selections still remain.
                for (field in $scope.data.list) {
                    for (listItem in $scope.data.list[field].list) {
                        if (currentSelections.includes($scope.data.list[field].list[listItem].label)) {
                            $scope.data.list[field].list[listItem].selected = true;
                        }
                    }

                }
            };

            // Synchronize filter with data
            function synchronizeData() {
                var fields,
                    field;

                $scope.filterTemp = [];

                // Get actvie checkboxes
                for (fields in $scope.data.list) {
                    for (field in $scope.data.list[fields].list) {
                        if ($scope.data.list[fields].list[field].selected) {
                            $scope.filterTemp.push(field);
                        }
                    }
                }
            }

            synchronizeData();
        }

        return directive;
    }
})();