// headline.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('headline', headline);

    // Inject dependencies.
    headline.$inject = [];

    function headline() {
        var directive = {
            controller: controller,
            restrict: 'E',
            scope: {
                name: '@?',
                url: '@?',
                data: '='
            },
            templateUrl: '/templates/headline.html'
        };

        return directive;

        function controller($scope) {
            var data = $scope.data;
            switch (data.size) {
                case 'h1' :
                    $scope.text = '<h1>' + data.text + '</h1>';
                    break;
                case 'h2' :
                    $scope.text = '<h2>' + data.text + '</h2>';
                    break;
                case 'h3' :
                    $scope.text = '<h3>' + data.text + '</h3>';
                    break;
                case 'h4' :
                    $scope.text = '<h4>' + data.text + '</h4>';
                    break;
                case 'h5' :
                    $scope.text = '<h5>' + data.text + '</h5>';
                    break;
                case 'h6' :
                    $scope.text = '<h6>' + data.text + '</h6>';
                    break;
            }

        }
    }
})();
