// tabsDynamicWrapper.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('tabsDynamicWrapper', tabsDynamicWrapper);

    // Inject dependencies.
    tabsDynamicWrapper.$inject = [];

    function tabsDynamicWrapper() {
        var directive = {
            restrict: 'E',
            scope: {
                data: '='
            },
            templateUrl: '/templates/tabsDynamicWrapper.html'
        };

        return directive;
    }
})();