// table.constants.js
(function() {
    'use strict';

    angular
        .module('app.constants')
        .constant('TABLECONFIG', {
            "cms": {
                "subscriptionProducts": [{
                        label: 'product',
                        value: 'name',
                        icon: ['settlementMethodIcon', 'barrierObservationMethodIcon']
                    },
                    {
                        label: 'currency',
                        value: 'currency',
                        icon: 'quantoIcon'
                    },
                    {
                        label: 'maturityDate',
                        value: 'maturityDate',
                        icon: 'earlyRedemptionIcon',
                        format: 'mediumDate'
                    },
                    {
                        label: 'subscriptionPeriodEndDate',
                        value: 'subscriptionPeriodEndDate',
                        format: 'customDateTime',
                        minWidth: 180 // Minimum column width in pixels in the instrument table
                        // maxWidth: 300 // Maximum column width in pixels in the instrument table
                    },
                    {
                        label: 'valor',
                        value: 'valor',
                        notSortable: true
                    },
                    {
                        label: ' ',
                        value: 'menu',
                        align: 'right',
                        notSortable: true
                    }
                ]
            }
        })
        .constant('FILTER_DEFAULT_DATA', {
            "filters": {
                "inListing": {
                        "name": "inListing",
                        "label": "inListing",
                        "selected": false
                },
                "group": {
                        "name": "group",
                        "label": "group",
                        "enabled": false,
                        "expandable": false,
                        "expanded": false,
                        "list": {}
                },
                "currency": {
                        "name": "currency",
                        "label": "currency",
                        "enabled": false,
                        "expandable": false,
                        "expanded": false,
                        "list": {}
                },
                "underlying": {
                        "name": "underlying",
                        "label": "underlying",
                        "enabled": false,
                        "expandable": false,
                        "expanded": false,
                        "list": {},
                        "listMulti": {}
                },
                "maturityDate": {
                        "name": "maturityDate",
                        "label": "maturityDate",
                        "list": {
                        },
                        "min": 1531094400,
                        "max": 1532563200,
                        "start": 1531180800,
                        "end": 1532563200
                }
            }
        })
        .constant('YIELD_MATRIX_DEFAULT_DATA', {
            "filters": {
                "currency": {
                        "name": "currency",
                        "label": "currency",
                        "list": {}
                },
                "underlying": {
                        "name": "underlying",
                        "label": "underlying",
                        "list": {},
                        "listMulti": {}
                },
                "distanceRange2BarrierLevel": {
                    "label": "distanceRange2BarrierLevel",
                    "list": [],
                    "name": "distanceRange2BarrierLevel"
                }
            }
        });
})();