// search.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('search', search);

    // Inject dependencies.
    search.$inject = [
        'InstrumentService',
        '$timeout',
        '$state',
        '$translate',
        '$rootScope',
        'AppConfigService'
    ];

    function search(
        InstrumentService,
        $timeout,
        $state,
        $translate,
        $rootScope,
        AppConfigService
    ) {
        var directive = {
            controller: controller,
            restrict: 'E',
            scope: {
                name: '@'
            },
            templateUrl: '/templates/search.html'
        };

        return directive;

        function controller($scope) {
            var container = 'search',
                input = $(container).find('input'),
                entries,
                timeout,
                i;

            // Preload translations
            $timeout(function() {
                $translate(['search_placeholder', 'search']).then(function(translations) {
                    $scope.translations = translations;
                });
            });

            // Check for results
            function checkResults(data) {
                entries = data.products.concat(data.groups, data.groupNames);
                $scope.entriesLength = entries.length;

                // Toggle keyboard controls
                if ($scope.entriesLength) {
                    $scope.data = data;
                    if ($scope.isEnterKeyPressed) {
                        $timeout(function() {
                            handleEnterKeyPressed();
                        }, 300);
                    } else {
                        initControls(true);
                    }
                } else {
                    $scope.error = 'no_results';
                    $scope.data = null;
                    initControls(false);
                }
            }

            // Clear search
            function clearSearch() {
                $scope.data = null;
                $scope.error = null;
                $(container).toggleClass('active', false);
                $(container).unbind('keyup');
                input.val('');
                input.attr('placeholder', $scope.translations.search);

                // Apply blur only on search input field | instead of the old instruction : document.activeElement.blur()
                input.blur();

                i = -1;
            }

            $scope.isEnterKeyPressed = false;

            // Goto product detail page
            $scope.goProductDetail = function(item) {

                //Close search
                $scope.setFocus(false);
                $timeout(function() {
                    $state.go('productDetail', {
                        identifier: item.idType,
                        identifierValue: item.idValue
                    }, {
                        reload: true
                    });
                });

                // Close menu for mobile
                $rootScope.showMenuFlag = false;
            };
            
            function handleEnterKeyPressed() {

                // Hitting "return" triggers click function of entry
                if ($scope.entriesLength === 1) {

                    // For groups use setFilters
                    if (entries[0].type === 'group') {
                        $scope.setFilters(entries[0].filters);
                    } else {

                        // For products go directly to product detail
                        $timeout(function() {
                            $state.go('productDetail', {
                                identifier: entries[0].idType,
                                identifierValue: entries[0].idValue
                            });
                        });
                    }

                    // Close menu for mobile
                    $rootScope.showMenuFlag = false;
                } else {

                    // If no selected search result, select the first one
                    if ($(container).find('.results li a.active').length === 0){
                        $(container).find('.results li a').first().addClass('active');
                    }

                    $(container).find('.results li a.active').trigger('click');
                }

                clearSearch();
                $scope.isEnterKeyPressed = false;
            }
            
            // Init search controls
            function initControls(bool) {
                
                // Unbind previous listeners
                $(container).unbind('keyup');
                if (bool) {
                    i = -1;
                    $(container).keyup(function(event) {
                        $scope.isEnterKeyPressed = false;
                        switch (event.keyCode) {
                            case 13:
                                
                                // Setting shared flag indicating that ENTER was already pressed
                                $scope.isEnterKeyPressed = true;
                                handleEnterKeyPressed();
                                break;
                            case 40:

                                // Go result list down
                                if (i < $scope.entriesLength) {
                                    i++;
                                    $(container).find('.results li a.active').removeClass('active');
                                    $($('.results li:not(.label) a')[i]).addClass('active');
                                }

                                break;
                            case 38:

                                // Go result list up
                                if (i > 0) {
                                    i--;
                                    $(container).find('.results li a.active').removeClass('active');
                                    $($('.results li:not(.label) a')[i]).addClass('active');
                                }

                                break;
                            default:
                                break;
                        }
                    });
                } else {
                    $scope.isEnterKeyPressed = false;
                }
            }

            // Set filters
            $scope.setFilters = function(data) {

                //Close search
                $scope.setFocus(false);

                // Go to state with search parameters.
                $timeout(function() {
                    $state.go('productList.findProducts', {
                        search: $.param(data)
                    }, {
                        reload: true
                    });
                });

                // Close menu for mobile
                $rootScope.showMenuFlag = false;
            };

            // Set input focus events
            $scope.setFocus = function(flag) {

                // Close searchbox
                if (!flag) {
                    $timeout(function() {
                        clearSearch();
                    }, 100);

                } else { // Show searchbox
                    $(container).keyup(function(event) {
                        if (event.keyCode === 13) {
                            $scope.isEnterKeyPressed = true;
                        }
                    });
                    $(container).toggleClass('active', true);
                    input.attr('placeholder', $scope.translations.search_placeholder);
                }
            };

            /**
             * Check search term for length
             */
            $scope.check = function() {
                var query = $scope.query;
                if (!query || !query.length) {
                    $scope.data = null;
                    return;
                } else if (query.length < 3) {
                    $scope.error = null;
                    return;
                }

                var locale = AppConfigService.getLocale(),
                    queryParams = {
                    q: query,
                    locale: locale
                };
                
                // Wait until user is done with typing in
                clearTimeout(timeout);
                timeout = setTimeout(function() {
                    submit(queryParams);
                }, 400);
            };

            /**
             * Submit the search term
             * @param queryParams
             */
            function submit(queryParams) {
                $(container).addClass('icon-loading');
                InstrumentService.getSearchResults(queryParams).then(function(response) {
                    checkResults(response.data);
                }, function(err) {
                    console.log(err);
                }).finally(function() {
                    $(container).removeClass('icon-loading');
                });
            }

            //Click outside handler
            $(container).on('mouseleave', function() {
                $(document).bind('click.searchHandler', function() {
                    $scope.setFocus(false);
                });
            });
            $(container).on('mouseenter', function() {
                $(document).unbind('click.searchHandler');
            });
        }
    }
})();