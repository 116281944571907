//instrumentTableIconMobile.directive.js
(function() {

    'use strict';

    angular
        .module('app.directives')
        .directive('instrumentTableIconMobile', instrumentTableIcon);

    instrumentTableIcon.$inject = [
        '$timeout',
        'MediaQueryService'
    ];

    function instrumentTableIcon($timeout, MediaQueryService) {
        var directive = {
            link: link,
            replace: true,
            restrict: 'E',
            scope: {
                data: '=',
                target: '='
            }
        };

        return directive;

        //Init
        function link(scope, element) {
            var el = '<span class="icon">' +
                    '<i class="icon-product icon-' + scope.data.icon.toLowerCase() + '">' +
                        '<span class="path1"></span><span class="path2"></span>' +
                    '</i>' +
                    '<div class="flyout"><p>' + scope.data.text + '</p></div>' +
                '</span>' +
                '<div class="modal">' +
                    '<div class="wrapper">' +
                        '<i class="icon-close"></i>' +
                        '<div class="content">' +
                            '<i class="icon-product icon-' + scope.data.icon.toLowerCase() + '">' +
                                '<span class="path1"></span><span class="path2"></span>' +
                            '</i>' +
                            '<p>' + scope.data.text + '</p>' +
                        '</div>' +
                    '</div>' +
                '</div>';

            $timeout(function() {
                var length = element && element.length && element.length > 0 ? element.length - 1 : 0,
                    valueContainer = $(element[length]).parent().find('em').last(),
                    icon, flyout, modal,
                    toggleModal = function(e) {
                        e.stopPropagation();

                        if (modal.is(':visible')) {
                            modal.hide();
                        } else {
                            flyout.hide();
                            modal.show();
                        }
                    };

                // Append
                valueContainer.append($(el));
                icon = valueContainer.find('.icon');
                flyout = valueContainer.find('.flyout');
                modal = valueContainer.find('.modal');

                // Add eventlistener
                icon.mouseenter(function() {
                    if (MediaQueryService.getMedia() === 'regular') {
                        flyout.show();
                    }
                });
                icon.mouseleave(function() {
                    if (MediaQueryService.getMedia() === 'regular') {
                        flyout.hide();
                    }
                });

                valueContainer.find('.icon, .icon-close').bind('click', toggleModal);

            }, 1);
        }
    }
})();