// sceneSlider.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('sceneslider', sceneSlider);

    // Inject dependencies.
    sceneSlider.$inject = [
        '$timeout',
        'MediaQueryService'
    ];

    function sceneSlider($timeout, MediaQueryService) {
        var directive = {
            controller: controller,
            link: link,
            restrict: 'E',
            scope: {
                name: '@?',
                url: '@?',
                data: '='
            },
            templateUrl: '/templates/sceneSlider.html'
        };

        return directive;

        function controller($scope) {
            $scope.baseURL = window.remoteBaseURL;
            $scope.direction = 'left';
            $scope.currentIndex = 0;
            $scope.media = MediaQueryService.getMedia();
            $scope.isMobile = MediaQueryService.getMedia() === 'mobile' || MediaQueryService.getMedia() === 'medium';

            var durationArray = [], mouseOver = false;

            for (var slide in $scope.data.slides){
                if ($scope.data.slides.hasOwnProperty(slide)) {
                    var duration = 5;
                    if (!isNaN(parseInt($scope.data.slides[slide].duration))){
                        duration = parseInt($scope.data.slides[slide].duration);
                    }

                    durationArray.push(duration);
                }
            }

            //duration
            var timer,
                sliderFunc = function() {

                    //check if mouse is over the slider
                    if (!mouseOver) {
                        $scope.currentIndex = ($scope.currentIndex < $scope.data.slides.length - 1) ? ++$scope.currentIndex : 0;
                    }

                    timer = $timeout(sliderFunc, durationArray[$scope.currentIndex] * 1000);
            };

            $timeout(sliderFunc, durationArray[$scope.currentIndex] * 1000);

            // when the scope is getting destroyed, cancel the timer
            $scope.$on('$destroy', function() {
                $timeout.cancel(timer);
            });

            $scope.setCurrentSlideIndex = function(index) {
                $scope.direction = (index > $scope.currentIndex) ? 'left' : 'right';
                $scope.currentIndex = index;
                $timeout.cancel(timer);
            };

            // check Current image index
            $scope.isCurrentSlideIndex = function(index) {
                return $scope.currentIndex === index;
            };

            $scope.prevSlide = function() {
                $scope.direction = 'left';
                $scope.currentIndex = ($scope.currentIndex < $scope.data.slides.length - 1) ? ++$scope.currentIndex : 0;
                $timeout.cancel(timer);
            };

            $scope.nextSlide = function() {
                $scope.direction = 'right';
                $scope.currentIndex = ($scope.currentIndex > 0) ? --$scope.currentIndex : $scope.data.slides.length - 1;
                $timeout.cancel(timer);
            };

            $scope.mouseOver = function() {
                mouseOver = true;
            };

            $scope.mouseLeave = function() {
                mouseOver = false;
            };
        }
        
        function link(vm, element) {
            var isMobile = MediaQueryService.getMedia() === 'mobile' || MediaQueryService.getMedia() === 'medium';
            
            // Set height for slider.
            if (isMobile) {
                $timeout(function() {
                    var slider = element.find($('.slider')),
                        slide = element.find($('.slider-slide')).first(),
                        dots = element.find($('.slider-dots')),
                        maxHeight = Math.max.apply(null, element.find($('.slider-slide-box')).map(
                            function() {
                                return $(this).height();
                            }
                        ).get());

                    maxHeight += slide.height() + dots.height() + 8;

                    slider.height(maxHeight);
                });
            }
        }
    }
})();
