// productsFilter.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('productsFilter', productsFilter);

    // Inject dependencies.
    productsFilter.$inject = [
        'ProductFilterService',
        'ProfilerService'
    ];

    function productsFilter(
        ProductFilterService,
        ProfilerService
    ) {
        var directive = {
            restrict: 'E',
            controller: controller,
            templateUrl: '/templates/productsFilter.html',
            scope: {
                data: '=',
                changeFunc: '=',
                url: '=',
                type: '=',
                tabs: '='
            }
        };

        function controller($scope) {
            var container = 'products-filter',
                jContainer = $(container);

            // Inititally get filterdata if they are already set by url params
            $scope.filter = ProductFilterService.formatURLParamsToFilterObject(ProductFilterService.queryData.filter);

            // Allowing render of sub content of dropdown, keys of the array are container ids
            $scope.isRenderAllowed = [];

            /**
             * Remove active filter value
             * @param {string} key - Type of active filter tag.
             * @param {string} value - Value of active filter tag.
             */
            $scope.removeActiveFilterValue = function(key, value) {
                ProductFilterService.removeActiveFilterValue($scope.filter, key, value).then(function(data) {
                    $scope.filter = data;
                    $scope.submit();
                });
            };

            // Reset filter
            $scope.resetFilter = function() {
                ProductFilterService.reset($scope.type).then(function() {
                    $scope.filter = {};
                    $scope.toggleThirdLevelFilter(true); //Close third level filter
                    $scope.submit();
                });
            };

            /**
             * Check if there are active filters to show tags
             */
            $scope.isFilterActive = function(filters) {
                var flag = false;
                try {
                    if (filters) {
                        angular.forEach(filters, function(val) {
                            if (val.active) {
                                flag = true;
                            }
                        });
                    }
                } catch (e) {
                    console.log(e);
                }

                return flag;
            };


            // Submit filter form
            $scope.submit = function() {
                var productsTab = $('.find-products tab'),
                    productsFilterList = $('products-filter .list-wrapper');

                // Type specific container
                if ($scope.type === 'yieldMatrix') {
                    productsTab = $('.yield-matrix tab');
                }

                productsTab.addClass('icon-loading');
                productsFilterList.addClass('icon-loading');

                ProductFilterService.submit(
                    $scope.url,
                    ProductFilterService.getQueryURL(
                        $scope.filter, true
                    )
                ).then(function(data) {
                    $scope.data = data;

                    // Update tabs
                    updateTabs($scope.type);

                }, function() {
                    $scope.groups = [];
                }).finally(function() {
                    ProfilerService.setTimeLoad();
                    productsTab.removeClass('icon-loading');
                    productsFilterList.removeClass('icon-loading');

                    //Close third level filter
                    if ($scope.data.filters.group && !$scope.data.filters.group.active) {
                        $scope.toggleThirdLevelFilter(true);
                    }
                });
            };

            // Toggle this filter window
            $scope.toogleDropDowns = function(container, cssClass) {
                var jContainer = $(container),
                    state,
                    productsOpen;

                if (cssClass) {
                    jContainer = $(container + '.' + cssClass);
                }

                //Allow content rendering
                $scope.isRenderAllowed[container] = true;

                state = jContainer.hasClass('open');
                productsOpen = $('products-filter .open');

                if (state) {
                    productsOpen.removeClass('open');
                } else {
                    productsOpen.removeClass('open');
                    jContainer.addClass('open');
                }
            };

            /**
             *  Init event listener
             * @param {Boolean} close - Just close filter if true 
             */
            $scope.toggleThirdLevelFilter = function(close) {
                if (close) {
                    jContainer.find('.third-level-filter .third-level-filter-expanded').hide();
                    jContainer.find('.third-level-filter i').removeClass('active');
                } else {
                    jContainer.find('.third-level-filter .third-level-filter-expanded').toggle();
                    jContainer.find('.third-level-filter i').toggleClass('active');
                }
            };


            /**
             * Update tabs data
             * @param {String} type - Yield-Matrix/ProductList
             */
            function updateTabs(type) {
                if (type === 'yieldMatrix') {
                    $scope.tabs.BarrierReverseConvertible = $scope.data.groups.searchObjectsByKey('name', 'BarrierReverseConvertible')[0];
                    $scope.tabs.MultiBarrierReverseConvertible = $scope.data.groups.searchObjectsByKey('name', 'MultiBarrierReverseConvertible')[0];
                    $scope.tabs.Autocallable_SoftCallable_MultiBarrierReverseConvertible = $scope.data.groups.searchObjectsByKey('name', 'Autocallable_SoftCallable_MultiBarrierReverseConvertible')[0];
                } else {
                    $scope.tabs.inSubscription = $scope.data.in_subscription;
                    $scope.tabs.secondaryMarket = $scope.data.secondary_market;
                }
            }
        }

        return directive;
    }
})();