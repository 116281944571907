// lazyRender.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('lazyRender', lazyRender);

    // Inject dependencies.
    lazyRender.$inject = [
        '$timeout',
        'HistoryService'
    ];

    function lazyRender($timeout, HistoryService) {
        var directive = {
            link: link,
            restrict: 'A',
            scope: {
                data: '=',
                media: '='
            }
        };

        return directive;

        function link(vm, element) {
            var jElement = $(element),
                jTitle = jElement.find('h1, h2, h3').first(),
                jWindow = $(window),
                holder = false, // A time holder for performance purpose
                tweakFactor = 50, // Time between each scroll callback to be executed
                lateCheck = null; // A late callback to check if all visible tables are rendered (to fix quick scroll)

            // Subscribe scroll event to document
            $timeout(function() {

                $(document).on('scroll', onScrollCallback);

                if (vm.media && vm.media !== 'regular') {
                    $(document).on('touchmove', onScrollCallback);
                }

                // Calling onScrollHandler the first time to init
                onScrollHandler();

                // Watch for any changes on group's data and check if should be redered or not
                vm.$watch('group', onScrollHandler);
            });

            // Unsubscribe scroll event
            vm.$on('$destroy', function() {

                $(document).off('scroll', onScrollCallback);

                if (vm.media && vm.media !== 'regular') {
                    $(document).off('touchmove', onScrollCallback);
                }
            });
            
            /**
             * Optimized scroll event callback 
             */
            function onScrollCallback() {

                // Clear old timeout if already registered
                if (lateCheck) {
                    clearTimeout(lateCheck);
                }

                lateCheck = setTimeout(function() {
                    onScrollHandler();
                }, 200);

                if (holder) {
                    return;
                }

                holder = true;

                // Execute the desired instructions when scrolled
                onScrollHandler();

                // Performance holder clearer
                setTimeout(function() {
                    holder = false;
                }, tweakFactor);
            }

            /**
             * Executed when window scrolled to check if Group can be rendered or not
             */
            function onScrollHandler() {
                if (!vm.data.allowRender && (isScrolledIntoView(jTitle) || isScrolledIntoView(jElement))) {
                    
                    // allowRender flag set to true
                    vm.data.allowRender = true;

                    // Store action for history
                    HistoryService.storeRenderedGroup(vm.data.id);
                    
                    // Safely apply DOM changes
                    try {
                        vm.$apply();
                    } catch (e) {
                    }
                }

            }

            /**
             * Check if target element is in the visible area
             * @param {type} element
             * @returns {Boolean}
             */
            function isScrolledIntoView(element) {
                try {
                    var docViewTop = jWindow.scrollTop(),
                        docViewBottom = docViewTop + jWindow.height(),
                        elemTop = element.offset().top,
                        elemBottom = elemTop + element.height();
                    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
                } catch (e) {}

                return true;
            }
        }
    }
})();