(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('localeSelector', localeSelector);

    localeSelector.$inject = [];

    function localeSelector() {
        var directive = {
            restrict: 'E',
            scope: {
                name: '@',
                languageList: '=',
                currentLocale: '<'
            },
            templateUrl: '/templates/localeSelector.html'
        };

        return directive;
    }
})();