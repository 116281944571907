//instrumentTableRangeColor.directive.js
(function() {

    'use strict';

    angular
        .module('app.directives')
        .directive('instrumentTableRangeColor', instrumentTableRangeColor);

    instrumentTableRangeColor.$inject = ['$state', '$timeout'];

    function instrumentTableRangeColor($state, $timeout) {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                data: '='
            }
        };
        return directive;

        function link(scope, element) {
            var value,
                cssClass,
                jElement = $(element),
                flyout,
                content = '';

            if (!scope.data || isNaN(scope.data.performance)) {
                return;
            }

            // Set css class for performance
            value = Number(scope.data.performance) * 100;

            if (value < 3) {
                cssClass = 'color-0';
            } else if (value < 6) {
                cssClass = 'color-1';
            } else if (value < 10) {
                cssClass = 'color-2';
            } else if (value < 15) {
                cssClass = 'color-3';
            } else if (value < 20) {
                cssClass = 'color-4';
            } else if (value < 25) {
                cssClass = 'color-5';
            } else if (value < 30) {
                cssClass = 'color-6';
            } else if (value < 35) {
                cssClass = 'color-7';
            } else if (value >= 35) {
                cssClass = 'color-8';
            }

            // Add css class to parant td
            jElement.closest('td').addClass(cssClass);

            // Set flyout with product data
            angular.forEach(scope.data.underlyings, function(value) {
                content += '<em>' + value + '</em>';
            });
            flyout = '<div class="flyout"><p>' + content + '</p></div>';
            jElement.append($(flyout));

            // Add eventlistener
            jElement.mouseenter(function() {
                jElement.find('.flyout').toggle();
            });

            // Close on mouseleave
            jElement.mouseleave(function() {
                jElement.find('.flyout').hide();
            });

            jElement.click(function() {
                $timeout(function() {
                    $state.go('productDetail', {
                        identifier: 'sin',
                        identifierValue: scope.data.sin
                    });
                });
            });
        }
    }
})();