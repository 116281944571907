// productFilterDropdownSmall.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('productFilterDropdownSmall', productFilterDropdownSmall);

    // Inject dependencies.
    productFilterDropdownSmall.$inject = [
        '$timeout',
        'MediaQueryService'
    ];

    function productFilterDropdownSmall(
        $timeout,
        MediaQueryService
    ) {
        var directive = {
            controller: controller,
            link: link,
            restrict: 'E',
            templateUrl: '/templates/productsFilterDropdownSmall.html',
            scope: {
                data: '=',
                filterData: '=',
                submit: '=',
                toggle: '='
            }
        };

        function controller($scope) {
            $scope.changed = false;
            $scope.filterTemp = [];
            $scope.media = MediaQueryService.getMedia();

            // Submit changes
            $scope.apply = function() {
                $($scope.container).removeClass('open');
                $scope.filterData = $scope.filterTemp;
                $scope.changed = false;

                // Submit changes
                $timeout(function() {
                    $scope.submit();
                }, 5);
            };

            // Update filter data object
            $scope.change = function() {
                $scope.changed = true; // Set changed state to force submit
                synchronizeData();
            };

            // Synchronize filter with data
            function synchronizeData() {
                var field;
                $scope.filterTemp = [];

                // Get active checkboxes
                for (field in $scope.data.list) {
                    if ($scope.data.list[field].selected) {
                        $scope.filterTemp.push(field);
                    }
                }
            }

            synchronizeData();
        }

        function link(vm, element) {
            var jElement = $(element);
            vm.container = jElement;

            // Submit filter if data has
            vm.$watch(function() {
                return element.attr('class');
            }, function() {
                if (vm.changed) {
                    vm.clickOutside();
                }
            });
            
            // Close on mouseleave
            vm.clickOutside = function() {

                // If something changed apply changes also on click outside
                if (vm.changed) {
                    jElement.removeClass('open');
                    vm.apply();
                }

                jElement.removeClass('open');
            };
        }

        return directive;
    }

})();