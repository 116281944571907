// sidebarMobile.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('sidebarMobile', sidebarMobile);

    // Inject dependencies.
    sidebarMobile.$inject = [
        'BASEURL',
        'IS_BETA_VERSION',
        '$state',
        '$rootScope'
    ];

    function sidebarMobile(BASEURL, IS_BETA_VERSION) {
        var directive = {
            controller: controller,
            restrict: 'E',
            scope: {},
            templateUrl: '/templates/sidebarMobile.html'
        };

        return directive;

        function controller($scope, $state, $rootScope) {
            $scope.BASEURL = BASEURL;
            $scope.isBetaVersion = IS_BETA_VERSION;

            try {
                $scope.currentState = $state;
            } catch (e) {
                console.log(e);
            }

            /**
             * Close Sidebar Menu for mobile
             */
            $scope.closeMenu = function() {
                $rootScope.
                    showMenuFlag = false;
            };

        }
    }
})();