(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('LocaleService', LocaleService);

    // Inject dependencies.
    LocaleService.$inject = [
        'DisclaimerService',
        '$rootScope',
        '$location',
        '$cookies',
        'COOKIES_CONFIG',
        '$state'
    ];

    function LocaleService(
        DisclaimerService,
        $rootScope,
        $location,
        $cookies,
        COOKIES_CONFIG,
        $state
    ) {
        return {

            updateLanguageUrls: function(stateName) {
                var self = this;

                if (!stateName) {
                    stateName = $state.current.name;
                }

                // Set root urls for languages, language short name and locale names
                $rootScope.languageList = self.setupLanguageUrls(window.AppConfig);

                angular.forEach($rootScope.languageList, function(v, i) {
                    $rootScope.languageList[i].localesUrl = self.createUrlForState($rootScope.languageList[i].localesUrl, stateName);
                });
            },

            setupLanguageUrls: function(appConfig) {
                var languageList = {},
                    i;

                for (i = 0; i < appConfig.supportedLocales.length; i++) {
                    languageList[i] = {};
                    languageList[i].localesUrl = appConfig.localesToUrl[appConfig.supportedLocales[i]];
                    languageList[i].languageName = appConfig.supportedLocales[i].split('_')[0].toUpperCase();
                    languageList[i].locale = appConfig.supportedLocales[i];
                }

                return languageList;
            },

            createUrlForState: function(selectedLocalesToUrl, stateName) {
                var url = selectedLocalesToUrl,
                    currentState = stateName,
                    params = $location.search();


                /**
                 * Check if params are empty.
                 * Get identifier and identifier value for product detail page.
                 */
                if (jQuery.isEmptyObject(params) && window.location.href.split('product/detail/').length > 1) {
                    var productDetailParams = window.location.href.split('product/detail/')[1].split('/');

                    params = {
                        identifier: productDetailParams[0],
                        identifierValue: productDetailParams[1]
                    };
                }

                url += '?disclaimerSet=' + !DisclaimerService.checkShowDisclaimer(true);
                url += '&state=' + encodeURIComponent(currentState);
                url += '&stateParams=' + encodeURIComponent(JSON.stringify(params));

                var cookiesDisclaimer = $cookies.get(COOKIES_CONFIG.COOKIE_DISCLAIMER_ACCEPTED),
                    cookiesTracking = $cookies.get(COOKIES_CONFIG.COOKIE_TRACKING_ACCEPTED),
                    cookiesMarketing = $cookies.get(COOKIES_CONFIG.COOKIE_MARKETING_ACCEPTED);

                if (cookiesDisclaimer && cookiesDisclaimer !== 'false') {
                    url += '&disclaimerCookie=true';
                } else if (cookiesDisclaimer === 'false' || cookiesDisclaimer === false) {
                    url += '&disclaimerCookie=false';
                } else {
                    url += '&disclaimerCookie=null';
                }

                if (cookiesTracking && cookiesTracking !== 'false') {
                    url += '&trackingCookie=true';
                } else if (cookiesTracking === 'false' || cookiesTracking === false) {
                    url += '&trackingCookie=false';
                } else {
                    url += '&trackingCookie=null';
                }

                if (cookiesMarketing && cookiesMarketing !== 'false') {
                    url += '&marketingCookie=true';
                } else if (cookiesMarketing === 'false' || cookiesMarketing === false) {
                    url += '&marketingCookie=false';
                } else {
                    url += '&marketingCookie=null';
                }

                return url;
            }
        };
    }
})();