// mainFooter.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('mainFooter', mainFooter);

    // Inject dependencies.
    mainFooter.$inject = [];

    function mainFooter() {
        var directive = {
            controller: controller,
            restrict: 'E',
            scope: {},
            templateUrl: '/templates/mainFooter.html'
        };

        return directive;
        

        function controller($scope) {
            $scope.pageTemplate = window.BUILD_ID + '/templates/page.html';

            /**
             * Direct browser to callto/tel href
             * @param {string} phonenumber - Phonenumber
             */
            $scope.call = function(phoneNumber) {
                window.location.href = 'tel:' + phoneNumber;
            };

            /**
             * Get cms footer grid from already loaded config data
             */
            $scope.gridData = new Object(window.AppConfig.footer); 
        }
    }
})();