//Chartservice
angular
    .module('app.services')
    .factory('ChartService', function(
        $http,
        $q,
        ApiService,
        $locale,
        ChartSettingsService,
        ChartGuidesService,
        ChartPushService,
        ChartMarginService,
        $translate
    ) {

        'use strict';

        var ChartService = function() {

            var instance = {},
                init = function(obj) {

                    //Instance params
                    var deferred = $q.defer();
                    instance.benchmarks = obj.benchmarks;
                    instance.container = obj.container;
                    instance.chartType = obj.chartType;
                    instance.identifier = obj.identifier;
                    instance.id = obj.id;
                    instance.timespan = obj.timespan;
                    instance.theme = obj.theme;
                    instance.media = obj.media;
                    instance.targetFinder = obj.targetFinder;
                    instance.staticData = obj.staticData;
                    instance.baseUrl = obj.baseUrl;
                    instance.mainDataSetHidden = false;
                    instance.valor = obj.valor;
                    instance.translations = [
                        'chart_error',
                        'strike',
                        'barrier',
                        'previousClose',
                        'downsideThreshold',
                        'couponBarrier',
                        'maximumGain',
                        'lowerBarrier',
                        'upperBarrier',
                        'downsideThresholdlowerBarrier',
                        'maximumGainupperBarrier',
                        'downsideThresholdcouponBarrierlowerBarrier'
                    ];
                    instance.chartEngine = {
                            chart: null,
                            chartData: null,
                            selectedPeriod: instance.timespan,
                            showBullet: false,
                            showSecondAxis: false,
                            valueAxisLabelsEnabled: true,
                        },
                        instance.issuePrice = obj.issuePrice,
                        instance.mainGraphLineAlpha = 1;

                    //Get translations
                    $translate(instance.translations).then(function(data) {
                        instance.chartEngine.translations = data;
                    });

                    //Get project specific chart settings
                    angular.extend(
                        instance.chartEngine,
                        ChartSettingsService.get(instance.theme, instance.chartEngine.selectedPeriod)
                    );

                    //Show loader
                    $(instance.container).addClass('icon-loading');

                    //Get data
                    getData()
                        .then(function() {

                            //Prepare data
                            prepareData(instance.chartEngine.chartData)
                                .then(function() {

                                    //Draw chart
                                    drawChart()
                                        .then(function() {

                                            //Init target finder
                                            //TODO: Add target finder service
                                            if (instance.targetFinder) {
                                                var dataset = instance.chartEngine.chartData.set[0].dat,
                                                    lastValue = dataset[dataset.length - 2].value,
                                                    lastDate = dataset[dataset.length - 2].date;

                                                //Default params
                                                var targetFinderParams = {
                                                    chart: instance.chartEngine,
                                                    color: instance.chartEngine.chartData.set[0].col,
                                                    dataSet: dataset,
                                                    container: document.querySelector('.target-finder'),
                                                    dragger: document.querySelector('.target-finder .target'),
                                                    lastValue: lastValue,
                                                    min: instance.chartEngine.chart.panels[0].valueAxes[0].min,
                                                    max: instance.chartEngine.chart.panels[0].valueAxes[0].max,
                                                    endDate: instance.chartEngine.chart.endDate,
                                                    startDate: instance.chartEngine.chart.startDate
                                                };
                                                TargetFinderService.init(targetFinderParams);
                                            }

                                            if (instance.timespan === '1D' && instance.chartEngine.push) {
                                                ChartPushService.set({
                                                    identifier: instance.identifier,
                                                    id: instance.id,
                                                    dataSet: instance.chartEngine.chartData.set[0],
                                                    chart: instance.chartEngine.chart,
                                                    update: update
                                                });
                                            }

                                            //Promise
                                            deferred.resolve();
                                            return deferred.promise;

                                        }, function() { //Error drawChart()
                                            console.log('CHART ERROR: drawChart()');
                                        });
                                }, function() { //Error prepareData()
                                    console.log('CHART ERROR: prepareData()');
                                });
                        }).catch(function(){
                            if (obj.onChartDataFetchError) {
                                obj.onChartDataFetchError();
                            }
                    }).finally(function() {
                            $(instance.container).removeClass('icon-loading');
                        });

                    return deferred.promise;
                };

            var calculatePercents = function(data, basePrice) {
                data.value2 = ((data.value - basePrice) / basePrice) * 100;
                data.realValue = AmCharts.formatNumber(data.value, {
                    precision: 2,
                    decimalSeparator: $locale.NUMBER_FORMATS.DECIMAL_SEP,
                    thousandsSeparator: $locale.NUMBER_FORMATS.GROUP_SEP
                });
                return data;
            }

            //Init chart
            var drawChart = function() {

                var deferred = $q.defer(),
                    i,
                    availableDatasets = [],
                    valueAxis2,
                    g;

                //Day / Week translations
                AmCharts.monthNames = $locale.DATETIME_FORMATS.STANDALONEMONTH;
                AmCharts.shortMonthNames = $locale.DATETIME_FORMATS.SHORTMONTH;
                AmCharts.dayNames = $locale.DATETIME_FORMATS.DAY;
                AmCharts.shortDayNames = $locale.DATETIME_FORMATS.SHORTDAY;

                //Hard return if no container found
                if (!$(instance.container)) {
                    return;
                }

                //Set default periods
                if (!instance.chartEngine.selectedPeriod) {
                    instance.chartEngine.selectedPeriod = '1M';
                }

                /**
                 * Calculate margin
                 * @param {Array} sets - Datasets
                 * @param {Array} guides - Guides
                 * @param {Array} marginSteps - Margin steps of current value axis
                 * @param {Object} translations - translations
                 */
                instance.chartEngine.panelsSettingsMarginLeft = ChartMarginService.calculateMargin({
                    sets: instance.chartEngine.chartData.set,
                    marginSteps: instance.chartEngine.panelsSettingsMarginLeftSteps,
                    translations: instance.chartEngine.translations,
                    skipGuides: instance.chartEngine.skipGuidesForMarginCalculation
                });

                //Build chart object
                instance.chartEngine.chart = new AmCharts.AmStockChart();

                //Datasets
                for (i = 0; i < instance.chartEngine.chartData.set.length; i++) {

                    var dataSet = new AmCharts.DataSet();
                    dataSet.categoryField = "date";
                    dataSet.color = instance.chartEngine.colors[i];
                    dataSet.sin = instance.id;
                    dataSet.currency = instance.chartEngine.chartData.set[i].cur;

                    // Update dataset color with benchmark color
                    if (i > 0 && instance.benchmarks.length && instance.benchmarks[i - 1]) {
                        dataSet.color = instance.benchmarks[i - 1].color;
                        dataSet.sin = instance.benchmarks[i - 1].sin;
                        dataSet.compared = instance.benchmarks[i - 1].active;
                    }

                    dataSet.dataProvider = instance.chartEngine.chartData.set[i].dat;

                    // Update field mappings regarding the chartType
                    switch (instance.chartEngine.chartGraph) {
                        case 'candlestick':
                        case 'ohlc':
                            dataSet.fieldMappings = [{
                                    fromField: "open",
                                    toField: "open"
                                },
                                {
                                    fromField: "high",
                                    toField: "high"
                                }, {
                                    fromField: "low",
                                    toField: "low"
                                }, {
                                    fromField: "close",
                                    toField: "close"
                                }
                            ];
                            break;
                        case 'line':
                        case 'step':
                            dataSet.fieldMappings = [{
                                fromField: "value",
                                toField: "value"
                            }];
                            break;
                        case 'percentsAbsolute':
                            dataSet.fieldMappings = [{
                                fromField: "value2",
                                toField: "value2"
                            }];
                            break;
                        default:
                            dataSet.fieldMappings = [{
                                fromField: "value",
                                toField: "value"
                            }];
                            break;
                    }
                    dataSet.title = instance.chartEngine.chartData.set[i].til;

                    availableDatasets.push(dataSet);
                }

                instance.chartEngine.chart.dataSets = availableDatasets;

                //Panel
                var stockPanel = new AmCharts.StockPanel();
                stockPanel.recalculateToPercents = instance.chartEngine.recalculateToPercents;
                instance.chartEngine.chart.panels = [stockPanel];

                var panelsSettings = new AmCharts.PanelsSettings();
                panelsSettings.fontFamily = instance.chartEngine.panelsSettingsFontFamily;
                panelsSettings.fontSize = instance.chartEngine.panelsSettingsFontSize;

                //Show margins
                if (instance.chartEngine.showMargins) {
                    panelsSettings.marginTop = 15;
                    panelsSettings.marginLeft = instance.chartEngine.panelsSettingsMarginLeft || 0;
                    panelsSettings.marginRight = instance.chartEngine.panelsSettingsMarginRight || 0;
                }

                //Number format
                panelsSettings.numberFormatter = {
                    precision: -1,
                    decimalSeparator: $locale.NUMBER_FORMATS.DECIMAL_SEP,
                    thousandsSeparator: $locale.NUMBER_FORMATS.GROUP_SEP
                };
                instance.chartEngine.chart.panelsSettings = panelsSettings;

                //Value axis right
                var valueAxis = new AmCharts.ValueAxis();
                valueAxis.gridAlpha = instance.chartEngine.valueAxisGridAlpha;
                valueAxis.gridColor = instance.chartEngine.valueAxisGridColor;
                valueAxis.labelsEnabled = instance.chartEngine.valueAxisLabelsEnabled;
                valueAxis.precision = 2;
                valueAxis.position = instance.chartEngine.valueAxisPosition;
                valueAxis.includeGuidesInMinMax = true;
                valueAxis.minMaxMultiplier = instance.chartEngine.valueAxisMinMaxMultiplier;
                stockPanel.addValueAxis(valueAxis);

                //Value (percent) axis left
                if (instance.chartEngine.showSecondAxis) {
                    valueAxis2 = new AmCharts.ValueAxis();
                    valueAxis2.position = "left";
                    valueAxis2.precision = 2;
                    valueAxis2.gridAlpha = 0;
                    valueAxis2.gridColor = '#000000';
                    stockPanel.addValueAxis(valueAxis2);
                }

                //Value Axis settings
                var valueAxesSettings = new AmCharts.ValueAxesSettings();
                valueAxesSettings.axisAlpha = 0;
                valueAxesSettings.color = instance.chartEngine.valueAxesSettingsColor;
                valueAxesSettings.fillAlpha = 0;
                valueAxesSettings.fillColor = "#888888";
                valueAxesSettings.fontSize = instance.chartEngine.valueAxesSettingsFontSize;
                valueAxesSettings.inside = instance.chartEngine.valueAxesSettingsInside;
                valueAxesSettings.showFirstLabel = true;
                valueAxesSettings.showLastLabel = true;
                valueAxesSettings.unit = instance.chartEngine.valueAxesSettingsUnit;
                instance.chartEngine.chart.valueAxesSettings = valueAxesSettings;

                //Graph
                var graph = new AmCharts.StockGraph();

                if (instance.chartEngine.showBullet) {
                    graph.bullet = 'round';
                    graph.bulletColor = '#ffffff';
                    graph.bulletAlpha = 1;
                    graph.bulletBorderThickness = 1;
                    graph.bulletBorderColor = '#666666';
                    graph.bulletSize = 5;
                    graph.lineColor = instance.chartEngine.chartData.set[0].col;
                    graph.useDataSetColors = false;
                }

                graph.balloonText = instance.chartEngine.graphBalloonText;
                graph.compareGraphBalloonText = instance.chartEngine.compareGraphBalloonText;
                graph.showBalloon = instance.chartEngine.graphShowBalloon;
                graph.comparable = true;
                graph.compareField = "value";
                graph.compareFromStart = true;
                graph.compareGraphFillAlphas = instance.chartEngine.graphFillAlphas;
                graph.compareGraphLineAlpha = 1;
                graph.compareGraphLineThickness = instance.chartEngine.graphLineThickness;
                graph.hidden = instance.mainDataSetHidden;
                graph.lineAlpha = instance.mainGraphLineAlpha;
                graph.lineThickness = instance.chartEngine.graphLineThickness;
                graph.fillAlphas = instance.chartEngine.graphFillAlphas;

                switch (instance.chartEngine.chartGraph) {
                    case 'candlestick':
                        graph.type = 'candlestick';
                        graph.closeField = 'close';
                        graph.highField = 'high';
                        graph.lowField = 'low';
                        graph.openField = 'open';
                        break;
                    case 'ohlc':
                        graph.type = 'ohlc';
                        graph.closeField = 'close';
                        graph.highField = 'high';
                        graph.lowField = 'low';
                        graph.openField = 'open';
                        break;
                    case 'line':
                        graph.type = 'line';
                        graph.valueField = "value";
                        break;
                    case 'percentsAbsolute':
                        graph.type = 'line';
                        graph.valueField = "value2";
                        graph.compareField = "value2";
                        break;
                    case 'step':
                        graph.type = 'step';
                        graph.valueField = "value";
                        break;
                    default:
                        graph.type = 'line';
                        graph.valueField = "value";
                        break;
                }

                //For single graphs show negative base
                if (instance.chartEngine.showNegativeLineColor) {
                    graph.negativeFillAlphas = instance.chartEngine.graphNegativeFillAlphas;
                    graph.negativeFillColors = instance.chartEngine.graphNegativeFillColors;
                    graph.negativeLineColor = instance.chartEngine.graphNegativeLineColor;

                    //Set negative base value
                    if (instance.chartEngine.chartData.set[0].pre) {
                        graph.negativeBase = instance.chartEngine.chartData.set[0].pre;
                    } else {
                        graph.negativeBase = instance.chartEngine.chartData.set[0].dat[0].value;
                    }
                }

                stockPanel.addStockGraph(graph);

                //Grap for left axis (sinceStartPerformance)
                if (instance.chartEngine.showSecondAxis) {
                    var graph2 = new AmCharts.StockGraph();
                    graph2.comparable = true;
                    graph2.compareField = "percent";
                    graph2.compareFromStart = true;
                    graph2.compareGraphFillAlphas = 0.2;
                    graph2.compareGraphLineAlpha = 1;
                    graph2.valueField = "percent";
                    graph2.type = "none";
                    graph2.showBalloon = false;
                    graph2.fillAlphas = 0.5;
                    graph2.valueAxis = valueAxis2;
                    stockPanel.addStockGraph(graph2);
                }

                // Balloon settings
                var balloon = instance.chartEngine.chart.balloon;
                balloon.adjustBorderColor = instance.chartEngine.balloonAdjustBorderColor;
                balloon.adjustFillColor = instance.chartEngine.balloonAdjustFillColor;
                balloon.fontFamily = instance.chartEngine.balloonFontFamily ? instance.chartEngine.balloonFontFamily : null;
                balloon.borderAlpha = 1;
                balloon.borderColor = '#333333';
                balloon.borderThickness = 1;
                balloon.color = instance.chartEngine.balloonColor ? instance.chartEngine.balloonColor : "#333333";
                balloon.cornerRadius = instance.chartEngine.balloonCornerRadius;
                balloon.fillColor = instance.chartEngine.balloonFillColor;
                balloon.fontSize = instance.chartEngine.balloonFontSize;
                balloon.horizontalPadding = instance.chartEngine.balloonHorizontalPadding;
                balloon.verticalPadding = instance.chartEngine.balloonVerticalPadding;
                balloon.textShadowColor = instance.chartEngine.balloonTextShadowColor ? instance.chartEngine.balloonTextShadowColor : '#ffffff';
                balloon.pointerWidth = instance.chartEngine.balloonPointerWidth;
                balloon.productLabel = instance.valor;

                //Category axis settings
                var categoryAxesSettings = new AmCharts.CategoryAxesSettings();
                categoryAxesSettings.autoGridCount = instance.chartEngine.categoryAxesSettingsAutoGridCount;
                categoryAxesSettings.axisAlpha = instance.chartEngine.categoryAxisAlpha;
                categoryAxesSettings.axisColor = instance.chartEngine.categoryAxisColor;
                categoryAxesSettings.axisHeight = instance.chartEngine.categoryAxisHeight;
                categoryAxesSettings.axisThickness = instance.chartEngine.categoryAxisThickness;
                categoryAxesSettings.boldPeriodBeginning = instance.chartEngine.categoryAxesSettingsBoldPeriodBeginning;
                categoryAxesSettings.dateFormats = instance.chartEngine.categoryAxisDateFormats;
                categoryAxesSettings.color = instance.chartEngine.categoryAxesSettingsColor;
                categoryAxesSettings.equalSpacing = instance.chartEngine.categoryAxesSettingsEqualSpacing;
                categoryAxesSettings.fillColor = instance.chartEngine.categoryAxesSettingsFillColor;
                categoryAxesSettings.fillAlpha = instance.chartEngine.categoryAxesSettingsFillAlpha;
                categoryAxesSettings.fontSize = instance.chartEngine.categoryAxesSettingsFontSize;
                categoryAxesSettings.gridAlpha = instance.chartEngine.categoryAxesSettingsGridAlpha;
                categoryAxesSettings.gridCount = instance.chartEngine.categoryAxisGridCount;
                categoryAxesSettings.gridColor = instance.chartEngine.categoryAxisGridColor;
                categoryAxesSettings.inside = false;
                categoryAxesSettings.labelsEnabled = true;
                categoryAxesSettings.minPeriod = instance.chartEngine.categoryAxesSettingsMinPeriod;
                categoryAxesSettings.maxSeries = instance.chartEngine.categoryAxesMaxSeries;
                categoryAxesSettings.offset = instance.chartEngine.categoryAxesSettingsOffset;
                categoryAxesSettings.parseDates = true;
                categoryAxesSettings.position = instance.chartEngine.categoryAxesSettingsPosition;
                categoryAxesSettings.tickLength = instance.chartEngine.categoryAxesSettingsTickLength;
                instance.chartEngine.chart.categoryAxesSettings = categoryAxesSettings;

                // Chartcursor
                var chartCursorSettings = new AmCharts.ChartCursorSettings();
                chartCursorSettings.enabled = instance.chartEngine.showCursor;
                chartCursorSettings.bulletsEnabled = true;
                chartCursorSettings.bulletSize = instance.chartEngine.chartCursorSettingsBulletSize;
                chartCursorSettings.categoryBalloonEnabled = instance.chartEngine.chartCursorSettingsCategoryBalloonEnabled;
                chartCursorSettings.categoryBalloonDateFormats = instance.chartEngine.chartCursorSettingsCategoryBalloonDateFormats;
                chartCursorSettings.cursorColor = instance.chartEngine.chartCursorSettingsCursorColor;
                chartCursorSettings.minPeriod = 'DD';
                chartCursorSettings.oneBalloonOnly = instance.chartEngine.chartCursorSettingsOneBalloonOnly;
                chartCursorSettings.pan = false;
                chartCursorSettings.valueBalloonsEnabled = instance.chartEngine.chartCursorSettingsValueBalloonsEnabled;
                chartCursorSettings.zoomable = false;
                instance.chartEngine.chart.chartCursorSettings = chartCursorSettings;

                // Scrollbar
                var chartScrollbarSettings = new AmCharts.ChartScrollbarSettings();
                chartScrollbarSettings.enabled = false;
                instance.chartEngine.chart.chartScrollbarSettings = chartScrollbarSettings;

                // Guide previousClose
                if (instance.chartEngine.chartData.set[0].pre && !instance.benchmarks.searchObjectsByKey('active', true).length) {
                    ChartGuidesService.setGuide({
                        axes: valueAxis,
                        balloonColor: instance.chartEngine.guides.previousClose.balloonColor,
                        color: instance.chartEngine.guides.previousClose.color,
                        dashLength: instance.chartEngine.guides.previousClose.dashLength,
                        data: instance.chartEngine.chartData.set[0].pre,
                        fontFamily: instance.chartEngine.guides.previousClose.fontFamily,
                        fontSize: instance.chartEngine.guides.previousClose.fontSize,
                        label: instance.chartEngine.translations.previousClose,
                        lineThickness: instance.chartEngine.guides.previousClose.lineThickness,
                        offset: instance.chartEngine.guides.previousClose.offset,
                        padding: instance.chartEngine.guides.barrier.padding,
                        position: instance.chartEngine.guides.previousClose.position,
                        showTriangle: instance.chartEngine.guides.previousClose.showTriangle,
                        textAlign: instance.chartEngine.guides.previousClose.textAlign,
                        width: instance.chartEngine.panelsSettingsMarginLeft
                    });
                }

                // Other guides
                if (instance.chartEngine.chartData.gde && !instance.guidesHidden) {

                    var equalValues;

                    // Modify guides
                    // If guides have the same value combine the labels and render just one
                    for (g = 0; g < instance.chartEngine.chartData.gde.length; g++) {
                        instance.chartEngine.chartData.gde[g].value = AmCharts.roundTo(instance.chartEngine.chartData.gde[g].value, 2);
                        equalValues = instance.chartEngine.chartData.gde.searchObjectsByKey('value', instance.chartEngine.chartData.gde[g].value);

                        if (equalValues.length) {
                            angular.forEach(equalValues, function(guide, index) {
                                if (index >= 1) {
                                    equalValues[0].label += guide.label;
                                    guide.label = '';
                                    guide.skip = true;
                                }
                            });
                        }
                    }

                    for (g = 0; g < instance.chartEngine.chartData.gde.length; g++) {
                        if (instance.chartEngine.chartData.gde[g].skip) {
                            continue;
                        }
                        ChartGuidesService.setGuide({
                            axes: valueAxis,
                            balloonColor: instance.chartEngine.guides.barrier.balloonColor,
                            color: instance.chartEngine.guides.barrier.color,
                            dashLength: instance.chartEngine.guides.barrier.dashLength,
                            data: instance.chartEngine.chartData.gde[g].value,
                            fontFamily: instance.chartEngine.guides.barrier.fontFamily,
                            fontSize: instance.chartEngine.guides.barrier.fontSize,
                            inside: instance.chartEngine.guides.barrier.inside,
                            label: instance.chartEngine.translations[instance.chartEngine.chartData.gde[g].label],
                            lineThickness: instance.chartEngine.guides.barrier.lineThickness,
                            offset: instance.chartEngine.guides.barrier.offset,
                            padding: instance.chartEngine.guides.barrier.padding,
                            position: instance.chartEngine.guides.barrier.position,
                            showTriangle: instance.chartEngine.guides.barrier.showTriangle,
                            textAlign: instance.chartEngine.guides.barrier.textAlign,
                            width: instance.chartEngine.panelsSettingsMarginLeft
                        });
                    }
                }

                //Write chart
                if ($(instance.container)) {

                    //Moving average
                    instance.chartEngine.chart.write(instance.container[0]);
                }

                deferred.resolve();
                return deferred.promise;
            };

            //Get chart data
            var getData = function() {
                var deferred = $q.defer(),
                    url,
                    i,
                    benchmarkSins = [];

                //If static data is available
                if (instance.staticData) {
                    instance.chartEngine.chartData = instance.staticData;
                    deferred.resolve();
                    return deferred.promise;
                } else if (instance.theme === 'candlestick') {
                    instance.chartType = 'ohlc';
                }

                //Build url
                url = instance.baseUrl + '?' + instance.identifier + '=' + instance.id;

                //Add timespan
                if (instance.chartEngine.selectedPeriod && instance.theme !== 'forwardCurve') {
                    url += '&timespan=' + instance.chartEngine.selectedPeriod;
                }

                //Add benchmarks
                if (instance.benchmarks && instance.benchmarks.length) {
                    for (i = 0; i < instance.benchmarks.length; i++) {
                        benchmarkSins.push(instance.benchmarks[i].sin);
                    }
                    url += '&' + decodeURI($.param({
                        benchmarkSins: benchmarkSins
                    }));
                }

                //Request
                return $http({
                    method: 'GET',
                    url: ApiService.get(url).url,
                    headers: ApiService.getHeaders()
                }).then(function(response) {
                    var data = response.data;
                    if (data.state === 'ok') {

                        //Write chartdata
                        instance.chartEngine.chartData = data;

                        //Chart notification
                        if (data.message) {
                            $(instance.container).html(data.message);
                        }

                        deferred.resolve();
                        return deferred.promise;

                    } else { //Backend error
                        $(instance.container).html(data.message);
                        deferred.reject();
                        return deferred.promise;
                    }
                }, function() {
                    $(instance.container).html(instance.chartEngine.translations.chart_error);
                    deferred.reject();
                    return deferred.promise;
                }).finally(function() {
                    $(instance.container).removeClass('icon-loading');
                });
            };

            //Get chart data
            var getDataOnly = function(container, identifier, id, timespan) {

                var deferred = $q.defer(),
                    url;

                //Show loader
                $(instance.container).addClass('icon-loading');

                //Build url
                url = '/portfolios/chart/line/theme/ivestorChart/';

                //Add timespan
                url += 'timespan/' + timespan + '/';
                url += identifier + '/' + id + '/presenter/js/format/json';

                //Request
                return $http({
                        method: 'GET',
                        url: ApiService.get(url).url,
                        headers: ApiService.getHeaders()
                    })
                    .then(function(response) {

                        var data = response.data;

                        if (data.state === 'ok') {

                            //Write chartdata
                            deferred.resolve(data);
                            return deferred.promise;

                        } else { //Backend error
                            $(instance.container).html(data.message);
                            deferred.reject();
                            return deferred.promise;
                        }

                    }, function() {
                        $(instance.container).html(instance.chartEngine.translations.chart_error);
                        deferred.reject();
                        return deferred.promise;
                    }).finally(function() {
                        $(instance.container).removeClass('icon-loading');
                    });
            };

            //Set timespan
            var setTimespan = function(timespan) {
                instance.chartEngine.selectedPeriod = timespan;
                update();
            };

            //Set timespan
            var setZoom = function(timespan) {
                var toDate = new Date(),
                    fromDate = window.getDateFromTimespan(toDate, timespan);

                instance.chartEngine.selectedPeriod = timespan;
                instance.chartEngine.chart.zoom(fromDate, toDate);

                // Apply timespan specific settings
                if (ChartSettingsService.defaultTimespanSettings[timespan]) {
                    instance.chartEngine.chart.categoryAxesSettings.autoGridCount = ChartSettingsService.defaultTimespanSettings[timespan].categoryAxesSettingsAutoGridCount;
                }

                instance.chartEngine.chart.validateNow();
            };

            var setType = function(type) {
                instance.theme = type;
                switch (type) {
                    default:
                    case 'step':
                        instance.chartType = 'productDetail';
                        break;
                    case 'candlestick':
                    case 'ohlc':
                        instance.chartType = 'ohlc';
                        break;
                }
                update();
            };

            //Toggle benchmark
            var toggleBenchmark = function(flag) {
                var deferred = $q.defer(),
                    dataSet,
                    i,
                    activeBenchmarks,
                    guides = instance.chartEngine.chart.panels[0].valueAxes[0].guides;

                // Update benchmark visibility
                for (i = 0; i < instance.benchmarks.length; i++) {
                    dataSet = instance.chartEngine.chart.dataSets.searchObjectsByKey('sin', instance.benchmarks[i].sin)[0];

                    // Toggle compare mode
                    if (typeof flag !== 'undefined') { // Set flag state
                        dataSet.compared = flag;
                    } else { // toggle
                        dataSet.compared = instance.benchmarks[i].active;
                    }
                }

                // If no benchmark available disabled guides
                activeBenchmarks = instance.benchmarks.searchObjectsByKey('active', true).length;
                if (instance.mainGraphLineAlpha && ((!activeBenchmarks && guides.length) || (typeof flag !== 'undefined' && flag === false))) {

                    // Store guides
                    instance.guidesHidden = instance.chartEngine.chart.panels[0].valueAxes[0].guides;

                    // Remove guides from axis
                    instance.chartEngine.chart.panels[0].valueAxes[0].guides = [];

                } else if (instance.guidesHidden) {

                    // Reactivate guides
                    instance.chartEngine.chart.panels[0].valueAxes[0].guides = instance.guidesHidden;
                    instance.guidesHidden = null;
                }

                // Toggle recalculation to percents
                instance.chartEngine.chart.validateData();

                deferred.resolve();
                return deferred.promise;
            };


            //Toggle mainDataset
            var toggleMainDataset = function() {
                var deferred = $q.defer();

                // Toggle main data graph
                instance.chartEngine.chart.panels[0].graphs[0].hidden = !instance.chartEngine.chart.panels[0].graphs[0].hidden;
                instance.mainDataSetHidden = !instance.mainDataSetHidden;
                instance.chartEngine.chart.validateData();

                deferred.resolve(instance.chartEngine.chart.panels[0].graphs[0].hidden);
                return deferred.promise;
            };

            // Toggle main graph visibility
            var toggleMainGraphVisibility = function() {
                var bool,
                    activeBenchmarks = instance.benchmarks.searchObjectsByKey('active', true).length;

                instance.mainGraphLineAlpha = !instance.mainGraphLineAlpha ? 1 : 0;
                bool = !instance.mainGraphLineAlpha ? false : true;

                // Reactivate guides
                if (!bool && instance.guidesHidden) {
                    instance.chartEngine.chart.panels[0].valueAxes[0].guides = instance.guidesHidden;
                    instance.guidesHidden = null;
                } else if (bool && !instance.guidesHidden && !activeBenchmarks) {
                    instance.guidesHidden = instance.chartEngine.chart.panels[0].valueAxes[0].guides;
                    instance.chartEngine.chart.panels[0].valueAxes[0].guides = [];
                }

                instance.chartEngine.chart.panels[0].graphs[0].lineAlpha = instance.mainGraphLineAlpha;
                instance.chartEngine.chart.panels[0].graphs[0].cursorBulletAlpha = instance.mainGraphLineAlpha;
                instance.chartEngine.chart.validateNow();
                return bool;
            };

            //Prepare chartdata
            var prepareData = function prepareData(data) {
                var deferred = $q.defer(),
                    i,
                    datasets = data.set;

                for (i = 0; i < datasets.length; i++) {
                    var dataset = data.set[i].dat,
                        entry,
                        sum = 0;

                    //Convert to javascript date object
                    for (entry in dataset) {
                        dataset[entry].date = new Date(dataset[entry].date);

                        // Workaround for displaying percent values compared from the first date for every period
                        if (instance.chartEngine.chartGraph === 'percentsAbsolute') {

                            // Calculate percents based on issuer price
                            if (instance.issuePrice && i === 0) {
                                calculatePercents(dataset[entry], instance.issuePrice.value);

                            } else if (!sum && Number(dataset[entry].value)) {

                                // Calculate percents based on first available value
                                sum = Number(dataset[entry].value);
                            } else {
                                calculatePercents(dataset[entry], sum);
                            }
                        }

                        // #63905 To render properly charts in case of price 0
                        if (dataset[entry].value === 0) {
                            dataset[entry].value = "-";
                        }
                    }
                }

                // Overwrite first datapoint
                if (instance.issuePrice) {
                    var firstDataPoint = data.set[0].dat[0];
                    firstDataPoint.value = instance.issuePrice.value;
                    firstDataPoint.value2 = 0.0001;
                    firstDataPoint.realValue = instance.issuePrice.value;
                }

                //Set enddate in the future
                if (instance.targetFinder && instance.chartEngine.selectedPeriod !== '1D') {
                    var d = new Date();

                    d.setMonth(d.getMonth() + 1);
                    datasets[0].dat.push({
                        value: 'null',
                        date: d
                    });
                }

                deferred.resolve();
                return deferred.promise;
            };

            //Update chart settings
            var update = function() {

                var deferred = $q.defer();

                //Show loader
                $(instance.container).addClass('icon-loading');

                //Clear data
                instance.chartEngine.chartData = null;

                //Get chart data
                getData()
                    .then(function() {

                        //Prepare data
                        prepareData(instance.chartEngine.chartData)
                            .then(function() {

                                //Draw chart
                                drawChart()
                                    .then(function() {
                                        deferred.resolve();
                                    }, function() { //Error drawChart()
                                        console.log('CHART ERROR: drawChart()');
                                    });
                            }, function() { //Error prepareData()
                                console.log('CHART ERROR: prepareData()');
                            });
                    });

                return deferred.promise;
            };

            //Toggle guides
            var toggleGuides = function() {

                //chart.guides[i].fillAlpha = 0;
                var guides = instance.chartEngine.chart.panels[0].valueAxes[0].guides,
                    newGuides = [],
                    i;

                //Toggle guides
                if (!instance.guides) {
                    instance.guides = guides;

                    //Get guides
                    for (i = 0; i < guides.length; i++) {
                        if (!guides[i].toggle) {
                            newGuides.push(guides[i]);
                        }
                    }
                } else {
                    newGuides = instance.guides;
                    instance.guides = null;
                }
                instance.chartEngine.chart.panels[0].valueAxes[0].guides = newGuides;
                instance.chartEngine.chart.validateData();
            };

            return {
                chart: instance,
                init: init,
                update: update,
                getDataOnly: getDataOnly,
                toggleGuides: toggleGuides,
                setTimespan: setTimespan,
                setType: setType,
                setZoom: setZoom,
                toggleBenchmark: toggleBenchmark,
                toggleMainDataset: toggleMainDataset,
                toggleMainGraphVisibility: toggleMainGraphVisibility
            };
        };

        return ChartService;
    });
