// profiler.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('ProfilerService', ProfilerService);

    ProfilerService.$inject = ['$cookies', '$rootScope', 'AppConfigService'];

    function ProfilerService($cookies, $rootScope, AppConfigService) {

        var pageEndLoadTime = 0,
            pageStartLoadTime = 0;

        return {
            setProfilerId: function(profilerId) {
                if (AppConfigService.isInternalNetwork()) {
                    $cookies.put('profilerPageRequestId', profilerId);
                    $rootScope.$broadcast('ProfilerIdChange', profilerId);
                }
            },

            resetProfiler: function() {
                $cookies.remove('profilerPageRequestId');
                $cookies.remove('shutProfilerOff');
                pageStartLoadTime = performance.now();
                pageEndLoadTime = performance.now();
            },

            setTimeLoad: function() {
                pageEndLoadTime = performance.now();
                $rootScope.$broadcast('PageLoadEnd', pageEndLoadTime - pageStartLoadTime);
            },

            getProfilerState: function() {
                var status = $cookies.get('shutProfilerOff');
                return status === "1";
            },

            switchProfilerState: function() {
                if (AppConfigService.isInternalNetwork()) {
                var newState = $cookies.get('shutProfilerOff') === "1" ? "0" : "1";
                $cookies.put('shutProfilerOff', newState);
                }
            }
        };
    }
})();