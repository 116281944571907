// api.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('ApiService', ApiService);

    ApiService.$inject = [
        '$location',
        'ProfilerService',
        'API',
        'APIHEADERS',
        'APISEARCH',
        'APIREPORT',
        '$http',
        'HelperService',
        '$q'
    ];

    function ApiService($location, ProfilerService, API, APIHEADERS, APISEARCH, APIREPORT, $http, HelperService, $q) {
        return {
            get: function(urlParams) {

                // Set return params.
                var api = {
                    url: API + urlParams // jshint ignore:line
                };

                // Use with remotes
                if (window.remoteBaseURL) {
                    api.url = window.remoteBaseURL + API + urlParams // jshint ignore:line
                }

                return api;
            },

            /**
             * Returns search specific url
             */
            getSearch: function() {

                // Set return params.
                var api = {
                    url: APISEARCH // jshint ignore:line
                };

                // Use with remotes
                if (window.remoteBaseURL) {
                    api.url = window.remoteBaseURL + APISEARCH; // jshint ignore:line
                }

                return api;
            },

            /**
             * Method to get the product report containing chart.
             * @param sin
             * @param domContainerElement
             * @param chartBenchMarks
             * @param valor
             * @returns {*}
             */
            getProductReportWithChart: function(sin, domContainerElement, chartBenchMarks, valor) {
                var self = this,
                    legend = [],
                    deferred = $q.defer();
                $(domContainerElement).addClass('icon-loading');

                // setting up legend data
                chartBenchMarks.forEach(function(i) {
                    var rgb = HelperService.hexToRgb(i.color),
                    legendItem = {
                        "title": i.label,
                        "R": rgb.r,
                        "G": rgb.g,
                        "B": rgb.b
                    };
                    legend.push(legendItem);
                });

                // Add valor legend since its not part of chartBenchMarks array
                legend.push({
                    "title": "Valor: " + valor,
                    "R": 0,
                    "G": 0,
                    "B": 0});
                HelperService.getPngFromSvg($(domContainerElement).find('svg'))
                    .then(function(pngTag) {
                        self.makeRequest('POST',
                            APIREPORT + sin,
                            domContainerElement,
                            {
                                "chart": pngTag,
                                "legend": legend
                            },
                            deferred
                        );
                }, function(error) {
                    console.log('SVG to PNG conversion failed:', error);
                    console.log('Requesting Report with empty chart data', error);
                        self.makeRequest('POST',
                            APIREPORT + sin,
                            domContainerElement,
                            {
                                "chart": "",
                                "legend": legend
                            },
                            deferred
                        );
                    deferred.reject(error);
                });

                return deferred.promise;

            },

            /**
             * Method to get the product report
             * @param sin
             * @param domContainerElement
             */
            getProductReport: function(sin, domContainerElement) {
                this.makeRequest('GET',
                    APIREPORT + sin,
                    domContainerElement);
            },

            /**
             * Method to make request to backend
             * @param method - The request method; POST or GET
             * @param url - The backend url to make a request to
             * @param domContainerElement - The dom element for which to show the loading icon
             * @param data - The data that needs to be posted to the backend
             * @param deferred - The other promise that is depending on this request
             * @returns {*}
             */
            makeRequest: function(method, url, domContainerElement, data, deferred) {

                // Show loading icon
                $(domContainerElement).addClass('icon-loading');
                var requestBody = {
                    method: method,
                    url: url,
                    responseType: 'json'
                }, reportUrl;

                if (data){
                    requestBody.data = data;
                }

                $http(requestBody).then(function(response) {
                    reportUrl = response.data.data.url;
                }, function(error) {
                    console.log('APIREPORT request failed: ', error);
                    if (deferred){
                        deferred.reject(error);
                    }
                }).finally(function() {

                    // Hide loading icon
                    $(domContainerElement).removeClass('icon-loading');

                    // Use with remotes
                    if (window.remoteBaseURL) {
                        reportUrl = window.remoteBaseURL + reportUrl; // jshint ignore:line
                    }

                    window.open(reportUrl, "_blank");

                    if (deferred){
                        deferred.resolve();
                    }
                });

                if (deferred){
                   return deferred.promise;
                }

            },
            
            getHeaders: function() {
                APIHEADERS['Frontend-URL'] = encodeURIComponent($location.$$url); // jshint ignore:line
                return APIHEADERS;
            },

            setProfilerCookie: function(id) {
                ProfilerService.setProfilerId(id);
            }
        };
    }
})();