(function() {

    'use strict';

    angular.module('app.services')
        .factory('ChartMarginService', ChartMarginService);

    ChartMarginService.$inject = [];

    function ChartMarginService() {
        return {

            /**
             * Calculate margin width
             * @param {Array} sets - Datasets
             * @param {Array} guides - Guides
             * @param {Array} marginSteps - Margin of current value axis
             * @param {Object} translations - translations
             */
            calculateMargin: function(obj) {
                var i,
                    g,
                    guidesMax = [],
                    maxValueLength,
                    lastTicksValueLength = [],
                    thisTickLength,
                    marginWidth;

                // Get decimal digits length
                for (i = 0; i < obj.sets.length; i++) {
                    if (!obj.sets[i].dat[obj.sets[i].dat.length - 1]) {
                        lastTicksValueLength.push(2);
                    } else if (obj.sets[i].dat[obj.sets[i].dat.length - 1].value && obj.sets[i].dat[obj.sets[i].dat.length - 1].value !== 'null') {
                        thisTickLength = obj.sets[i].dat[obj.sets[i].dat.length - 1].value.toString().split('.')[0].length;
                        lastTicksValueLength.push(thisTickLength);
                    } else if (obj.sets[i].dat[obj.sets[i].dat.length - 1].value) {
                        thisTickLength = obj.sets[i].dat[obj.sets[i].dat.length - 2].value.toString().split('.')[0].length;
                        lastTicksValueLength.push(thisTickLength);
                    }
                }
                maxValueLength = lastTicksValueLength.sort(function(a, b) {
                    return b - a;
                })[0] + 4;

                if (!maxValueLength) {
                    maxValueLength = 1;
                }
                marginWidth = obj.marginSteps[maxValueLength];

                /**
                 * Get guides max length
                 * Use translated label string for calculation
                 */
                if (!obj.guides || obj.skipGuides) {
                    obj.guides = [];
                }
                for (g = 0; g < obj.guides.length; g++) {
                    guidesMax.push(obj.translations[obj.guides[g].label].toString().length * 7);
                }
                guidesMax = Math.max.apply(null, guidesMax);

                // Check for longer labels within guides
                if (guidesMax) {
                    marginWidth = Math.max(guidesMax, obj.marginSteps[maxValueLength])
                }

                return marginWidth;
            }
        };
    }
})();