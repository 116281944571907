// productList.controller.js
(function() {
    'use strict';

    angular
        .module('app.controllers')
        .controller('ProductListController', ProductListController);

    // Inject dependencies.
    ProductListController.$inject = [
        '$scope',
        'BASEURL',
        '$state',
        '$transitions'
    ];

    function ProductListController(
        vm,
        BASEURL,
        $state,
        $transitions
    ) {
        vm.BASEURL = BASEURL;


        // Update tabs navigation when switch between tabs or access via external links
        vm.updateNavigation = function() {
            try {
                $('#product-list>tabs tab').show();
                $('#product-list>tabs>nav ul li').removeClass('active');
                $('#product-list>tabs>nav ul li[index=\'' + $state.current.name + '\']').addClass('active');
            } catch (e) {}
        };

        /**
         * Toggles product list legend
         * @param {Object} state - Current state object
         */
        function toggleProductListLegend(state) {
            if (state.name === 'productList.yieldMatrix') {
                vm.showProductListLegend = false;
            } else {
                vm.showProductListLegend = true;
            }
        }

        // Redirect to a defined state in the route config.
        $transitions.onStart({}, function(transition) {
            toggleProductListLegend(transition.to());
        });

        // Initially toggle product list legend
        toggleProductListLegend($state.current);
    }
})();