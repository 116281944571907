// clickOutSide.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('clickOutSide', clickOutSide);

    clickOutSide.$inject = ['$document'];

    function clickOutSide($document) {
        var directive = {
            restrict: 'A',
            link: link
        };

        return directive;

        function link(vm, elem, attr) {
            if (!attr.clickOutSide) {
                console.error('[clickOutSide directive] ClickOutSide attribute is required!');
                return;
            }

            var elemClickHandler = function(e) {
                    e.stopPropagation();
                },

                docClickHandler = function() {
                    vm.$apply(attr.clickOutSide);
                };

            elem.on('click', elemClickHandler);
            $document.on('click', docClickHandler);

            // teardown the event handlers when the scope is destroyed.
            vm.$on('$destroy', function() {
                elem.off('click', elemClickHandler);
                $document.off('click', docClickHandler);
            });
        }
    }

})();

