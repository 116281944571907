// httpWrapper.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('HttpWrapper', HttpWrapper);

    HttpWrapper.$inject = [
        'ApiService',
        '$http',
        '$rootScope',
        '$state'
    ];

    function HttpWrapper(
        ApiService,
        $http,
        $rootScope
    ) {

        return function(params, successCallback, errorCallback) {

            $http({
                method: params.method,
                url: ApiService.get(params.url).url,
                data: params.data,
                params: params.params,
                headers: ApiService.getHeaders()
            }).then(
                function(response) {
                    /**
                     * In case The request have to update the Profile URL, and the response have
                     * suitable profiler Id associated with
                     */
                    if (response.data.profiler && params.refreshProfiler) {
                        ApiService.setProfilerCookie(response.data.profiler);
                    }

                    if (response.data.state === 'OK' &&
                        response.status === 200
                    ) {
                        successCallback(response.data.data);

                        // Set browser title.
                        if (response.data.browserTitle) {
                            $rootScope.browserTitle = response.data.browserTitle;
                        }

                        // Set meta keywords.
                        if (response.data.metaKeywords) {
                            $('meta[name=keywords]').attr('content', response.data.metaKeywords);
                        }

                        // Set meta description.
                        if (response.data.metaDescription) {
                            $('meta[name=description]').attr('content', response.data.metaDescription);
                        }
                    } else {
                        var requestError = {};
                        requestError.url = ApiService.get(params.url).url;
                        if (response.data.state) {
                            requestError.state = response.data.state;
                            requestError.message = response.data.data;
                        } else {
                            requestError.status = response.status;
                            requestError.statusText = response.statusText;
                            requestError.display_exceptions = true;
                            requestError.exception = response.data;
                        }

                        $rootScope.$broadcast('RequestError', requestError);
                        errorCallback(response.data.data);
                    }
                },
                function(rejection) {   

                    // Check for 404 response code
                    if (rejection.status === 404) {
                        window.location.href = '/404';
                    } else {

                        // Fallback to /500 for all other codes
                        window.location.href = '/500';
                    }
                }
            );
        };
    }
})();