// SoftCallableMultiBarrierReverseConvertible.constants.js
(function() {
    'use strict';

    angular
        .module('app.constants')
        .constant("SoftCallableMultiBarrierReverseConvertible", {
            "mobile": {
                "inSubscription": [{
                        label: 'valor',
                        value: 'valor',
                        notSortable: true
                    },
                    {
                        label: 'couponPercentPa',
                        value: 'couponPercentPa',
                        precision: 2
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames',
                        icon: 'settlementMethodIcon'
                    },
                    {
                        label: 'currency',
                        value: 'currency',
                        icon: 'quantoIcon'
                    },
                    {
                        label: 'barrierLevelRelativePercent',
                        value: 'barrierLevelRelativePercent',
                        precision: 2,
                        icon: 'barrierObservationMethodIcon'
                    },
                    {
                        label: 'maturityDate',
                        value: 'maturityDate',
                        icon: 'earlyRedemptionIcon',
                        format: 'mediumDate'
                    },
                    {
                        label: 'subscriptionPeriodEndDateMobile',
                        value: 'subscriptionPeriodEndDate',
                        format: 'customDateTime'
                    },
                    {
                        label: ' ',
                        value: 'menu',
                        align: 'right',
                        notSortable: true
                    }
                ],
                "secondaryMarket": [{
                        label: 'valor',
                        value: 'valor',
                        notSortable: true
                    },
                    {
                        label: 'maxPerformancePercentPaPriceCurrency',
                        value: 'maxPerformancePercentPaPriceCurrency'
                    },
                    {
                        label: 'couponPercentPa',
                        value: 'couponPercentPa'
                    },
                    {
                        label: 'performance2NextObservationDatePriceCurrency',
                        value: 'performance2NextObservationDatePriceCurrency'
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames',
                        icon: 'settlementMethodIcon'
                    },
                    {
                        label: 'currency',
                        value: 'currency',
                        icon: 'quantoIcon'
                    },
                    {
                        label: 'distance2BarrierLevelPercent',
                        value: 'distance2BarrierLevelPercent',
                        precision: 2,
                        icon: 'barrierObservationMethodIcon'
                    },
                    {
                        label: 'maturityDate',
                        value: 'maturityDate',
                        icon: 'earlyRedemptionIcon',
                        format: 'mediumDate'
                    },
                    {
                        label: 'bid',
                        value: 'bid'
                    },
                    {
                        label: 'ask',
                        value: 'ask'
                    },
                    {
                        label: ' ',
                        value: 'menu',
                        align: 'right',
                        notSortable: true
                    }
                ]
            },
            "inSubscription": [{
                    label: 'couponPercentPa',
                    value: 'couponPercentPa',
                    precision: 2
                },
                {
                    label: 'underlyingNames',
                    value: 'underlyingNames',
                    icon: 'settlementMethodIcon',
                    minWidth: 200 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'currency',
                    value: 'currency',
                    icon: 'quantoIcon'
                },
                {
                    label: 'barrierLevelRelativePercent',
                    value: 'barrierLevelRelativePercent',
                    precision: 2,
                    icon: 'barrierObservationMethodIcon'
                },
                {
                    label: 'maturityDate',
                    value: 'maturityDate',
                    icon: 'earlyRedemptionIcon',
                    format: 'mediumDate'
                },
                {
                    label: 'subscriptionPeriodEndDate',
                    value: 'subscriptionPeriodEndDate',
                    format: 'customDateTime',
                    minWidth: 160 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'valor',
                    value: 'valor',
                    notSortable: true
                },
                {
                    label: ' ',
                    value: 'menu',
                    align: 'right',
                    notSortable: true
                }
            ],
            "secondaryMarket": [
                {
                    label: 'maxPerformancePercentPaPriceCurrency',
                    value: 'maxPerformancePercentPaPriceCurrency'
                },
                {
                    label: 'couponPercentPa',
                    value: 'couponPercentPa'
                },
                {
                    label: 'performance2NextObservationDatePriceCurrency',
                    value: 'performance2NextObservationDatePriceCurrency'
                },
                {
                    label: 'underlyingNames',
                    value: 'underlyingNames',
                    icon: 'settlementMethodIcon',
                    minWidth: 200 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'currency',
                    value: 'currency',
                    icon: 'quantoIcon'
                },
                {
                    label: 'distance2BarrierLevelPercent',
                    value: 'distance2BarrierLevelPercent',
                    precision: 2,
                    icon: 'barrierObservationMethodIcon'
                },
                {
                    label: 'maturityDate',
                    value: 'maturityDate',
                    icon: 'earlyRedemptionIcon',
                    format: 'mediumDate'
                },
                {
                    label: 'bid',
                    value: 'bid'
                },
                {
                    label: 'ask',
                    value: 'ask'
                },
                {
                    label: ' ',
                    value: 'menu',
                    align: 'right',
                    notSortable: true
                }
            ],
            "yieldMatrix": [{
                    label: 'underlying',
                    value: 'underlying'
                },
                {
                    label: 'currency',
                    value: 'currency',
                    icon: 'quantoIcon'
                },
                {
                    label: 'distanceRange2BarrierLevel',
                    value: 'distanceRange2BarrierLevel',
                    precision: 2
                },
                {
                    label: 'products_range_0',
                    value: 'range_0',
                    precision: 2
                },
                {
                    label: 'products_range_1',
                    value: 'range_1',
                    precision: 2
                },
                {
                    label: 'products_range_2',
                    value: 'range_2',
                    precision: 2
                },
                {
                    label: 'products_range_3',
                    value: 'range_3',
                    precision: 2
                },
                {
                    label: 'products_range_4',
                    value: 'range_4',
                    precision: 2
                },
                {
                    label: 'products_range_5',
                    value: 'range_5',
                    precision: 2
                }
            ],
            "productDetail": {
                "categorization": [{
                        label: 'product_category',
                        value: 'metaMetaGroupName'
                    },
                    {
                        label: 'metaGroupName',
                        value: 'metaGroupName'
                    },
                    {
                        label: 'groupName',
                        value: 'groupName'
                    }
                ],
                "lifecycle": [{
                        label: 'product_detail_subscriptionPeriodEndDate',
                        value: 'subscriptionPeriodEndDate',
                        visibleIf: 'inSubscription'
                    },
                    {
                        label: 'product_detail_fixingDate',
                        value: 'fixingDate'
                    },
                    {
                        label: 'product_detail_issueDate',
                        value: 'issueDate'
                    },
                    {
                        label: 'lastTradingDate',
                        value: 'lastTradingDate'
                    },
                    {
                        label: 'product_detail_maturityDate',
                        value: 'maturityDate'
                    },
                    {
                        label: 'product_detail_redemptionDate',
                        value: 'redemptionDate'
                    },
                    {
                        label: 'repayment',
                        value: 'static',
                        staticValue: 'true'
                    },
                    {
                        label: 'repaymentLevelPriCurPercentSoftCallable',
                        value: 'nextIssuerCallDate'
                    },
                    {
                        label: 'days2MaturityDate',
                        value: 'days2MaturityDate',
                        precision: 0
                    }
                ],
                "staticData": [{
                        label: 'issuer',
                        value: 'issuer'
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames'
                    },
                    {
                        label: 'issuePrice',
                        value: 'issuePrice',
                        precision: 2
                    },
                    {
                        label: 'currency',
                        value: 'currency'
                    },
                    {
                        label: 'isQuanto',
                        value: 'isQuanto'
                    },
                    {
                        label: 'settlementMethod',
                        value: 'settlementMethod'
                    },
                    {
                        label: 'quotingMethod',
                        value: 'quotingMethod'
                    },
                    {
                        label: 'notionalValue',
                        value: 'notionalValue',
                        precision: 0
                    },
                    {
                        label: 'listing',
                        value: 'inListing'
                    }
                ],
                "coupons": [{
                        label: 'couponPercentPa',
                        value: 'couponPercentPa',
                        precision: 2
                    },
                    {
                        label: 'couponPaymentFrequency',
                        value: 'couponPaymentFrequency'
                    },
                    {
                        label: 'accruedInterestPriCurPercent',
                        value: 'accruedInterestPriCurPercent',
                        precision: 3
                    }
                ],
                "couponPaymentDateAndInterest": [{
                        label: 'couponPaymentDate',
                        value: 'paymentDate'
                    },
                    {
                        label: 'couponPaymentInterest',
                        value: 'interest',
                        precision: 2
                    }
                ],
                "barrierLabel": 'barrierAndStrike',
                "barrier": [{
                        label: 'barrierLevelRelativePercent',
                        value: 'barrierLevelRelativePercent',
                        precision: 2
                    },
                    {
                        label: 'barrierObservationMethod',
                        value: 'barrierObservationMethod'
                    },
                    {
                        label: 'hasTouchedBarrier',
                        value: 'hasTouchedBarrier'
                    },
                    {
                        label: 'relativeStrike2ReferencePrice',
                        value: 'relativeStrike2ReferencePrice',
                        precision: 2
                    }
                ],
                "earlyRedemptionSoft": [{
                        label: 'issuerCallNoticePeriodEndDate',
                        value: 'issuerCallNoticePeriodEndDate'
                    },
                    {
                        label: 'repaymentValue',
                        value: 'repaymentValue',
                        precision: 2
                    }
                ],
                "pattern": [{
                        label: 'performanceSinceIssuePercent',
                        value: 'performanceSinceIssuePercent'
                    },
                    {
                        label: 'worstUnderlyingPerformance2ReferencePriceRelative',
                        value: 'worstUnderlyingPerformance2ReferencePriceRelative'
                    },
                    {
                        label: 'worstPerformanceUnderlying',
                        value: 'worstPerformanceUnderlying'
                    },
                    {
                        label: 'barrierHitProbability2MaturityPercent',
                        value: 'barrierHitProbability2MaturityPercent'
                    },
                    {
                        label: 'performance2NextObservationDatePriceCurrency',
                        value: 'performance2NextObservationDatePriceCurrency'
                    },
                    {
                        label: 'maxPerformancePercentStrikeCurrency',
                        value: 'maxPerformancePercentStrikeCurrency'
                    },
                    {
                        label: 'maxPerformancePercentPaPriceCurrency',
                        value: 'maxPerformancePercentPaPriceCurrency'
                    }
                ],
                "underlyings": [{
                        label: 'underlying',
                        value: 'underlying'
                    },
                    {
                        label: 'price',
                        value: 'price',
                        precision: 2
                    },
                    {
                        label: 'performance_since_issue_percent',
                        value: 'performanceSinceIssuePercent'
                    },
                    {
                        label: 'strike',
                        value: 'strike',
                        precision: 2
                    },
                    {
                        label: 'barrierLevelAbsoluteReferenceCurrency',
                        value: 'barrierLevelAbsoluteReferenceCurrency',
                        precision: 2
                    },
                    {
                        label: 'distance2BarrierLevelPercent',
                        value: 'distance2BarrierLevelPercent'
                    }
                ]
            }
        });
})();