// mediaQuery.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('MediaQueryService', MediaQueryService);

    // Inject dependencies.
    MediaQueryService.$inject = [];

    // Init InstrumentService.
    function MediaQueryService() {
        var MEDIA_SIZES = {
          MOBILE: 'mobile',
          MEDIUM: 'medium',
          REGULAR: 'regular'
        };
        return {
            mediaQuery: 'mobile',
            screenSize: null,

            getMediaSizes: function() {
              return MEDIA_SIZES;
            },

            /**
             * Check if current device is iOS or not
             * @returns {boolean}
             */
            isIos: function() {
                try {
                    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                } catch (e) {
                    console.log(e);
                }

                return false;
            },

            /**
             * Get iOS version
             * @returns {number}
             */
            getIosVersion: function() {
                try {
                    var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
                    return parseInt(v[1], 10);

                } catch (e) {
                    console.log(e);
                }

                return 0;
            },

            /**
             * Check if the device is iPhone X
             * @returns {boolean}
             */
            isIphoneX: function() {
                try {
                    var instance = this;

                    // Get the device pixel ratio
                    var ratio = window.devicePixelRatio || 1;

                    // Define the users device screen dimensions
                    var screen = {
                        width: window.screen.width * ratio,
                        height: window.screen.height * ratio
                    };

                    // iPhone X Detection
                    if (instance.isIos() && screen.width === 1125 && screen.height === 2436) {
                        return true;
                    }

                } catch (e) {
                    console.log(e);
                }

                return false;
            },

            /**
             * Get iOS classes to be added to body tag
             * @returns {string}
             */
            getIosClasses: function() {
                var instance = this,
                    classes = '';

                if (instance.isIos()) {
                    classes += 'ios';

                    if (instance.getIosVersion() !== 0) {
                        classes += ' ios_' + instance.getIosVersion();
                    }

                    if (instance.isIphoneX()) {
                        classes += ' iphone_x';
                    }
                }

                return classes;
            },

            /**
             * Calculate viewport size.
             * @returns {{width: *, height: *}}
             */
            viewport: function() {
                var e = window, a = 'inner';

                if (!('innerWidth' in window)) {
                    a = 'client';
                    e = document.documentElement || document.body;
                }

                return {
                    width: e[a + 'Width'],
                    height: e[a + 'Height']
                };
            },

            /**
             * Get current media query
             * @returns {string}
             */
            getMedia: function() {
                var instance = this;

                instance.screenSize = instance.viewport().width;

                if (instance.screenSize < 480) {
                    instance.mediaQuery = 'mobile';
                } else if (instance.screenSize < 960) {
                    instance.mediaQuery = 'medium';
                } else {
                    instance.mediaQuery = 'regular';
                }

                return instance.mediaQuery;
            },


            /**
             * Reload page on resize window.
             */
            reloadOnResize: function() {
                var instance = this,
                    currentMediaQuery = instance.getMedia(),
                    resizeTimeout = null;

                $(window).resize(function() {
                    clearTimeout(resizeTimeout);

                    resizeTimeout = setTimeout(function() {

                        // Check if media query has changed.
                        if (currentMediaQuery !== instance.getMedia()) {
                            currentMediaQuery = instance.getMedia();
                            location.reload();
                        }

                    }, 500);
                });
            },

            isMediaDesktop: function() {
                return this.getMedia() === this.getMediaSizes().REGULAR;
            },
            isMediaMedium: function() {
                return this.getMedia() === this.getMediaSizes().MEDIUM;
            },
            isMediaMobile: function() {
                return this.getMedia() === this.getMediaSizes().MOBILE;
            }
        };
    }
})();
