// tabsDynamic.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('tabsDynamic', tabsDynamic);

    // Inject dependencies.
    tabsDynamic.$inject = ['$timeout', 'MediaQueryService', 'TabsService'];

    function tabsDynamic($timeout, MediaQueryService, TabsService) {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                data: '='
            }
        };
        
        return directive;
        
        function link(vm, element, attrs) {

            try {
                angular.element(window).bind('hashchange', activateFromHash);
            } catch (e) {
                console.log(e);
            }

            $timeout(function() {
                vm.index = vm.data.start ? vm.data.start : 1;
                vm.changeFunc = vm.$eval(attrs.changeFunc);
    
                // Set navigation.
                setNavigation(vm, element);
				TabsService.setNavigationDimensions(vm, element);

                hideAllTabs(element);

                // Set start tab/nav.
                $(element).find('tab[index="' + vm.index + '"]').show();

                var tab = $(element).find('ul li[index="' + vm.index + '"]');
                tab.addClass('active');

                try {
                    activateFromHash();
                } catch (e) {
                    console.log(e);
                }

            }, 10);

            vm.$on('$destroy', function() {
                try {
                    angular.element(window).unbind('hashchange', activateFromHash);
                } catch (e) {
                    console.log(e);
                }
            });

            function activateFromHash() {
                if (getHash()) {
                    var hashedTab = $(element).find('ul li[data-anchor=' + getHash() + ']');
                    if (hashedTab.length > 0) {

                        hideAllTabs(element);

                        vm.index = $(element).find('ul li[data-anchor=' + getHash() + ']').attr('index');

                        hashedTab.addClass('active');
                        $(element).find('tab[data-anchor=' + getHash() + ']').show();
                    }
                }
            }
        }



        function hideAllTabs(element) {

            // deactivate all active tabs
            $(element).find('nav > ul li').removeClass('active');
            $(element).find('> tab').hide();
        }

        function getHash() {
            if (window.location.hash) {
                return window.location.hash.substring(1);
            }

            return null;
        }

        /**
         * Set navigation.
         * @param {object} vm - $scope.
         * @param {object} element - Directive element.
         */
        function setNavigation(vm, element) {
            var nav = $(element).find('nav ul li');
            
            $.each(nav, function() {
                $(this).click(function() {
                    var index = $(this).attr('index'),
						position = nav.index($(this));

					// Horizontally Scroll to clicked label
					try {
						if (MediaQueryService.getMedia() !== 'regular') {
							$(this).parent().parent().animate({scrollLeft: ($(this).parent().width() / nav.length) * (position - 1) + 50}, 200);
						}
					} catch (e) {
						console.log(e);
					}

                    if (index === vm.index) {
                        return;
                    } else {
                        hideAllTabs(element);

                        // Toggle active states.
                        $(element).find('ul li[index="' + vm.index + '"]').removeClass('active');
                        $(element).find('ul li[index="' + index + '"]').addClass('active');
                        $(element).find('tab[index="' + vm.index + '"]').hide();
                        
                        vm.index = index;
                        
                        $(element).find('tab[index="' + index + '"]').show(); // Show new tab.
                    }

                    try {
                        if ($(this).data('anchor')) {
                            window.location.hash = '#' + $(this).data('anchor');
                        }
                    } catch (e) {
                        console.log(e);
                    }

                    // Trigger change function.
                    if (vm.changeFunc) {
                        vm.changeFunc(index);
                    }
                });
            });
        }
    }
})();