// route-config.js
angular
    .module('app')
    .config(config);

function config(
    $stateProvider,
    $translateProvider,
    $locationProvider,
    $logProvider,
    $urlRouterProvider
) {
    'use strict';

    /** 
     *  Get url for 404 state
     *  Fallback url is "/"
     */
    var errorPageState = window.AppConfig.navigation.unlinked.searchObjectsByKey('stateName', '404')[0];
    if (!errorPageState) {
        errorPageState = {
            url: '/'
        };
    }

    // Set html5 mode.
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    });

    // Disable internal logging
    $logProvider.debugEnabled(false);

    // Allow case insensitive routes only for product details routes
    $urlRouterProvider.rule(function($injector, $location) {
        var path = $location.path(),
            normalized = path.toLowerCase();
            
        if (path !== normalized &&
            (normalized.indexOf("/valor/") > -1 ||
                normalized.indexOf("/isin/") > -1 ||
                normalized.indexOf("/wkn/") > -1)
        ) {
            $location.replace().path(normalized);
        }
    });

    //For any unmatched url, redirect to errorpage
    $urlRouterProvider.otherwise(function() {

        // Reloading instead of regular Angular change state (to force getting 404 response code from PHP)
        window.location.href = errorPageState.url;
    });

    var getShortUrlProductPageController = function(identifier) {
        return function($state, $stateParams) {
            $state.go('productDetail', {
                identifier: identifier,
                identifierValue: $stateParams.identifierValue
            });
        };
    };

    // Setup states.
    $stateProvider


        /**
         * That state is the parent state of a lightbox.
         * There is ui-view="lightbox" within the index.html and lightbox.html.
         * lightbox.contact is a child state and the content of the lightbox.
         * To create different content, copy lightbox.contact.
         * With that construct, it should be possible to navigate from one lightbox to another.
         */
        .state('lightbox', {
            abstract: true,
            views: {
                lightbox: {
                    controller: 'LightboxController as lightbox',
                    templateUrl: '/templates/lightbox.html'
                }
            }
        })
        .state('shortlinkWKNInsensitive', {
            url: "/{id:wkn|Wkn|wKn|WKn|wkN|WkN|wKN|WKN}/:identifierValue",
            controller: getShortUrlProductPageController('valor')
        })
        .state('shortlinkIsinInsensitive', {
            url: "/{id:isin|Isin|iSin|ISin|isIn|IsIn|iSIn|ISIn|isiN|IsiN|iSiN|ISiN|isIN|IsIN|iSIN|ISIN}/:identifierValue",
            controller: getShortUrlProductPageController('isin')
        })
        .state('shortlinkValorInsensitive', {
            url: "/{id:valor|Valor|vAlor|VAlor|vaLor|VaLor|vALor|VALor|valOr|ValOr|vAlOr|VAlOr|vaLOr|VaLOr|vALOr|VALOr|" +
                "valoR|ValoR|vAloR|VAloR|vaLoR|VaLoR|vALoR|VALoR|valOR|ValOR|vAlOR|VAlOR|vaLOR|VaLOR|vALOR|VALOR}/:identifierValue",
            controller: getShortUrlProductPageController('valor')
        });

    // Save $stateProvider global to add dynamic routes.
    window.$stateProviderRef = $stateProvider;

    // Load translations.
    $translateProvider.useSanitizeValueStrategy('escaped');
    $translateProvider.translations('de_CH', window.lang_de_CH);
    $translateProvider.translations('en_CH', window.lang_en_CH);
    $translateProvider.translations('fr_CH', window.lang_fr_CH);
    $translateProvider.translations('it_CH', window.lang_it_CH);
}