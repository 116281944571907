// productFilterCheckboxMultiple.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('productFilterCheckboxMultiple', productFilterCheckboxMultiple);

    // Inject dependencies.
    productFilterCheckboxMultiple.$inject = ['$timeout'];

    function productFilterCheckboxMultiple($timeout) {
        var directive = {
            controller: controller,
            restrict: 'E',
            templateUrl: window.BUILD_ID + '/templates/productsFilterCheckboxMultiple.html',
            scope: {
                data: '=',
                filterData: '=',
                submit: '='
            }
        };

        function controller($scope) {
            $scope.filterTemp = [];

            // Update filter data object
            $scope.change = function() {
                synchronizeData();
                
                $scope.filterData = $scope.filterTemp;

                // Submit changes
                $timeout(function() {
                    $scope.submit();
                }, 5);
            };

            // Synchronize filter with data
            function synchronizeData() {
                var field;
                $scope.filterTemp = [];

                // Get active checkboxes
                for (field in $scope.data.list) {
                    if ($scope.data.list[field].selected) {
                        $scope.filterTemp.push(field);
                    }
                }
            }

            synchronizeData();
        }

        return directive;
    }
})();