//instrumentTableMobile.directive.js
(function() {

    'use strict';

    angular
        .module('app.directives')
        .directive('instrumentTableMobile', instrumentTableMobile);

    instrumentTableMobile.$inject = [
        '$timeout',
        '$state',
        'PAGING'
    ];

    function instrumentTableMobile(
        $timeout,
        $state,
        PAGING
    ) {
        var directive = {
            controller: controller,
            link: link,
            templateUrl: '/templates/instrumentTableMobile.html',
            restrict: 'EA',
            scope: {
                data: '=',
                tpl: '=',
                menuFunc: '=',
                showAll: '=',
                changeFunc: '=',
                initialSorting: '=',
                applySortForAllGroups: '=',
                sortFunc: '=',
                tabKey: '='
            }
        };
        return directive;

        //controller
        function controller($scope) {

            //Goto product detail page
            $scope.goToProduct = function(item) {
                if (!item || !item.valor) {
                    return;
                }

                $timeout(function() {
                    $state.go('productDetail', {
                        identifier: 'valor',
                        identifierValue: item.valor.value
                    });
                });
            };

            $scope.$on("TriggerCalculateCells", function() {
                try {
                    $timeout(function() {
                        $scope.calculateCells();
                    }, 300);
                } catch (e) {
                    console.log(e);
                }
            });
        }

        //Init
        function link(scope, element) {
            var tableCellsStatic,
                tableCellsScrollableTR,
                tableCellsStaticTR,
                i,
                resizeTimeout,
                scrollTimeout,
                jElement = $(element);

            if (!scope.tpl) {
                return;
            }

            // Calculate cell height
            function calculateCells() {

                // Get cells and tr heights
                tableCellsStatic = jElement.find('.static td');
                tableCellsStaticTR = jElement.find('.static tr');
                tableCellsScrollableTR = jElement.find('.scrollable tr');

                for (i = 0; i < tableCellsStaticTR.length; i++) {
                    var maxHeight,
                        staticTR = $(tableCellsStaticTR[i]),
                        scrollableTR = $(tableCellsScrollableTR[i]);
                    
                    // Get max height
                    maxHeight = Math.max(
                        staticTR.height(),
                        scrollableTR.height()
                    );

                    if (maxHeight !== 0) { // IE Fix, check required because IE applies 0 height to <tr> otherwise
                        staticTR.height(maxHeight);
                        scrollableTR.height(maxHeight);
                    }
                }
            }

            scope.calculateCells = calculateCells;

            // Set paging
            function setPaging() {
                var paging;

                if (!scope.data.paging) {
                    paging = {
                        page: 1,
                        rowsPerPage: PAGING.rowsPerPage
                    };

                    // Set instrumnent count
                    paging.count = scope.data.count;

                    // Calculate pages
                    paging.pages = Math.ceil(paging.count / PAGING.rowsPerPage);

                    // Set intital paging object
                    scope.data.paging = paging;
                }

                // Enable paging, also removes "button all"
                scope.data.pagingEnabled = true;
            }

            //Toggle goto top button
            function setScrollListner(scrollContainer) {
                scrollContainer.scroll(function() {
                    if (scrollContainer.scrollLeft() + scrollContainer.innerWidth() >= scrollContainer[0].scrollWidth - 10) {
                        jElement.find('.paging').fadeIn();
                    } else {
                        jElement.find('.paging').fadeOut();
                    }
                });
            }

            // Extend current table data
            scope.extendData = function(data) {

                // Update current page index
                data.paging.page++;

                // Trigger update function
                scope.changeFunc(data, true).then(function() {
                    $timeout(function() {
                        calculateCells();
                    });
                });
            };

            function setSorting() {
                
                //Wait for dom is ready
                $timeout(function() {
                    var jElement = $(element),
                        btns = jElement.find('.static span[order-by]');

                    //Setup sort buttons
                    $.each(btns, function() {
                        $(this).click(function() {
                            try {
                                var sortIndex; // Index necessary for backend query ('asc' == 1, 'desc' === -1)

                                //Remove initial active states
                                if (scope.initialSorting) {
                                    jElement.find('.initial-active').removeClass('initial-active');
                                    jElement.find('.initial-' + scope.initialSorting.sortBy).removeClass('initial-' + scope.initialSorting.sortBy);
                                }

                                scope.type = $(this).attr('order-by');
                                if (scope.sort === 'asc') {
                                    scope.sort = 'desc';
                                    sortIndex = -1;
                                    scope.orderBy = scope.type + '.value';
                                } else {
                                    scope.sort = 'asc';
                                    sortIndex = 1;
                                    scope.orderBy = scope.type + '.value';
                                }

                                // Append current sorting to data object
                                scope.data.sorting = {};
                                scope.data.sorting[scope.type] = sortIndex;

                                scope.data.sortingKey = scope.type;
                                scope.data.sortingIndex = sortIndex;

                                /* Enable / Disable Sorting of all instrument tables
                                if (scope.applySortForAllGroups) {
                                    scope.applySortForAllGroups(scope.type, sortIndex);
                                }

                                scope.$apply();
                                */

                            } catch (e) {
                                console.log(e);
                            }

                            try {

                                //Calling SortGroup function from the instrument table mobile directive
                                if (scope.sortFunc) {
                                    scope.sortFunc(scope.data);
                                }

                            } catch (e) {
                                console.log(e);
                            }

                        });
                    });

                    // Set initial css classes if already sorted by the backend
                    try {
                        if (scope.data && !(scope.data.sortingKey) && scope.initialSorting) {
                            scope.data.sortingKey = scope.initialSorting.orderBy;
                            scope.data.sortingIndex = scope.initialSorting.sortBy === 'desc' ? -1 : 1;
                            scope.sort = scope.initialSorting.sortBy;
                        }
                    } catch (e) {
                        console.log(e);
                    }
                });

            }

            // Set loader.
            jElement.addClass('icon-loading');

            // Wait for dom and calculate cell height
            $timeout(function() {
                calculateCells();
                setPaging();
                setScrollListner(jElement.find('.scrollable'));
                setSorting();
                jElement.removeClass('icon-loading');
            }, 50);

            // Recalculate cells on resize
            $(window).resize(function() {
                clearTimeout(resizeTimeout);
                resizeTimeout = setTimeout(function() {
                    calculateCells();
                }, 200);
            });

            // Recalculate cells on scroll
            $(window).scroll(function() {
                clearTimeout(scrollTimeout);
                scrollTimeout = setTimeout(function() {
                    calculateCells();
                }, 200);
            });
        }
    }
})();