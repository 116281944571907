(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('investmentIdeaDetailComponent', ['$sce',
            function($sce) {
                return {
                    link: function(scope) {
                        if (scope.data && scope.data.bodyText) {
                            scope.bodyText = $sce.trustAsHtml(scope.data.bodyText);
                        }
                    },
                    restrict: 'E',
                    scope: {
                        name: '@?',
                        url: '@?',
                        data: '='
                    },
                    templateUrl: '/templates/investmentIdeaDetailComponent.html'
                };
            }]);
})();
