// footNoteText.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('footNoteText', footNoteText);

    // Inject dependencies.
    footNoteText.$inject = ['HideFootNoteTextForGroups'];

    function footNoteText(HideFootNoteTextForGroups) {
        var directive = {
            controller: controller,
            restrict: 'E',
            scope: {
                groupId: '<'
            },
            templateUrl: '/templates/footNoteText.html'
        };

        function controller($scope) {
            $scope.show = true; // display directive by default

            // If the groupId for this instrument is defined in 'HideFootNoteForGroups', we hide the foot note
            if (HideFootNoteTextForGroups.indexOf($scope.groupId) !== -1) {
                $scope.show = false;
            }
        }

        return directive;
    }
})();
