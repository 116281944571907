//instrumentTableIcon.directive.js
(function() {

    'use strict';

    angular
        .module('app.directives')
        .directive('instrumentTableMultiIcon', instrumentTableIcon);

    instrumentTableIcon.$inject = ['$timeout'];

    function instrumentTableIcon($timeout) {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                data: '='
            }
        };
        return directive;

        //Init
        function link(scope, element) {
            var content = '<span class="icon">';
            content += '<i class="icon-product icon-' + scope.data.icon.toLowerCase() + '">';
            content += '<span class="path1"></span><span class="path2"></span>';
            content += '</i>';
            content += '<div class="flyout"><p>' + scope.data.text + '</p></div>';

            $timeout(function() {
                var length = element && element.length && element.length > 0 ? element.length - 1 : 0,
                    valueContainer = $(element[length]).parent().find('em').last(),
                    icon;

                // Append 
                valueContainer.append($(content));
                                
                // Add eventlistener
                icon = valueContainer.find('.icon');
                icon.mouseenter(function() {
                    var flyout = $(this).find('.flyout');
                    flyout.toggle();
                });

                // Close on mouseleave
                valueContainer.mouseleave(function() {
                    var flyout = $(this).find('.flyout');
                    flyout.hide();
                });
            }, 1);
        }
    }
})();