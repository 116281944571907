(function() {
    'use strict';

    angular
        .module('app.filters')
        .filter('tabsLabel', tabsLabel);

	tabsLabel.$inject = [
	    'MediaQueryService',
        '$filter'
    ];

    function tabsLabel(MediaQueryService, $filter) {

		function htmlToPlaintext(text) {
			return text ? String(text).replace(/<[^>]+>/gm, '') : '';
		}

        return function(input) {

            var output = input;

			if (MediaQueryService.getMedia() !== 'regular') {
				output = htmlToPlaintext(input).length > 27 ? $filter('limitTo')(htmlToPlaintext(input), 27) + '...' : input;
            }

            return output;
        };
    }
}());