// productDetailPattern.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('productDetailPattern', productDetailPattern);

    // Inject dependencies.
    productDetailPattern.$inject = [
        'MediaQueryService',
        '$timeout',
        'TooltipService'
    ];

    function productDetailPattern(
        MediaQueryService,
        $timeout,
        TooltipService
    ) {
        var directive = {
            restrict: 'E',
            controller: controller,
            templateUrl: '/templates/productDetailPattern.html',
            scope: {
                data: '=',
                tpl: '='
            }
        };

        function controller($scope) {
            var list, width, liWidth,
                omitKey = 'performanceSinceIssuePercent',
                toHide = $scope.data.fields[omitKey];
            $scope.index = 0,
            $scope.media = MediaQueryService.getMedia(); // Set mobile flag

            if (omitKey && Object.prototype.hasOwnProperty.call(toHide, 'visible') && toHide.visible === false) {
                $scope.tpl = $scope.tpl.filter(function(template) {
                    return template.label === omitKey;
                });
            }

            // Update list position
            function animateList() {
                list.css('transform', 'translate3d(' + ($scope.index * -width) + 'px, 0, 0)');
            }

            /**
             * Change pattern field to next/previous
             * @param {Boolean} flag 
             */
            $scope.changePattern = function(flag) {
                var entries = $('product-detail-pattern-mobile ul.pattern li');

                // Move all list items forward or backward
                if (flag && $scope.index < entries.length - 1) {
                    $scope.index++;
                } else if (!flag && $scope.index) {
                    $scope.index--;
                }

                animateList();
            };

            /**
             * Jump to index
             * @param {Integer} index - Index of current pattern
             */
            $scope.jumpToIndex = function(index) {
                $scope.index = index;
                animateList();
            };

            /**
             * Initially calculate width and list element
             * Number 44 is the sum of elements padding + border;
             */
            $timeout(function() {
                list = $('product-detail-pattern-mobile ul.pattern');
                liWidth = $('product-detail-pattern-mobile ul.pattern li.active').width() + 44;
                width = $(window).width() - ($(window).width() - liWidth);
            });

            $scope.getTooltip = TooltipService.getTooltip;
        }

        return directive;
    }
})();
