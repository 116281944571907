// cmsPage.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('CmsPageService', CmsPageService);

    // Inject dependencies.
    CmsPageService.$inject = [
        'HttpWrapper',
        '$q'
    ];

    // Init CmsPageService.
    function CmsPageService(HttpWrapper, $q) {
        return {

            get: function(pageUrl) {
                var deferred = $q.defer();

                HttpWrapper({
                        method: 'GET',
                        url: '/' + pageUrl,
                        refreshProfiler: true
                    },
                    function(response) {
                        deferred.resolve(response);
                    },
                    function(rejection) {
                        deferred.reject(rejection);
                    }
                );

                return deferred.promise;
            }
        };
    }
})();