//formatCalendar.directive.js
(function() {

    'use strict';

    angular
        .module('app.directives')
        .directive('formatCalendar', formatCalendar);

    formatCalendar.$inject = [];

    function formatCalendar() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: link
        };
        return directive;

        function link(scope, element, attr, ngModel) {
            var regex = /^(31|30|[012]\d|\d)\.(0\d|1[012]|\d)\.(19[789]\d|20[0123]\d)$/;

            //Formatters
            ngModel.$formatters.push(function(val) {
                if (isNaN(val) || !val) {
                    return undefined;
                }

                var d = new Date(val * 1000);
                return (d.getUTCDate() <= 9 ? '0' : '') + d.getUTCDate() + '.' + (d.getUTCMonth() <= 8 ? '0' : '') + (d.getUTCMonth() + 1) + '.' + d.getUTCFullYear();
            });

            //Validators 
            ngModel.$validators.regEx = function(modelValue, viewValue) {
                var bool = regex.test(viewValue);
                return bool;
            };

            //Parsers
            ngModel.$parsers.push(function(val) {
                var dateArgs = val.match(/\d{2,4}/g);
                if (dateArgs) {
                    var year = dateArgs[2],
                        month = parseInt(dateArgs[1] - 1),
                        day = dateArgs[0],
                        hour = 2,
                        minutes = 0,
                        timestamp = new Date(year, month, day, hour, minutes).getTime() / 1000; //for php timestamp

                    return timestamp;
                }
            });
        }
    }
})();