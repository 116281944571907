// notificationBox.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('notificationBox', notificationBox);

    // Inject dependencies.
    notificationBox.$inject = [
        '$state',
        '$filter',
        '$timeout'
    ];

    function notificationBox(
        $state,
        $filter,
        $timeout
    ) {
        var directive = {
            restrict: 'E',
            controller: controller,
            templateUrl: '/templates/notificationBox.html',
            scope: {
                data: '=',
                subscriptionPeriodEndDate: '=',
                touchedBarrierUnderlyingName: '=',
                firstTouchedBarrierDate: '='
            }
        };

        function controller($scope) {
            $scope.boxes = $scope.data;

            /**
             * Click function for notification links
             * @param {Object} box - Notification data
             */
            $scope.click = function(box) {

                // Go to state with search parameters.
                if (box.filter) {
                    $timeout(function() {
                        $state.go('productList.findProducts', {
                            search: $.param(box.filter)
                        }, {
                            reload: true
                        });
                    });
                } else if (box.state) {
                    $timeout(function() {
                        $state.go(box.state);
                    });
                }
            };

            // Prepare dynamic translation data
            $scope.translationData = {
                subscriptionPeriodEndDate: $scope.subscriptionPeriodEndDate ? $filter('date')($scope.subscriptionPeriodEndDate * 1000, 'shortDate') : '',
                subscriptionPeriodEndDateTime: $scope.subscriptionPeriodEndDate ? $filter('date')($scope.subscriptionPeriodEndDate * 1000, 'shortTime'): '',
                touchedBarrierUnderlyingName: $scope.touchedBarrierUnderlyingName,
                firstTouchedBarrierDate: $filter('date')($scope.firstTouchedBarrierDate * 1000, 'shortDate')
            };
        }

        return directive;
    }
})();