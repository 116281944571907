// productFilterSlider.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('productFilterSlider', productFilterSlider);

    // Inject dependencies.
    productFilterSlider.$inject = ['$timeout'];

    function productFilterSlider($timeout) {
        var directive = {
            controller: controller,
            link: link,
            restrict: 'E',
            templateUrl: '/templates/productsFilterSlider.html',
            scope: {
                data: '=',
                filterData: '=',
                submit: '='
            }
        };

        function controller($scope) {

            // Update filter data object
            $scope.change = function() {
                synchronizeData();
                
                $scope.filterData = $scope.filterTemp;

                // Submit changes
                $timeout(function() {
                    $scope.submit();
                }, 5);
            };

            // Synchronize filter with data
            function synchronizeData() {
                $scope.filterTemp = {};

                // Check for new data
                if ($scope.data.slider.sliderEnd && $scope.data.slider.sliderEnd !== $scope.data.slider.max) {
                    $scope.filterTemp.end = $scope.data.slider.sliderEnd;
                }

                if ($scope.data.slider.sliderStart && $scope.data.slider.sliderStart !== $scope.data.slider.min) {
                    $scope.filterTemp.start = $scope.data.slider.sliderStart;
                }
            }


            // Reset current input value
            $scope.resetValue = function(key) {

                // Which key to reset
                if (key === 'start') {
                    $scope.data.slider.sliderStart = $scope.data.min;
                    $scope.filterTemp[key] = $scope.data.slider.min;
                } else {
                    $scope.data.slider.sliderEnd = $scope.data.max;
                    $scope.filterTemp[key] = $scope.data.slider.max;
                }

                // Update filter
                $scope.change();
            };

            synchronizeData();
        }

        function link(vm, element) {

            // Blur current input field to trigger change function
            element.on('keyup', function(event) {
                if (event.keyCode === 13) {
                    document.activeElement.blur();
                }
            });
        }

        return directive;
    }
})();