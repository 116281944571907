(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('TooltipService', TooltipService);

	TooltipService.$inject = ['$timeout', 'MediaQueryService'];

    function TooltipService($timeout, MediaQueryService) {

		/**
		 * Get Tooltip data related to a column (to be added to html tag as 'tooltip' attribute)
		 * @param column
		 * @param tooltips
		 * @param isLabel
		 * @return {Array}
		 */
		this.getTooltip = function(column, tooltips, isLabel) {
			var result = [];
			angular.forEach(tooltips, function(tooltip) {
				if (column === tooltip.property || (Array.isArray(tooltip.property) && tooltip.property.indexOf(column) >= 0)) {
					if (typeof isLabel !== 'undefined' && tooltip.label || isLabel === undefined) {
						result.push(tooltip);
					}
				}
			});

			return result;
		};


		/**
		 * Apply tooltip on current page
		 */
		this.applyTooltips = function() {

			$('body').find('[tooltip]').each(function() {
				var element = $(this);

				if (element.attr('tooltip')) {
					var keywords = JSON.parse(element.attr('tooltip'));

					// Highlight keywords.
					$timeout(function() {
						angular.forEach(keywords, function(keyword) {
							var markInstance = new Mark(element.get(0)); // jshint ignore:line
							markInstance.mark(keyword.text, {
								accuracy: 'exactly',
								caseSensitive: true,
								separateWordSearch: false,
								tooltipContent: keyword.tooltip
							});
						});

						$timeout(function() {
							if (element.find('mark.highlight-tooltip').length > 0) {
								element.find('mark.highlight-tooltip').click(function(e) {
									e.preventDefault();
									e.stopPropagation();

									var tooltip = $('body .tooltip'),
										tooltipData = $(this).attr('data-tooltip'),
										trigger = $(this),
										triggerPosition = trigger.offset();

									tooltip.removeClass('show');

									// Check for tooltip.
									if (tooltip.length > 0) {
										tooltip.html('<p>' + tooltipData + '</p>');
									} else {
										tooltip = $('<div class="tooltip"><p>' + tooltipData + '</p></div>');
										$('body').append(tooltip);
									}

									tooltip.addClass('show');

									if (MediaQueryService.getMedia() === 'mobile') {

										// Set position in mobile
										tooltip.css({
											top: triggerPosition.top - tooltip.height() - 30,
											left: 10,
											width: $(document).width() - 20,
											maxWidth: $(document).width() - 20
										});
									} else if (MediaQueryService.getMedia() === 'medium') {

										// Set position in tab
										tooltip.css({
											top: triggerPosition.top - tooltip.height() - 30,
											left: triggerPosition.left
										});
									} else {

										// Set position in desktop
										tooltip.css({
											top: triggerPosition.top - tooltip.height() - 30,
											left: triggerPosition.left - 50
										});
									}
								});
							}
						}, 500);

					}, 100);

				}
			});

		};

        return this;
    }
})();