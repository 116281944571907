// glossary.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('GlossaryService', GlossaryService);

    // Inject dependencies.
    GlossaryService.$inject = [
        'HttpWrapper',
        '$q'
    ];

    // Init GlossaryService.
    function GlossaryService(HttpWrapper, $q) {
        return {
            
            
            /**
             * Get glossary data.
             * @returns {promise}
             */
            get: function() {
                var deferred = $q.defer();

                HttpWrapper({
                    method: 'GET',
                    refreshProfiler: true,
                    url: '/glossary'
                }, function(response) {
                    deferred.resolve(response);
                }, function(rejection) {
                    deferred.reject(rejection);
                });

                return deferred.promise;
            },

            /**
             * Escaping double quotes char
             * @param str
             * @returns {*}
             */
            escapeStr: function(str) {
                try {
                    str = str.replace(/\"/g, '\\"');
                } catch (e) {
                    console.log(e);
                }

                return str;
            }
        };
    }
})();