(function() {
    'use strict';

    angular
        .module('app.controllers')
        .controller('ServicesNewsletterController', ServicesNewsletterController);

    // Inject dependencies.
    ServicesNewsletterController.$inject = [
        'ProfilerService',
        '$rootScope',
        '$filter',
        '$scope'
    ];

    function ServicesNewsletterController(ProfilerService, $rootScope, $filter, $scope) {
        var vm = this,
            container = '#newsletter';

        vm.formData = {
            language: 'DE',
            activeField: null
        };

        $scope.allowedCharsNameRegex = /^[A-Za-zàéèöäüÜÄÖß \-^']+$/;

        vm.onFieldClick = function(fieldName) {
            vm.formData.activeField = fieldName;
        };

        vm.isFieldActive = function(fieldName) {
            return vm.formData.activeField === fieldName;
        };

        vm.onFieldBlur = function() {
            vm.formData.activeField = null;
        };

        // Set default selected language
        if (window.AppConfig && window.AppConfig.locale) {
            vm.formData.language = String(window.AppConfig.locale).substring(0, 2).toUpperCase();
        }

        // Set browser title
        $rootScope.browserTitle = $filter('translate')('newsletter_title');

        ProfilerService.resetProfiler();
        
        // Add loader
        vm.showLoader = function() {
            $(container).addClass('icon-loading');
        };

        vm.hideLoader = function() {
            $(container).removeClass('icon-loading');
        };
    }
})();
