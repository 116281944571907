// error.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('error', error);

    // Inject dependencies.
    error.$inject = [
        '$sce',
        '$rootScope',
        'ApplicationService'
    ];

    function error() {
        var directive = {
            controller: controller,
            restrict: 'E',
            templateUrl: '/templates/error.html'
        };

        return directive;

        function controller(
            $scope,
            $sce,
            $rootScope,
            ApplicationService
        ) {
            $scope.errorHappen = false;
            $scope.error = {};

            $rootScope.$on('RequestError', function(event, errorData) {
                ApplicationService.getSiteConfig().then(
                    function(data) {
                        if (data.environment) {
                            $scope.showError = data.environment !== 'production';
                        } else {
                            $scope.showError = true;
                        }
                    },
                    function() {
                        $scope.showError = true;
                    }
                );
                $scope.errorData = errorData;
                $scope.errorHappen = true;
            });

            $scope.close = function() {
                $scope.errorHappen = false;
            };

            $scope.trustAsHtml = function(html) {
                return $sce.trustAsHtml(html);
            };
        }
    }
})();
