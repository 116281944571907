// constants.js
(function() {
    'use strict';

    angular
        .module('app.constants')
        .constant('API', '/page-api')
        .constant('APIREPORT', '/page-api/product/report/')
        .constant('APISEARCH', '/autocomplete.php')
        .constant('APIHEADERS', {
            Accept: 'application/problem+json',
            'X-AUTH-TOKEN': null,
            'X-Requested-With': 'XMLHttpRequest'
        })
        .constant('BASEURL', window.BUILD_ID)
        .constant('IS_BETA_VERSION', false)

        .constant('PAGING', {
            rowsPerPage: 20
        })

        .constant('COOKIES_CONFIG', {
            COOKIE_DISCLAIMER_ACCEPTED: 'COOKIE_DISCLAIMER_KSP_ACCEPTED',
            COOKIE_TRACKING_ACCEPTED: 'COOKIE_TRACKING_KSP_ACCEPTED',
            COOKIE_MARKETING_ACCEPTED: 'COOKIE_MARKETING_KSP_ACCEPTED',
            defaultCookieExpirationDays: 365
        })

        .constant('PRELOAD_TEMPLATES', {
            mobile: ['instrumentTableMobile'],
            medium: ['instrumentTableMobile'],
            regular: [
                'search',
                'instrumentTable', 
                'productsFilter',
                'productsFilterDropdown',
                'productsFilterGroup',
                'productsFilterMaturity',
                'productsFilterUnderlying',
                'productsFilterSlider',
                'productsFilterCheckbox',
                'productList',
                'productList.findProducts',
                'productList.productsInSubscription',
                'productList.recentlyIssuedProducts',
                'productList.opportunitiesSecondaryMarket',
                'productList.yieldMatrix',
                'productList.legend'
            ]
        })

        .constant('SpecialFormatMinMaxFields', {
            minDecimals: 2,
            maxDecimals: 4,
            fields: [
                'couponPercentPa',
                'barrierLevelRelativePercent',
                'relativeStrike2ReferencePrice',
                'issuePrice',
                'couponPaymentInterest',
                'triggerLevel',
                'repaymentValue'
            ]
        })

        .constant('PUSHSETTINGS', {
            api: 'ws://solvians-push1.ivestor.de/websocket',
            application: 'de',
            user: 'ivestorPublicDisplay'
        })

        .constant('KeyInformationDocumentSettings', {
            rootURL: '/docRepository/rest/document?',
            locales: {"DE": "de_DE",
                      "EN": "en_GB",
                      "FR": "fr_FR",
                      "IT": "it_IT"}
        })

        // Product groupId for which to hide the foot note text with key translation key: 'footnote_text'
        .constant('HideFootNoteTextForGroups', [
            'TrackerCertificate'
        ]);

})();