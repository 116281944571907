// columns2.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('columns', columns2);

    // Inject dependencies.
    columns2.$inject = [];

    function columns2() {
        var directive = {
            controller: controller,
            restrict: 'E',
            scope: {
                name: '@?',
                url: '@?',
                data: '='
            },
            templateUrl: '/templates/columns2.html'
        };
        
        function controller($scope, $sce) {
            $scope.result = [];

            if ($scope.data && $scope.data.componentGroups) {
                angular.forEach($scope.data.componentGroups, function(obj) {

                    // Allowing iFrames to be rendered with ng-bind-html
                    if (obj && obj[0] && obj[0].data && obj[0].data.text) {
                        $scope.result.push($sce.trustAsHtml(obj[0].data.text));
                    }
                });
            }

        }
        
        return directive;
    }
})();
