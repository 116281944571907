// profiler.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('toggleButton', toggleButton);

    // Inject dependencies.
    toggleButton.$inject = [];

    function toggleButton() {
        var directive = {
            restrict: 'E',
            template: '<button\n' +
                '        ng-disabled="disabled"' +
                '        ng-click="clickEvent()"' +
                '        ng-class="{\'active\': model, \'disabled\': disabled}"' +
                '></button>',
            scope: {
                model: '=',
                disabled: '<',
                action: '='
            },
            link: function(scope) {
                scope.clickEvent = function() {
                    if (!scope.disabled) {
                        scope.model = !scope.model;

                        if (scope.action && typeof scope.action === 'function') {
                            scope.action();
                        }
                    }
                };
            }
        };

        return directive;
    }
})();