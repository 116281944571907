// htmlText.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('htmlText', htmlText);

    // Inject dependencies.
    htmlText.$inject = ['$timeout', '$window'];

    function htmlText($timeout, $window) {
        var directive = {
            controller: controller,
            link: function() {
                $timeout(function() {
                    if ($window.iFrameResize && $('iframe').length > 0) {
                       $window.iFrameResize();
                    }
                });
            },
            restrict: 'E',
            scope: {
                name: '@?',
                url: '@?',
                data: '='
            },
            templateUrl: '/templates/htmlText.html'
        };

        function controller($scope, $sce) {

            // Allowing iFrames to be rendered with ng-bind-html
            $scope.trustedHtml = $sce.trustAsHtml($scope.data.text);
        }

        return directive;
    }
})();
