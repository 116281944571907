// menu.service.js
/**
 * the menuService is used to retrieve the navigation information from server side
 */
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('DisclaimerService', DisclaimerService);

    DisclaimerService.$inject = [
        '$state',
        '$timeout',
        '$location'
    ];

    function DisclaimerService(
        $state,
        $timeout,
        $location
    ) {
        return {

            currentState: null,

            setDisclaimer: function(accepted) {
                localStorage.setItem('disclaimer_accepted', accepted);
                localStorage.setItem('disclaimer', true);
                localStorage.setItem('disclaimer_expiration', Math.round(new Date().getTime() / 1000));
            },

            /**
             * Check is the current useragent is a Bot or a Browser
             * @returns {boolean}
             */
            isUserAgentBot: function(userAgent) {
                try {
                    var botPattern = "(googlebot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)",
                        re = new RegExp(botPattern, 'i');

                    if (userAgent && re.test(userAgent)) {
                        return true;
                    }
                } catch (e) {}

                // In case of failure, fallback to browser behavior
                return false;
            },

            /**
             * This function is Responsible of checking if the disclaimer information
             * is set and the expiration Date is still not met
             *
             * @returns {boolean}
             */
            checkShowDisclaimer: function(disclaimerCheckOnly) {
                var instance = this,
                    disclaimer = localStorage.getItem('disclaimer') ?
                    localStorage.getItem('disclaimer') : null;

                if (!disclaimerCheckOnly && $location.search().state) {
                    var currentState = $location.search().state,
                        stateParams = JSON.parse($location.search().stateParams);
                    
                    // Remove parameters.
                    $location.search('state', null);
                    $location.search('stateParams', null);
                    
                    // Set current state for disclaimer.
                    instance.setCurrentState(currentState, stateParams);
                    
                    $timeout(function() {
                        $state.go(currentState, stateParams);
                    }, 100);
                }

                if (disclaimer) {
                    var expiration = localStorage.getItem('disclaimer_expiration') ?
                        localStorage.getItem('disclaimer_expiration') : null;
                    if (expiration) {
                        var ts = Math.round(new Date().getTime() / 1000),
                            expiration_logic = localStorage.getItem('disclaimer_accepted'),
                            tsExpiration;

                        // the expiration time could be 168h ( 7 days ) or ( 8h ) according to if user accept
                        if (expiration_logic === 'true') {
                            tsExpiration = ts - (168 * 3600);
                        } else {
                            tsExpiration = ts - (8 * 3600);
                        }

                        if (expiration < tsExpiration) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }

                return false;
            },

            setCurrentState: function(state, params) {
                this.currentState = state.name;
                this.currentParams = params;
            },

            getCurrentState: function() {
                return {
                    name: this.currentState,
                    params: this.currentParams
                };
            }
        };
    }
})();
