// history.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('HistoryService', HistoryService);

    // Inject dependencies.
    HistoryService.$inject = [
        '$state',
        '$timeout',
        '$rootScope'
    ];

    // Init HistoryService
    function HistoryService(
        $state,
        $timeout,
        $rootScope
    ) {
        var historyService = {

            /**
             * Is logging enabled or not
             */
            debugMode: false,

            /**
             * Unique key to be used in storage
             */
            storageKey: 'navigationHistory',

            /**
             * Flag to check if the service is currently restoring previous state or is idle
             */
            isRestoring: false,

            /**
             * Delay before start restoring
             */
            delayStartRestoring: 100,

            /**
             * Delay before clicking on Show all buttons
             */
            delayForGroupsRendering: 300,

            /**
             * Flag to check if the service is currently allowed to restore previous state or is idle
             */
            isCurrentlyAllowedToRestore: false,

            /**
             * CSS Class to be added to the container while restoring
             */
            historyLoaderClass: 'icon-loading icon-loading-history',

            /**
             * Storing the history of performed actions
             */
            history: {
                windowScrollPosition: null,
                activeTab: null,
                renderedGroups: {},
                expandedGroups: {},
                pagedGroups: {},
                data: {},
                groupData: {}
            },

            /**
             * ENTRY POINT -- Start restoring previous status of there are any stored data
             * @param data - reference to groups object from the scope
             * @param activeTab - reference to activeTab object from the scope
             * @param changeTabFunc - reference to changeTab function from the scope
             */
            restore: function(data, activeTab, scope) {
                var instance = this;

                // Setting the DOM container if there is any
                try {
                    instance.container = scope.container;
                } catch (e) {
                    instance.log(e);
                }

                try {
                    instance.log(instance.isCurrentlyAllowedToRestore, instance.load(), $state.current, $state.current.name.indexOf('productList'));
                    if (instance.isCurrentlyAllowedToRestore && instance.load() && $state.current && $state.current.name.indexOf('productList') >= 0) {

                        // Show loader
                        instance.showLoader();

                        $timeout(function() {
                            instance.log("restore started");
                            instance.isRestoring = true;

                            // Check if it is Tab based page or not
                            if (activeTab && scope && instance.history.activeTab) {
                                instance.restoreActiveTab(scope);
                            }

                            // Check if it is Tab based page or not then render groups
                            if (activeTab) {
                                instance.restoreRenderedGroups(data[activeTab.name]);
                            } else {
                                instance.restoreRenderedGroups(data);
                            }

                            // Small time to allow rendering of the groups before clicking on Show all button
                            $timeout(function() {
                                instance.restoreExpandedGroups();
                            }, instance.delayForGroupsRendering);
                        }, instance.delayStartRestoring);
                    }
                } catch (e) {
                    instance.log(e);
                }

            },

            /**
             * Save history of the group that accessed product detail page
             * @param groupId
             */
            saveHistoryForGroup: function(groupId, activeTab) {
                var instance = this;

                // Store the active tab if it's a multi tab page
                instance.storeActiveTab(activeTab);

                // Store group of the selected product
                instance.storeCurrentWindowScrollPosition(groupId);

                // Clear other groups expand status
                Object.keys(instance.history.expandedGroups).forEach(function(itm) {
                    if (itm !== groupId) {
                        delete instance.history.expandedGroups[itm];
                    }
                });

                // Clear other groups paging status
                Object.keys(instance.history.pagedGroups).forEach(function(itm) {
                    if (itm !== groupId) {
                        delete instance.history.pagedGroups[itm];
                    }
                });

                // Saving the history object to persistence layer (could be localStorage)
                instance.persist();
            },

            /**
             * Save current data to storage
             * @param data
             */
            storeData: function(data) {
                var instance = this;
                instance.log("Calling restore data...");
                if (instance.isRestoring) {
                    return;
                }

                instance.log("Storing data...");
                instance.history.data = data;
            },

            /**
             * Save current group data to storage
             * @param data
             */
            storeGroupData: function(group) {
                var instance = this;
                if (instance.isRestoring) {
                    return;
                }

                instance.log("Storing Group data...");
                instance.history.groupData[group.id] = group;
            },

            /**
             * Add current active tab to history
             * @param currentPosition
             */
            storeActiveTab: function(activeTab) {
                var instance = this;
                if (instance.isRestoring) {
                    return;
                }

                instance.history.activeTab = activeTab;
            },

            /**
             * Add current scroll position to history
             * @param currentPosition
             */
            storeCurrentWindowScrollPosition: function(currentPosition) {
                var instance = this;
                if (instance.isRestoring) {
                    return;
                }

                instance.history.windowScrollPosition = currentPosition;
            },

            /**
             * Register new action to the history of actions
             * @param groupId
             */
            storeExpandedGroup: function(groupId) {
                var instance = this;
                if (instance.isRestoring) {
                    return;
                }

                instance.history.expandedGroups[groupId] = true;
            },

            /**
             * Register new action to the history of actions
             * @param groupId
             * @param currentPage
             */
            storePagedGroup: function(groupId, currentPage) {
                var instance = this;
                if (instance.isRestoring) {
                    return;
                }

                instance.history.pagedGroups[groupId] = currentPage;
            },

            /**
             * Register new action to the history of actions
             * @param groupId
             * @param currentPage
             */
            storeRenderedGroup: function(groupId) {
                var instance = this;

                instance.history.renderedGroups[groupId] = true;
            },

            /**
             * Restore already activate tab {name, index}
             * @param activeTab
             */
            restoreActiveTab: function(scope) {
                var instance = this;

                try {
                    if (!instance.isRestoring) {
                        return;
                    }

                    if (scope && scope.container && instance.history.activeTab) {

                        var elem = $(scope.container.find('tabs nav ul li').get(instance.history.activeTab.index));

                        instance.log("Restoring active tab", instance.history.activeTab, elem);

                        if (elem.length && !elem.hasClass('active')) {
                            elem.click();
                        }

                    }

                } catch (e) {
                    instance.log(e);
                }
            },

            /**
             * Execute all actions already stored in history
             * To be called when going back from product details to the product list
             * @param data
             */
            restoreRenderedGroups: function(data) {
                var instance = this;

                try {
                    if (!instance.isRestoring || !data.groups) {
                        return;
                    }

                    for (var i = 0; i < data.groups.length; i++) {
                        try {
                            if (instance.history.renderedGroups[data.groups[i].id]) {
                                data.groups[i].allowRender = true;
                            }
                        } catch (e) {
                            instance.log(e);
                        }
                    }

                } catch (e) {
                    instance.log(e);
                }

            },

            /**
             * Reopen group list (Show all button)
             */
            restoreExpandedGroups: function() {
                var instance = this,
                    flag = false;

                try {
                    if (!instance.isRestoring) {
                        return;
                    }

                    instance.log("Restoring expanded Groups");
                    instance.restoreScrollPositionBeforeFinish();
                    if (instance.history && instance.history.expandedGroups && !angular.equals(instance.history.expandedGroups, {})) {
                        angular.forEach(instance.history.expandedGroups, function(obj, key) {
                            if (instance.history.expandedGroups[key]) {
                                if ($('section[data-id=' + key + '] a.show-all').length) {
                                    $('section[data-id=' + key + '] a.show-all').click();
                                    flag = true;
                                } else {

                                    // Should restore pages
                                    instance.restoreGroupPages(key);
                                }
                            }
                        });

                        if (flag === false) {
                            instance.restoreScrollPosition();
                        }

                    } else {
                        instance.restoreScrollPosition();
                    }

                } catch (e) {
                    instance.log(e);
                }
            },

            /**
             * Reopened active page of the group
             * @param groupId
             */
            restoreGroupPages: function(groupId) {
                var instance = this;

                try {
                    if (!instance.isRestoring) {
                        return;
                    }

                    if (instance.history.pagedGroups[groupId] && $('section[data-id=' + groupId + '] instrument-table-paging ul li').length) {
                        instance.log("Restoring pages for group ", groupId);
                        $($('section[data-id=' + groupId + '] instrument-table-paging ul li').get(instance.history.pagedGroups[groupId])).find('button').click();
                        instance.finishRestoring();
                    } else {
                        instance.restoreScrollPosition();
                    }

                } catch (e) {
                    instance.log(e);
                    instance.finishRestoring();
                }
            },

            /**
             * Relocate screen scroll to the group of the previously selected product
             */
            restoreScrollPositionBeforeFinish: function() {
                var instance = this;

                try {
                    instance.restoreScrollPosition(true);
                    instance.hideLoader();
                } catch (e) {
                    instance.log(e);
                }

            },

            restoreScrollPosition: function(notFinished) {
                var instance = this;

                try {
                    if (instance.history && instance.history.windowScrollPosition) {

                        instance.log("restore scroll position to ", instance.history.windowScrollPosition);

                        try {
                            $('html, body').animate({
                                scrollTop: $('section[data-id=' + instance.history.windowScrollPosition + ']:visible').offset().top - 80
                            }, 500);
                        } catch (e) {
                            instance.log(e);
                        }
                    }

                } catch (e) {
                    instance.log(e);
                }

                if (!notFinished) {
                    instance.finishRestoring();
                }
            },

            /**
             * Restore stored data (usually a webservice response)
             */
            restoreData: function() {
                var instance = this;
                try {
                    if (instance.history && instance.history.data && !angular.equals({}, instance.history.data)) {
                        instance.log("Restoring data...");
                        return instance.history.data;
                    }

                } catch (e) {
                    instance.log(e);
                }

                return null;
            },

            /**
             * Restore stored group data (usually a webservice response of pagination)
             */
            restoreGroupData: function(group) {
                var instance = this;
                try {
                    if (instance.history && instance.history.groupData && !angular.equals({}, instance.history.groupData && instance.history.groupData[group.id])) {
                        instance.log("Restoring group data...");
                        var newInstance = angular.copy({}, instance.history.groupData[group.id]);
                        instance.history.groupData = {};
                        instance.persist();
                        return newInstance;
                    }

                } catch (e) {
                    instance.log(e);
                }

                return null;
            },

            /**
             * Has stored group data (usually a webservice response of pagination)
             */
            hasGroupData: function(group) {
                var instance = this;
                try {
                    if (instance.history && instance.history.groupData && !angular.equals({}, instance.history.groupData)) {
                        if (instance.history.groupData[group.id]) {
                            return true;
                        }
                    }

                } catch (e) {
                    instance.log(e);
                }

                return false;
            },

            /**
             * Indicates that restoring process just finished, then new user actions on the screen can be saved
             */
            finishRestoring: function() {
                var instance = this;
                instance.log("restore finished");
                instance.clear();
                instance.isRestoring = false;
                instance.hideLoader();
            },

            /**
             * Persist data to storage
             */
            persist: function() {
                var instance = this;
                try {
                    localStorage.setItem(instance.storageKey, JSON.stringify(instance.history));
                } catch (e) {
                    instance.log(e);
                }
            },

            /**
             * Load data persisted into the memory
             * @returns {{windowScrollPosition: null, expandedGroups: {}, pagedGroups: {}}|history|{windowScrollPosition, expandedGroups, pagedGroups}}
             */
            load: function() {
                var instance = this;
                try {
                    var storedData = localStorage.getItem(instance.storageKey);
                    if (storedData) {
                        instance.history = JSON.parse(storedData);
                        return true;
                    }

                } catch (e) {
                    instance.log(e);
                }

                return false;
            },

            /**
             * Clear Storage and rest instance
             */
            clear: function() {
                var instance = this;
                try {
                    instance.log("Cleaning stored history status");
                    localStorage.removeItem(instance.storageKey);
                    instance.history = {
                        windowScrollPosition: null,
                        activeTab: null,
                        renderedGroups: {},
                        expandedGroups: {},
                        pagedGroups: {},
                        data: {},
                        groupData: {}
                    };
                } catch (e) {
                    instance.log(e);
                }
            },

            /**
             * Handle logging within this service
             * Enable logs if this.debugMode is true
             */
            log: function() {
                var instance = this;
                if (instance.debugMode) {
                    console.log(arguments);
                }
            },

            /**
             * Registering on state change event to detect
             * navigation between product list and product details
             *
             * IT WILL BE EXECUTED ONCE
             */
            init: function() {
                var instance = this;

                instance.log("Initializing the History Service...");
                instance.clear();

                $rootScope.$on('$stateChangeSuccess', function(ev, to, toParams, from) {
                    if ($state.current && $state.current.name.indexOf('productList') >= 0) {
                        if (from && from.name === "productDetail") {
                            instance.log("Currently in product list and coming from product details");
                            instance.isCurrentlyAllowedToRestore = true;
                        } else {
                            instance.isCurrentlyAllowedToRestore = false;
                        }
                    }

                    return;
                });
            },

            /**
             * Show loader while restoring history
             */
            showLoader: function() {
                var instance = this;
                try {

                    $("#ui-view").addClass(instance.historyLoaderClass);

                } catch (e) {
                    instance.log(e);
                }
            },

            /**
             * Hide loader
             */
            hideLoader: function() {
                var instance = this;
                try {

                    $("#ui-view").removeClass(instance.historyLoaderClass);

                } catch (e) {
                    instance.log(e);
                }
            }

        };

        historyService.init();

        window.historyService = historyService;

        return historyService;
    }
})();