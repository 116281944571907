// productsFilterMobile.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('productsFilterMobile', productsFilterMobile);

    // Inject dependencies.
    productsFilterMobile.$inject = [
        'ProductFilterService',
        'ProfilerService',
        'PAGING'
    ];

    function productsFilterMobile(
        ProductFilterService,
        ProfilerService,
        PAGING
    ) {
        var directive = {
            restrict: 'E',
            controller: controller,
            templateUrl: '/templates/productsFilterMobile.html',
            scope: {
                data: '=',
                changeFunc: '=',
                url: '=',
                type: '=',
                tabs: '='
            }
        };

        function controller($scope, $filter) {
            var container = 'products-filter-mobile';

            // Inititally get filterdata if they are already set by url params
            $scope.filter = ProductFilterService.formatURLParamsToFilterObject(ProductFilterService.queryData.filter);

            // Allowing render of sub content of dropdown, keys of the array are container ids
            $scope.isRenderAllowed = [];

            /**
             * Remove active filter value
             * @param {string} key - Type of active filter tag.
             * @param {string} value - Value of active filter tag.
             */
            $scope.removeActiveFilterValue = function(key, value) {
                ProductFilterService.removeActiveFilterValue($scope.filter, key, value).then(function(data) {
                    $scope.filter = data;
                    $scope.submit();
                });
            };

            // Reset filter
            $scope.resetFilter = function() {
                ProductFilterService.reset($scope.type).then(function() {
                    $scope.filter = {};
                    $scope.submit();
                });
            };

            // Show/Hide filters
            $scope.showFilter = function() {
                $(container).toggleClass('active');

                // Lock document scrolling while filters are open
                if ($(container).hasClass('active')) {
                    window.scrollTo(0, 0);
                    $('body').css('position', 'fixed');
                } else {
                    $('body').css('position', 'inherit');
                }

            };

            /**
             * Check if there are active filters to show tags
             */
            $scope.isFilterActive = function(filters) {
                var flag = false;
                try {
                    if (filters) {
                        angular.forEach(filters, function(val) {
                            if (val.active) {
                                flag = true;
                            }
                        });
                    }
                } catch (e) {
                    console.log(e);
                }

                return flag;
            };

            $scope.filterButtonTitle = function() {
                var translate = $filter('translate'),
                    count = 0;

                try {
                    if ($scope.isFilterActive($scope.data.filters)) {

                        try {

                            count = $(container + ' .tag').length;

                        } catch (e) {
                            console.log(e);
                            count = 0;
                        }

                        return translate('filter_count_mobile') + ' (' + count + ')';
                    }
                } catch (e) {
                    console.log(e);
                }

                return translate('filter_products');
            };

            // Submit filter form
            $scope.submit = function() {
                $(container).addClass('icon-loading');

                ProductFilterService.submit(
                    $scope.url,
                    ProductFilterService.getQueryURL(
                        $scope.filter, true
                    )
                ).then(function(data) {
                    $scope.data = data;

                    // Update tabs
                    updateTabs($scope.type);

                }, function() {
                    $scope.groups = [];
                }).finally(function() {
                    ProfilerService.setTimeLoad();
                    $(container).removeClass('icon-loading');
                });
            };

            // Toggle this filter window
            $scope.toogleDropDowns = function(container, cssClass) {
                var jContainer = $(container),
                    state;

                if (cssClass) {
                    jContainer = $(container + '.' + cssClass);
                }

                //Allow content rendering
                $scope.isRenderAllowed[container] = true;
                state = jContainer.hasClass('open');

                if (state) {
                    $('products-filter .open').removeClass('open');
                } else {
                    $('products-filter .open').removeClass('open');
                    jContainer.addClass('open');
                }
            };

            // Init event listener
            $scope.toggleThirdLevelFilter = function() {
                $(container).find('.third-level-filter .third-level-filter-expanded').toggle();
                $(container).find('.third-level-filter i').toggleClass('active');
            };

            // Resetbody scroll
            $scope.$on('$destroy', function() {
                $('body').css('position', 'inherit');
            });

            /**
             * Update tabs data
             * @param {String} type - Yield-Matrix/ProductList
             */
            function updateTabs(type) {
                if (type === 'yieldMatrix') {
                    $scope.tabs.BarrierReverseConvertible = $scope.data.groups.searchObjectsByKey('name', 'BarrierReverseConvertible')[0];
                    $scope.tabs.MultiBarrierReverseConvertible = $scope.data.groups.searchObjectsByKey('name', 'MultiBarrierReverseConvertible')[0];
                    $scope.tabs.Autocallable_SoftCallable_MultiBarrierReverseConvertible = $scope.data.groups.searchObjectsByKey('name', 'Autocallable_SoftCallable_MultiBarrierReverseConvertible')[0];

                    // Update Paging
                    angular.forEach($scope.tabs, function(group) {
                        try {
                            var paging;

                            if (group && !group.paging) {
                                paging = {
                                    page: 1,
                                    rowsPerPage: PAGING.rowsPerPage
                                };

                                // Set instrumnent count
                                paging.count = group.count;

                                // Calculate pages
                                paging.pages = Math.ceil(paging.count / PAGING.rowsPerPage);

                                // Set intital paging object
                                group.paging = paging;
                            }

                            if (group) {
                                group.pagingEnabled = true;
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    });

                } else {
                    $scope.tabs.inSubscription = $scope.data.in_subscription;
                    $scope.tabs.secondaryMarket = $scope.data.secondary_market;
                }
            }
        }

        return directive;
    }
})();