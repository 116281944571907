// productFilterCheckbox.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('productFilterCheckbox', productFilterCheckbox);

    // Inject dependencies.
    productFilterCheckbox.$inject = [];

    function productFilterCheckbox() {
        var directive = {
            controller: controller,
            restrict: 'E',
            templateUrl: '/templates/productsFilterCheckbox.html',
            scope: {
                data: '=',
                filterData: '=',
                submit: '='
            }
        };

        function controller($scope) {
            $scope.filterData = $scope.data.selected || 0;

            // Format selected state
            function formatSelected() {
                if ($scope.data.selected) {
                    $scope.filterData = 1;
                } else {
                    $scope.filterData = 0;
                }
            }

            // Update filter
            $scope.change = function() {
                formatSelected();

                // Submit changes
                setTimeout(function() {
                    $scope.submit();
                });
            };

            // Initially format data
            formatSelected();
        }

        return directive;
    }
})();