// states.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('StatesService', StatesService);

    // Inject dependencies.
    StatesService.$inject = [
        '$location',
        '$state'
    ];

    // Init StatesService.
    function StatesService(
        $location,
        $state
    ) {
        return {
            counter: 0,
            
            
            /**
             * Add new state to the state provider.
             * @param {string} name - Name of the state.
             * @param {object} options - State options.
             */
            addState: function(name, options) {
                if (!$state.get(name)) {
                    window.$stateProviderRef.state(name, options);
                }
            },
            
            
            /**
             * Get unique state name.
             * Customer do not want to add a state name to every cms page.
             * If you set a state name to a child page, the parent page must have a matched state name too.
             * @param {string} stateName - Name of the state.
             * @param {string} parentStateName - Name of the parent state.
             * @returns {String} - Unique state name.
             */
            getUniqueStateName: function(stateName, parentStateName) {
                var instance = this;
                
                if (stateName) {
                    return stateName;
                } else {
                    var text = 'state' + instance.counter;
                    
                    if (parentStateName) {
                        text = parentStateName + '.' + text;
                    }
                    
                    return text;
                }
            },

            /**
             * Init Short Urls coming from CMS within AppConfig.shortUrls
             * @param data
             */
            initShortUrls: function(data) {
                try {
                    if (data) {
                        var counter = 0;
                        angular.forEach(data, function(target, source) {
                            if (source && target) {
                                window.$stateProviderRef.state('shortUrl' + counter, {

                                    controller: function($location) {

                                        if (target.indexOf('http') === 0) {
                                            window.location.href = target;
                                        } else {
                                            if (target.indexOf('/') !== 0) {
                                                target = '/' + target;
                                            }

                                            $location.url(target);
                                        }

                                    },
                                    url: source.indexOf('/') !== 0 ? '/' + source : source
                                });
                            }

                            counter++;
                        });
                    }

                } catch (e) {
                    console.log(e);
                }
            },

            /**
             * Init states.
             * @param {object} data - Data contains cms tree structure.
             * @returns {promise}
             */
            init: function(data) {
                var instance = this,
                    templateUrl = window.BUILD_ID + '/templates/';

                angular.forEach(data, function(tree) {
                    angular.forEach(tree, function(page) {
                        instance.counter++;
                        
                        // Get unique state name.
                        page.stateName = instance.getUniqueStateName(page.stateName);
                        
                        var controllerAs = instance.getControllerAs(page.stateName),
                            stateOptions = {};


                        /**
                         * Check if page has a submenu.
                         * aboutUs, services, productList.
                         */
                        if (page.submenu) {
                            var parentStateName = page.stateName,
                                parentUrl = page.url;


                            /**
                             * Set state options for productList as parent page.
                             * Special handling then default cms parent page.
                             */
                            if (page.stateName === 'productList') {
                                stateOptions = {
                                    abstract: true,
                                    controller: controllerAs,
                                    templateUrl: templateUrl + page.stateName + '.html',
                                    url: page.url
                                };
                            } else { // Default parent cms page.
                                stateOptions = {
                                    abstract: true,
                                    template: '<ui-view />',
                                    url: page.url
                                };
                            }

                            // Add state.
                            instance.addState(page.stateName, stateOptions);

                            // Reset state options.
                            stateOptions = {};

                            angular.forEach(page.submenu, function(childPage) {
                                instance.counter++;
                        
                                // Get unique state name.
                                childPage.stateName = instance.getUniqueStateName(childPage.stateName, parentStateName);
                                
                                var splittedStateName = childPage.stateName.split('.'),
                                    child = splittedStateName[1],
                                    parent = splittedStateName[0],
                                    controllerAs = instance.getControllerAs(child, parent),


                                /**
                                 * Remove parent url from child url.
                                 * Nested abstract child routes inherits url from parent.
                                 * /services/glossary -> /glossary
                                 */
                                childUrl = childPage.url.replace(parentUrl, '');

                                // Set state options for different types of pages.
                                if (parent === 'productList') {
                                    var view = parent + instance.uppercaseFirstLetter(child);

                                    // Add search any parameter to url.
                                    switch (childPage.stateName) {
                                        case 'productList.findProducts':
                                        case 'productList.yieldMatrix':
                                            childUrl += '?{search:any}';
                                            
                                            break;
                                    }



                                    stateOptions = {
                                        url: childUrl,
                                        views: {},
                                        reloadOnSearch: false
                                    };

                                    stateOptions.views[view] = {
                                        controller: controllerAs,
                                        templateUrl: templateUrl + childPage.stateName + '.html'
                                    };

                                } else if (child === 'glossary' || child === 'newsletter') {
                                    stateOptions = {
                                        controller: controllerAs,
                                        templateUrl: templateUrl + childPage.stateName + '.html',
                                        url: childUrl
                                    };
                                } else if (childPage.stateName === 'lightbox.contact') {
                                    stateOptions = {
                                        url: childPage.url,
                                        views: {
                                            lightbox: {
                                                templateUrl: templateUrl + childPage.stateName + '.html'
                                            }
                                        }
                                    };
                                } else {

                                    // Set state options for default cms page and update url.
                                    stateOptions = {
                                        controller: 'PageController as page',
                                        templateUrl: templateUrl + 'page.html',
                                        url: childUrl
                                    };
                                }

                                // Add state.
                                instance.addState(childPage.stateName, stateOptions);
                            });
                        } else {

                            // Set state options for different types of pages.
                            switch (page.stateName) {
                                case 'disclaimer':
                                case 'productDetail':
                                    stateOptions = {
                                        controller: controllerAs,
                                        templateUrl: templateUrl + page.stateName + '.html',
                                        url: page.url
                                    };
                                    
                                    break;
                                default:
                                    
                                    // Set state options for default cms page and update url.
                                    stateOptions = {
                                        controller: 'PageController as page',
                                        templateUrl: templateUrl + 'page.html',
                                        url: page.url
                                    };
                                    
                                    break;
                            }

                            // Add state.
                            instance.addState(page.stateName, stateOptions);
                        }
                    });
                });
            },
            
            
            /**
             * Get controller as syntax.
             * @param {string} child - Name of the child page.
             * @param {parent} parent - Name of the parent page.
             * @returns {string}
             */
            getControllerAs: function(child, parent) {
                var instance = this,
                    controllerName = instance.uppercaseFirstLetter(child) + 'Controller',
                    controllerAsName = child;

                if (parent) {
                    child = instance.uppercaseFirstLetter(child);

                    controllerName = instance.uppercaseFirstLetter(parent) + child + 'Controller';
                    controllerAsName = parent + child;
                }

                return controllerName + ' as ' + controllerAsName;
            },
            
            
            /**
             * Uppercase first letter helper function.
             * @param {string} text - Text to modify.
             * @returns {string}
             */
            uppercaseFirstLetter: function(text) {
                if (text) {
                    return text.charAt(0).toUpperCase() + text.slice(1);
                } else {
                    return text;
                }
            },

            /**
             * Allow case insensitive routes only for product details routes
             * @param {type} $injector
             * @param {type} $location
             * @returns {undefined}
             */
            getProductDetailsRule: function($injector, $location) {
                var path = $location.path(), normalized = path.toLowerCase();
                if (path !== normalized &&
                        (normalized.indexOf("/valor/") > -1 ||
                        normalized.indexOf("/isin/") > -1 ||
                        normalized.indexOf("/wkn/") > -1)
                    ) {
                    $location.replace().path(normalized);
                }
            }
        };
    }
})();
