// contact.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('contact', contact);

    // Inject dependencies.
    contact.$inject = [
        'ProfilerService',
        'ContactService',
        'MediaQueryService',
        'BASEURL',
        '$timeout',
        '$translate'
    ];

    function contact(
        ProfilerService,
        ContactService,
        MediaQueryService,
        BASEURL,
        $timeout,
		$translate
    ) {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                changeFunc: '='
            },
            templateUrl: '/templates/contact.html'
        };

        return directive;

        function link(vm, element) {
            vm.BASEURL = BASEURL;
            vm.formData = {};
            vm.phoneRegEx = /^[0-9|\+|\-\/\(\)\ ]+$/;
			vm.currentLang = $translate.proposedLanguage() || $translate.use();
            
            // Add loader.
            element.addClass('icon-loading');

            // Get contact data.
            ContactService.get().then(function(data) {
                vm.data = data.data;
            }).finally(function() {
                ProfilerService.setTimeLoad();

                // Remove loader with timeout to fix flicker.
                $timeout(function() {
                    element.removeClass('icon-loading');
                }, 300);
            });
            
            
            /**
             * Get media.
             * @returns {string} Media query as string.
             */
            vm.getMedia = function() {
                return MediaQueryService.getMedia();
            };

            
            /**
             * Submit form.
             * @param {object} $event - Contact form element.
             */
            vm.submitForm = function($event) {
                var form = $($event.currentTarget);
                
                // Add loader.
                form.addClass('icon-loading');

                // Sending timezone offset within form data
                try {
                    vm.formData.timezone = new Date().getTimezoneOffset();
                } catch (e) {
                    console.log(e);
                }

                // Get contact data.
                ContactService.submit(vm.formData).then(function(response) {        
                    vm.messageBody = response.data.message;
                    vm.messageType = 'success';

                }, function() {
                    vm.messageBody = 'contact_error_message';
                    vm.messageType = 'error';

                }).finally(function() {
                    ProfilerService.setTimeLoad();

                    // Remove loader with timeout to show progress.
                    $timeout(function() {
                        form.removeClass('icon-loading');
                    }, 300);

                });
            };
        }
    }
})();