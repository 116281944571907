(function() {

    'use strict';

    angular.module('app.services')
        .factory('ChartSettingsService', ChartSettingsService);

    ChartSettingsService.$inject = ['ChartCustomSettings'];

    function ChartSettingsService(ChartCustomSettings) {
        return {

            // Set the default project settings             
            defaultSettings: {

                /**
                 * General settings
                 */
                colors: ['#000000', '#E2B224', '#96C80F', '#4FA645', '#25529C', '#941E5B'], // Colors range for datasets
                recalculateToPercents: 'always', //Show axes in percents (true|false|'always')

                /**
                 * Category axis settings
                 */
                categoryAxisAlpha: 1, // Border between category axis and chart
                categoryAxisColor: '#9b9b9b', // Border between category axis and chart
                categoryAxisGridCount: 3, // How man category columns should be displayed (is Overwritten by: categoryAxesSettingsAutoGridCount)
                categoryAxisGridColor: '#f2f2f2', // Grid color of category axis
                categoryAxisHeight: 40, // Height of category axis (chart height decreased by this value)
                categoryAxesMaxSeries: 0, //Maximum number of datapoints
                categoryAxisThickness: 2, // Border between category axis and chart
                categoryAxesSettingsAutoGridCount: false, // Set category grid count automatically (Overwrites: categoryAxisHeight)
                categoryAxesSettingsColor: '#262626', // Textcolor category axis
                categoryAxisDateFormats: [{
                        period: 'fff',
                        format: 'DD.MM.YYYY'
                    },
                    {
                        period: 'ss',
                        format: 'DD.MM.YYYY'
                    },
                    {
                        period: 'mm',
                        format: 'DD.MM.YYYY'
                    },
                    {
                        period: 'hh',
                        format: 'DD.MM.YYYY'
                    },
                    {
                        period: 'DD',
                        format: 'DD.MM.YYYY'
                    },
                    {
                        period: 'MM',
                        format: 'MMM'
                    },
                    {
                        period: 'YYYY',
                        format: 'YYYY'
                    }
                ],
                categoryAxesSettingsBoldPeriodBeginning: false,
                categoryAxesSettingsEqualSpacing: false,
                categoryAxesSettingsFillAlpha: 0,
                categoryAxesSettingsFillColor: '#000000',
                categoryAxesSettingsFontSize: 16, // Fontsize category axis labels
                categoryAxesSettingsGridAlpha: 1,
                categoryAxesSettingsMinPeriod: 'DD',
                categoryAxesSettingsOffset: 0, // Distance between category axis and chart
                categoryAxesSettingsPosition: 'bottom', // Category axis position
                categoryAxesSettingsTickLength: 15, // Short grid line before category label

                /**
                 * Chartcursor settings
                 */
                showCursor: true,
                chartCursorSettingsBulletsEnabled: false, // Show bullets on mouseover
                chartCursorSettingsBulletSize: 7, // Size of bullets
                chartCursorSettingsCategoryBalloonEnabled: true, // Show cursor bottom label
                chartCursorSettingsCursorColor: '#333333', // Cursor and label background color
                chartCursorSettingsCategoryBalloonDateFormats: [{
                        period: 'fff',
                        format: 'DD.MM.YYYY'
                    },
                    {
                        period: 'ss',
                        format: 'DD.MM.YYYY'
                    },
                    {
                        period: 'mm',
                        format: 'DD.MM.YYYY'
                    },
                    {
                        period: 'hh',
                        format: 'DD.MM.YYYY'
                    },
                    {
                        period: 'DD',
                        format: 'DD.MM.YYYY'
                    },
                    {
                        period: 'MM',
                        format: 'DD.MM.YYYY'
                    },
                    {
                        period: 'YYYY',
                        format: 'YYYY'
                    }
                ],
                chartCursorSettingsOneBalloonOnly: false, // Show only one balloon per graph (maybe a performance leak)
                chartCursorSettingsValueBalloonsEnabled: true, // Show value ballons on chartcursor

                /**
                 * Graph settings
                 */
                chartGraph: 'line', // Type of graph
                graphFillAlphas: 0, // Graph (positive) area fill alpha
                graphLineThickness: 2, // Line thickness of main graph
                graphNegativeFillAlphas: 1, // Negative area fill alpha
                graphNegativeFillColors: '#CC0000', // Negative area fill color
                graphNegativeLineColor: '#00ff00', // Negative line color
                graphShowBalloon: true, // Show value labels on datapoint
                graphBalloonText: '[[value]]', // Value for mouseover balloon
                compareGraphBalloonText: '[[value]]', // Value for compared graphs mouseover balloon

                /**
                 * Balloon settings
                 */
                balloonAdjustBorderColor: true,
                balloonFillColor: '#ffffff', // Balloon label background color
                balloonColor: "#333333",
                balloonCornerRadius: 0, // Balloon label border radius
                balloonHorizontalPadding: 20,
                balloonVerticalPadding: 5,
                balloonPointerWidth: 28, // Size of the tiny triangle aiming on the graph
                balloonFontSize: 16, // Size of text for labels and values

                /**
                 * Guide settings 
                 */
                showGuides: false,
                guides: {
                    previousClose: {
                        balloonColor: '#ffffff',
                        color: '#262626',
                        dashLength: 3,
                        fontFamily: 'Yoga Sans for LUKB W01 Medium',
                        fontSize: 16,
                        inside: false,
                        lineAlpha: 1,
                        lineColor: '#9b9b9b',
                        lineThickness: 1,
                        offset: 6,
                        padding: 6,
                        position: 'left',
                        showTriangle: false,
                        textAlign: 'start'
                    },
                    barrier: {
                        balloonColor: '#ffffff',
                        color: '#000',
                        dashLength: 10,
                        fontFamily: 'Yoga Sans for LUKB W01 Medium',
                        fontSize: 16,
                        inside: true,
                        lineAlpha: 1,
                        lineColor: '#9b9b9b',
                        lineThickness: 1,
                        offset: 7,
                        padding: 1,
                        position: 'right',
                        showTriangle: false,
                        textAlign: 'start'
                    }
                },

                /**
                 * Push settings
                 */
                push: false,

                /**
                 * Panel settings
                 */
                panelsSettingsFontFamily: 'Yoga Sans for LUKB W01 Regular',
                panelsSettingsFontSize: 16,
                panelsSettingsMarginLeftSteps: [45, 45, 45, 45, 65, 70, 75, 80, 85, 90], //Set margins for diffrent count of decimals
                panelsSettingsMarginRightSteps: 0, //Set margins for diffrent count of decimals
                panelsSettingsNumberFormatterPrecision: 2,
                showBullet: false,
                showNegativeLineColor: false, // Display positive/negative lines
                showSecondAxis: false,
                showMargins: true,

                /**
                 * Value axis settings
                 */
                valueAxisGridColor: '#C4C4C4', // Grid color of value axis
                valueAxisGridAlpha: 0.3, // Grid alpha of value axis
                valueAxisLabelsEnabled: true,
                valueAxisPosition: 'left', // Value axis position
                valueAxesSettingsFontSize: 16, // Fontsize category axis labels
                valueAxesSettingsInside: false, // Display value labels inside
                valueAxesSettingsColor: '#262626', // Textcolor value axis
                valueAxesSettingsUnit: '', // Unit after labels
                valueAxisMinMaxMultiplier: 1 // Value axis scale (min and max numbers) will be multiplied with this value
            },

            //Default timespan specific settings
            defaultTimespanSettings: {
                '1D': {
                    push: true,
                    categoryAxisGridCount: 8,
                    categoryAxesSettingsMinPeriod: 'ss'
                },
                '1W': {
                    categoryAxisGridCount: 8,
                    categoryAxesSettingsEqualSpacing: true
                },
                '1M': {
                    categoryAxisGridCount: 5
                },
                '3M': {
                    categoryAxisGridCount: 5
                },
                '6M': {
                    categoryAxisGridCount: 6
                },
                'YTD': {
                    categoryAxisGridCount: 10
                },
                '1Y': {
                    categoryAxisGridCount: 10
                },
                '5Y': {
                    categoryAxisGridCount: 10
                },
                '30Y': {
                    categoryAxisGridCount: 10
                }
            },

            /* 
             * Overwrite type (charttheme) specific settings
             * @param default{object} overwrites the general parameters of the chart
             * @param timespan{object} overwrites paremters for a specific timespan
             * 
             * Example:
             * "themename": {
             *     defaults: {
             *         showBullet: true
             *     },
             *     timespans: {
             *         1D: {
             *             showBullet: fals
             *         }
             *     }
             * }
             */
            settings: {
                mobile: {
                    timespans: {
                        '1M': {
                            categoryAxisGridCount: 3
                        },
                        '3M': {
                            categoryAxisGridCount: 3
                        },
                        '6M': {
                            categoryAxisGridCount: 3
                        },
                        'YTD': {
                            categoryAxisGridCount: 3
                        },
                        '1Y': {
                            categoryAxisGridCount: 5
                        }
                    }
                },
                forwardCurve: {
                    defaults: {
                        graphFillAlphas: 0,
                        showBullet: true
                    }
                },
                marketSmall: {
                    defaults: {
                        categoryAxisAlpha: 0,
                        categoryAxisHeight: 0,
                        categoryAxesSettingsFillAlpha: 0,
                        showGuides: false,
                        showMargins: false,
                        valueAxisGridAlpha: 0,
                        valueAxisLabelsEnabled: false
                    }
                },
                portfolio: {
                    defaults: {
                        showGuides: false,
                    }
                },
                portfolioSmall: {
                    defaults: {
                        categoryAxisAlpha: 0,
                        categoryAxisHeight: 0,
                        categoryAxesSettingsFillAlpha: 0,
                        showGuides: false,
                        showMargins: false,
                        valueAxisGridAlpha: 0,
                        valueAxisLabelsEnabled: false
                    }
                },
                candlestick: {
                    defaults: {
                        chartGraph: 'candlestick'
                    }
                },
                ohlc: {
                    defaults: {
                        chartGraph: 'ohlc'
                    }
                },
                step: {
                    defaults: {
                        chartGraph: 'step'
                    }
                }
            },

            /* 
             * Return chart type and timespan specific settings
             * @params type{string}, timespan{string}
             */
            get: function(type, timespan) {
                var obj = {};

                //Get custom settings from the project constants
                angular.merge(this, ChartCustomSettings);
                if (ChartCustomSettings.defaultTimespanSettings) {
                    angular.extend(ChartCustomSettings, ChartCustomSettings.defaultTimespanSettings[timespan]);
                }

                //Get default settings
                angular.extend(obj, this.defaultSettings);
                angular.extend(obj, this.defaultTimespanSettings[timespan]);

                //Overwrite with type or type-timespan settings
                if (this.settings[type] && this.settings[type].defaults) {
                    angular.extend(obj, this.settings[type].defaults);
                }
                if (this.settings[type] && this.settings[type].timespans) {
                    angular.extend(obj, this.settings[type].timespans[timespan]);
                }
                return obj;
            }
        };
    }
})();