//websocket.service.js
(function () {
    
    'use strict';

    angular
        .module('app.services')
        .factory('WebsocketService', WebsocketService);

    WebsocketService.$inject = ['$interval', '$rootScope', 'PUSHSETTINGS'];

    function WebsocketService($interval, $rootScope, PUSHSETTINGS) {
        var service = {
            channelId: '',
            formatResponse: formatResponse,
            getInstrumentsSubscribed: getInstrumentsSubscribed,
            getPerformance: getPerformance,
            instruments: [],
            start: start,
            updateInstruments: updateInstruments,
            startHeartBeat: startHeartBeat,
            heartbeat: null,
            ws: null,
            subscribed: [],
            addSubscribePush: addSubscribePush,
            subscribePush: subscribePush,
            updateObject: updateObject,
            restart: restart
        };

        return service;
        ///////////////////////////////

        /* 
         * Get subscribed instrument, returns boolean
         * @param idValue (sin, valor, isin)
         */
        function getInstrumentsSubscribed(idValue) {
            var i;
            for (i = 0; i < service.subscribed.length; i++) {
                if (service.subscribed[i] === idValue) {
                    return true;
                }
            }
            return false;
        }

        //Calculate performance
        function getPerformance(close, current) {
            var previousClose = Number(close),
                currentPrice = Number(current),
                abs = currentPrice - previousClose,
                performance = {
                    '1D': abs * (100 / previousClose),
                    '1DAbs': abs
                };
            return performance;
        }

        //Format response
        function formatResponse(postResponse) {
            var response = JSON.parse(postResponse.data);
            if (response.subscribe) {
                service.channelId = response.subscribe.channelId;
            }
            service.updateInstruments(response);
        }

        //Restart push
        function restart() {
            service.start(service.instruments);
        }

        // Start connection
        function start(obj) {            
            var i;

            if (obj && obj.length > 0) {

                //Push server
                service.ws = new WebSocket(PUSHSETTINGS.api);
                
                //Errors
                service.ws.onerror = function (error) {
                    console.log(error);
                };

                // Log messages from the server
                service.ws.onmessage = function (data) {
                    service.formatResponse(data);
                };

                //Connection close
                service.ws.onclose = function (evt) {
                    console.log('close');
                    console.log(evt);
                };
                
                //Open websocket connection
                service.ws.onopen = function () {
                    
                    /**
                     * Call multiple loops to go deeper.
                     * Especially for lukb product list.
                     */
                    for (var key in obj) {
                        if (obj[key] && obj[key].length > 0) {
                            for (var key2 in obj[key]) {
                                if (obj[key][key2] && obj[key][key2].length > 0) {
                                    for (var key3 in obj[key][key2]) {
                                        if (obj[key][key2][key3] && obj[key][key2][key3].hasOwnProperty('properties')) {
                                            var pushObj = obj[key][key2][key3].properties;

                                            //Add instruments
                                            //instance.instruments = [];
                                            for (i = 0; i < pushObj.length; i++) {
                                                service.instruments.push(pushObj[i]);
                                            }

                                            //Check for running websockets
                                            if (service.channelId) {
                                                service.addSubscribePush(pushObj);
                                                return;
                                            }
                                            
                                            // Subscribe push object.
                                            service.subscribePush(pushObj);
                                        }
                                    }
                                }
                            }
                        }
                    }
                };
            }
        }

        /* 
         * Add new instrument to open websocket connection
         * @param instruments{Array} current instruments to push
         */
        function addSubscribePush(instruments) {
            var i,
                body = {
                    application: PUSHSETTINGS.application,
                    channelId: service.channelId,
                    user: PUSHSETTINGS.user,
                    addSubscribe: []
                };

            //Subscribe instruments
            for (i = 0; i < instruments.length; i++) {
                if (instruments[i].push) {
                    var identifier = Object.keys(instruments[i].pushDetails.instrumentIdentifiers)[0],
                        identifierValue = instruments[i].pushDetails.instrumentIdentifiers[identifier];

                    if (!service.getInstrumentsSubscribed(identifierValue)) {
                        body.addSubscribe.push(identifier + ':' + identifierValue + '|' + instruments[i].pushDetails.marketIdType + ':' + instruments[i].pushDetails.marketId + '|0|bid,ask,last|snapshot');
                        service.subscribed.push(identifierValue);
                    }
                }
            }

            //Send elements ids to server
            if (body.addSubscribe.length) {
                service.ws.send(JSON.stringify(body));
            }
        }

        //Subscribe to quoteserver
        function subscribePush(instruments) {
            var i,
                body = {
                    application: PUSHSETTINGS.application,
                    user: PUSHSETTINGS.user,
                    subscribe: []
                };

            //Subscribe instruments
            for (i = 0; i < instruments.length; i++) {
                if (instruments[i].push) {
                    var identifier = Object.keys(instruments[i].pushDetails.instrumentIdentifiers)[0],
                        identifierValue = instruments[i].pushDetails.instrumentIdentifiers[identifier];
                
                    body.subscribe.push(identifier + ':' + identifierValue + '|' + instruments[i].pushDetails.marketIdType + ':' + instruments[i].pushDetails.marketId + '|0|bid,ask,last|snapshot');
                    service.subscribed.push(identifierValue);
                }
            }
            
            //Send elements ids to server
            if (body.subscribe.length) {
                service.ws.send(JSON.stringify(body));
            }
        }

        // Check periodically for websocket connection and force restart if not connected
        function startHeartBeat() {
            service.heartBeat = $interval(function () {
                if (service.ws && service.ws.readyState !== 1) {
                    console.log('WS: restart');
                    service.restart();
                }
            }, 3000);
        }

        //Update markets object
        function updateInstruments(instrumentsList) {
            var i,
                data;

            // Prepare price data type
            for (i = 0; i < instrumentsList.length; i++) {
                if (!instrumentsList[i].fields) {
                    return;
                } else if (instrumentsList[i].fields.bid) {
                    data = instrumentsList[i].fields.bid;
                } else if (instrumentsList[i].fields.ask) {
                    data = instrumentsList[i].fields.ask;
                } else if (instrumentsList[i].fields.last) {
                    data = instrumentsList[i].fields.last;
                } else {
                    return;
                }

                // Update object
                service.updateObject(instrumentsList[i], data);
            }
        }

        /*
         * Search equivalent local object and update with quoteserver values
         * @params: instrument{Object}, data{Object}
         */
        function updateObject(instrument, data) {
            var i;

            for (i = 0; i < service.instruments.length; i++) {
                if (service.instruments[i].pushDetails.instrumentIdentifiers[instrument.idtype] == instrument.id) {
                    // Set push class before updating price value
                    if (service.instruments[i].value < data.v) {
                        service.instruments[i].pushDetails.push = 'up';
                    } else if (service.instruments[i].value > data.v) {
                        service.instruments[i].pushDetails.push = 'down';
                    }
                    service.instruments[i].value = data.v; // Set price
                    service.instruments[i].performance = service.getPerformance(service.instruments[i].previousClose, data.v); // Set performance
                    service.instruments[i].time = new Date(data.t); // Set time
                    service.instruments[i].size = data.s; // Set size
                }
            }
            
            // Update dom
            $rootScope.$apply();
        }
    }
})();