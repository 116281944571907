(function () {

    'use strict';

    //Chartservice
    angular
        .module('app.services')
        .factory('ChartPushService', ChartPushService);

    ChartPushService.$inject = ['$rootScope', 'WebsocketService', '$locale', '$state'];

    function ChartPushService($rootScope, WebsocketService, $locale, $state) {
        return {
            data: null,
            instrument: null,
            watcher: null,
            set: function (obj) {            
                var instance = this;
                angular.extend(instance, obj);

                //Get subscribed object
                instance.instrument = WebsocketService.instruments.searchObjectsByKey(obj.identifier, obj.id)[0];

                //Start watching
                instance.watcher = $rootScope.$watch(function () {
                    return instance.instrument.time;
                }, function (a, b) {
                    if (a) {
                        instance.checkData(instance.instrument);
                    }
                });
            },

            /* 
             * Check data
             */
            checkData: function (instrument) {

                //Check for valid date
                if (typeof instrument.time.getMinutes !== 'function') {
                    return;
                }

                var instance = this,
                    lastDataPoint = instance.dataSet.dat[instance.dataSet.dat.length - 2];

                //Check date
                if (lastDataPoint.date < instrument.time) {
                    instance.setData(instrument);
                }
            },

            /*
             * Set new dataset
             */
            setData: function (instrument) {
                var instance = this,
                    lastData = instance.dataSet.dat[instance.dataSet.dat.length - 1],
                    oldData = instance.dataSet.dat[instance.dataSet.dat.length - 2],
                    newLastIndex = {
                        date: lastData.date,
                        value: 'null'
                    },
                    newData = {
                        date: instrument.time,
                        value: instrument.price
                    };

                //Remove enddate holder point
                instance.dataSet.dat.pop();

                //Remove existing push datepoint
                if (oldData.date.getMinutes() === instrument.time.getMinutes()) {
                    instance.dataSet.dat.pop();
                }

                //Add push data
                instance.dataSet.dat.push(newData);

                //Add enddate holder again
                instance.dataSet.dat.push(newLastIndex);

                //Update chart
                instance.updateChart();
            },

            updateChart: function () {
                var instance = this,
                    chartMin = instance.dataSet.mit.value,
                    chartMax = instance.dataSet.mat.value,
                    price = Number(instance.instrument.price);

                //Update labels and min/max values
                if (price < chartMin) {
                    instance.update();
                } else if (price > chartMax) {
                    instance.update();
                } else {
                    instance.chart.validateData();
                }
            }
        };
    }
})();