// application.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('ApplicationService', ApplicationService);

    // Inject dependencies.
    ApplicationService.$inject = ['$q'];

    // Init applicationService.
    function ApplicationService($q) {

        return {

            /**
             *  Get site Variant Config.
             *  @returns {object} promise
             */
            getSiteConfig: function() {
                var deferred = $q.defer(),
                    config = window.AppConfig;

                deferred.resolve(config);
                return deferred.promise;
            }
        };
    }
})();