// disclaimer.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('disclaimerPopup', disclaimerPopup);

    // Inject dependencies.
    disclaimerPopup.$inject = [
        'DisclaimerService',
        '$rootScope',
        '$timeout',
        'MediaQueryService',
        'LocaleService',
        '$window'
    ];

    function disclaimerPopup(DisclaimerService, $rootScope, $timeout, MediaQueryService, LocaleService, $window) {
        return {
            controller: controller,
            link: link,
            restrict: 'E',
            templateUrl: '/templates/disclaimerPopup.html'
        };

        function instantiateNiceScroll() {

            // Nice scroll configuration parameters,
            // documentation at: https://github.com/inuyaksa/jquery.nicescroll#configuration-parameters
            var niceScrollOptions = {
                autohidemode: false,
                cursorcolor: '#E3E3E3',
                cursorwidth: '10px'
            };
            $('.disclaimer-container').niceScroll(niceScrollOptions);
            $('.disclaimer-dialog').niceScroll(niceScrollOptions);
        }

        function link() {
            try {
                if (MediaQueryService.isMediaDesktop()) {
                    $timeout(function() {
                         instantiateNiceScroll();
                    }, 100);
                }
            } catch (error) {
                console.log(error);
            }
        }

        function getDisclaimerContent() {
            if (window.AppConfig && window.AppConfig.disclaimer) {
                return window.AppConfig.disclaimer;
            }

            console.log('DisclaimerPopup:: Warning: could not find disclaimer content under window.AppConfig!');
            return [];
        }

        function controller($scope) {

            $scope.gridData = getDisclaimerContent();

            /**
             * this function is Triggered when the user clicks on the Accept button.
             * check if the user Accept the Term the put Local Variable
             * with TimeStamp in the localStorage. Otherwise close the disclaimer.
             */
            $scope.accept = function(accepted) {
                DisclaimerService.setDisclaimer(accepted);

                // Set root urls for languages, language short name and locale names
                LocaleService.updateLanguageUrls();

                if (MediaQueryService.isMediaMedium() || MediaQueryService.isMediaMobile()) {
                    $timeout(function() {
                        if ($window.location && $window.location.reload){
                            $window.location.reload();
                        }
                    }, 100);
                    return;
                }

                $rootScope.showDisclaimerPopup = false;

                // Remove show disclaimer class from body.
                $('body').removeClass('show-disclaimer');
            };

        }
    }
})();
