// instrumentChart.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('instrumentChart', instrumentChart);

    // Inject dependencies.
    instrumentChart.$inject = ['MediaQueryService'];

    function instrumentChart(MediaQueryService) {
        var directive = {
            controller: controller,
            restrict: 'E'
        };

        function controller($scope) {

            /**
             * Get current media type
             */
            $scope.media = MediaQueryService.getMedia();

            $scope.showDropDown = false;

            /**
             * Set benchmark using chart instance
             * @param {Object} data Benchmark data
             */
            $scope.setBenchmark = function(data) {

                // Get chart benchmark variable
                var benchmark = $scope.instrumentChart.chart.benchmarks.searchObjectsByKey('sin', data.sin)[0];

                // Toggle active state 
                data.active = !data.active; // View variable
                benchmark.active = data.active; // Chart instance variable

                // Update chart benchmarks
                $scope.instrumentChart.toggleBenchmark().then(function() {
                    $(event.target).toggleClass('active');
                }, function() {
                    console.log('CHART: error set benchmark');
                });
            };

            /**
             * Show all benchmarks
             */
            $scope.setAllBenchmarks = function(bool) {
                var i;

                // Set chart instance benchmark state 
                $scope.instrumentChart.toggleBenchmark(bool).then(function() {

                    // Set view benchmark state 
                    for (i = 0; i < $scope.chartBenchmarks.length; i++) {
                        $scope.chartBenchmarks[i].active = bool;
                    }
                }, function() {
                    console.log('CHART: error set benchmark');
                });
            };

            /**
             * Set timespan
             * @param {String} timespan 
             */
            $scope.setTimespan = function(timespan) {
                if ($scope.instrumentChart.chart.chartEngine.selectedPeriod !== timespan) {
                    $scope.instrumentChart.setTimespan(timespan);
                }

                $scope.showDropDown = false;
            };

            // Toggle main graph visibility
            $scope.toggleMainDataset = function() {
                $scope.mainDataGraphHidden = !$scope.instrumentChart.toggleMainGraphVisibility();
            };

            $scope.toggleDropDown = function() {
                $scope.showDropDown = true;
            };
        }

        return directive;
    }
})();