// contact.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('ContactService', ContactService);

    // Inject dependencies.
    ContactService.$inject = [
        'HttpWrapper',
        '$q',
        'ApiService'
    ];

    // Init ContactService.
    function ContactService(HttpWrapper, $q, ApiService) {
        return {
            data: null,
            
            /**
             * Get contact data.
             * @returns {promise}
             */
            get: function() {
                var deferred = $q.defer(),
                    instance = this;

                HttpWrapper({
                    method: 'GET',
                    refreshProfiler: true,
                    url: '/contact'
                }, function(response) {
                    instance.data = response;
                    deferred.resolve(response);
                }, function(rejection) {
                    deferred.reject(rejection);
                });

                return deferred.promise;
            },
            
            
            /**
             * Submit contact form.
             * @param {object} data - Form data from ng-models.
             * @returns {promise}
             */
            submit: function(data) {
                var deferred = $q.defer(),
                    instance = this,
                    url = instance.data.url,
                    header = ApiService.getHeaders();

                header['Content-Type'] = 'application/x-www-form-urlencoded';

                data[instance.data.data.tokenName] = instance.data.data.token;

                HttpWrapper({
                    data: $.param(data),
                    method: 'POST',
                    refreshProfiler: true,
                    url: url
                }, function(response) {
                    deferred.resolve(response);
                }, function(rejection) {
                    deferred.reject(rejection);
                });

                return deferred.promise;
            }
        };
    }
})();