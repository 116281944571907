// AutocallableReverseConvertibleWithConditionalCoupon.constants.js
(function() {
    'use strict';

    angular
        .module('app.constants')
        .constant("AutocallableReverseConvertibleWithConditionalCoupon", {
            "mobile": {
                "inSubscription": [
                    {
                        label: 'couponPercentPa',
                        value: 'couponPercentPa',
                        precision: 2
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames',
                        icon: 'settlementMethodIcon',
                        minWidth: 200 // Minimum column width in pixels in the instrument table
                    },
                    {
                        label: 'currency',
                        value: 'currency',
                        icon: 'quantoIcon'
                    },
                    {
                        label: 'relativeStrike2ReferencePrice',
                        value: 'relativeStrike2ReferencePrice',
                        precision: 2
                    },
                    {
                        label: 'redemptionDate',
                        value: 'redemptionDate',
                        icon: 'earlyRedemptionIcon',
                        format: 'mediumDate'
                    },
                    {
                        label: 'subscriptionPeriodEndDate',
                        value: 'subscriptionPeriodEndDate',
                        format: 'customDateTime',
                        minWidth: 160 // Minimum column width in pixels in the instrument table
                    },
                    {
                        label: 'valor',
                        value: 'valor',
                        notSortable: true
                    },
                    {
                        label: ' ',
                        value: 'menu',
                        align: 'right',
                        notSortable: true
                    }
                ],
                "secondaryMarket": [
                    {
                        label: 'maxPerformancePercentPaPriceCurrency',
                        value: 'maxPerformancePercentPaPriceCurrency'
                    },
                    {
                        label: 'couponPercentPa',
                        value: 'couponPercentPa'
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames',
                        icon: 'settlementMethodIcon',
                        minWidth: 200 // Minimum column width in pixels in the instrument table
                    },
                    {
                        label: 'currency',
                        value: 'currency',
                        icon: 'quantoIcon'
                    },
                    {
                        label: 'distance2StrikePercent',
                        value: 'distance2StrikePercent'
                    },
                    {
                        label: 'distance2triggerLevelRefCurrencyPercent',
                        value: 'distance2triggerLevelRefCurrencyPercent'
                    },
                    {
                        label: 'redemptionDate',
                        value: 'redemptionDate',
                        icon: 'earlyRedemptionIcon',
                        format: 'mediumDate'
                    },
                    {
                        label: 'bid',
                        value: 'bid'
                    },
                    {
                        label: 'ask',
                        value: 'ask'
                    },
                    {
                        label: ' ',
                        value: 'menu',
                        align: 'right',
                        notSortable: true
                    }
                ]
            },


            // desktop version configuration
            "inSubscription": [
                {
                    label: 'couponPercentPa',
                    value: 'couponPercentPa',
                    precision: 2
                },
                {
                    label: 'underlyingNames',
                    value: 'underlyingNames',
                    icon: 'settlementMethodIcon',
                    minWidth: 200 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'currency',
                    value: 'currency',
                    icon: 'quantoIcon'
                },
                {
                    label: 'relativeStrike2ReferencePrice',
                    value: 'relativeStrike2ReferencePrice',
                    precision: 2
                },
                {
                    label: 'redemptionDate',
                    value: 'redemptionDate',
                    icon: 'earlyRedemptionIcon',
                    format: 'mediumDate'
                },
                {
                    label: 'subscriptionPeriodEndDate',
                    value: 'subscriptionPeriodEndDate',
                    format: 'customDateTime',
                    minWidth: 160 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'valor',
                    value: 'valor',
                    notSortable: true
                },
                {
                    label: ' ',
                    value: 'menu',
                    align: 'right',
                    notSortable: true
                }
            ],
            "secondaryMarket": [{
                label: 'maxPerformancePercentPaPriceCurrency',
                value: 'maxPerformancePercentPaPriceCurrency'
            },
                {
                    label: 'couponPercentPa',
                    value: 'couponPercentPa'
                },
                {
                    label: 'underlyingNames',
                    value: 'underlyingNames',
                    icon: 'settlementMethodIcon',
                    minWidth: 200 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'currency',
                    value: 'currency',
                    icon: 'quantoIcon'
                },
                {
                    label: 'distance2StrikePercent',
                    value: 'distance2StrikePercent'
                },
                {
                    label: 'distance2triggerLevelRefCurrencyPercent',
                    value: 'distance2triggerLevelRefCurrencyPercent'
                },
                {
                    label: 'redemptionDate',
                    value: 'redemptionDate',
                    icon: 'earlyRedemptionIcon',
                    format: 'mediumDate'
                },
                {
                    label: 'bid',
                    value: 'bid'
                },
                {
                    label: 'ask',
                    value: 'ask'
                },
                {
                    label: ' ',
                    value: 'menu',
                    align: 'right',
                    notSortable: true
                }
            ],
            "productDetail": {
                "categorization": [{
                        label: 'product_category',
                        value: 'metaMetaGroupName'
                    },
                    {
                        label: 'metaGroupName',
                        value: 'metaGroupName'
                    },
                    {
                        label: 'groupName',
                        value: 'groupName'
                    }
                ],
                "lifecycle": [{
                        label: 'product_detail_subscriptionPeriodEndDate',
                        value: 'subscriptionPeriodEndDate',
                        visibleIf: 'inSubscription'
                    },
                    {
                        label: 'product_detail_fixingDate',
                        value: 'fixingDate'
                    },
                    {
                        label: 'product_detail_issueDate',
                        value: 'issueDate'
                    },
                    {
                        label: 'lastTradingDate',
                        value: 'lastTradingDate'
                    },
                    {
                        label: 'product_detail_maturityDate',
                        value: 'maturityDate'
                    },
                    {
                        label: 'product_detail_redemptionDate',
                        value: 'redemptionDate'
                    },
                    {
                        label: 'repayment',
                        value: 'static',
                        staticValue: 'true'
                    },
                    {
                        label: 'repaymentLevelPriCurPercent',
                        value: 'nextRepaymentDate'
                    },
                    {
                        label: 'days2MaturityDate',
                        value: 'days2MaturityDate',
                        precision: 0
                    }
                ],
                "staticData": [{
                        label: 'issuer',
                        value: 'issuer'
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames'
                    },
                    {
                        label: 'issuePrice',
                        value: 'issuePrice',
                        precision: 2
                    },
                    {
                        label: 'currency',
                        value: 'currency'
                    },
                    {
                        label: 'isQuanto',
                        value: 'isQuanto'
                    },
                    {
                        label: 'settlementMethod',
                        value: 'settlementMethod'
                    },
                    {
                        label: 'quotingMethod',
                        value: 'quotingMethod'
                    },
                    {
                        label: 'notionalValue',
                        value: 'notionalValue',
                        precision: 0
                    },
                    {
                        label: 'listing',
                        value: 'inListing'
                    }
                ],
                "coupons": [{
                        label: 'conditionalCouponPa',
                        value: 'couponPercentPa',
                        precision: 2
                    },
                    {
                        label: 'Memory',
                        value: 'couponMemory'
                    },
                    {
                        label: 'couponPaymentFrequency',
                        value: 'couponPaymentFrequency'
                    }
                ],
                "strike": [{
                        label: 'relativeStrike2ReferencePrice',
                        value: 'relativeStrike2ReferencePrice'
                    }
                ],
                "observationDaysList": [{
                        label: 'observationDaysList',
                        value: 'observationDaysList'
                    }
                ],
                "earlyRedemptionList": [{
                        label: 'observationDate',
                        value: 'observationDate'
                    },
                    {
                        label: 'repaymentValue',
                        value: 'repaymentValue',
                        precision: 2
                    },
                    {
                        label: 'triggerLevel',
                        value: 'triggerLevel',
                        precision: 2
                    }
                ],
                "pattern": [{
                        label: 'performanceSinceIssuePercent',
                        value: 'performanceSinceIssuePercent'
                    },
                    {
                        label: 'underlyingPerformance2ReferencePriceRelative',
                        value: 'underlyingPerformance2ReferencePriceRelative'
                    },
                    {
                        label: 'maxPerformancePercentPriceCurrency',
                        value: 'maxPerformancePercentPriceCurrency'
                    },
                    {
                        label: 'maxPerformancePercentPaPriceCurrency',
                        value: 'maxPerformancePercentPaPriceCurrency'
                    }
                ],
                "underlyings": [{
                    label: 'underlying',
                    value: 'underlying'
                },
                    {
                        label: 'price',
                        value: 'price',
                        precision: 2
                    },
                    {
                        label: 'performance_since_issue_percent',
                        value: 'performanceSinceIssuePercent'
                    },
                    {
                        label: 'strike',
                        value: 'strike',
                        precision: 2
                    },
                    {
                        label: 'distance2StrikePercent',
                        value: 'distance2StrikePercent',
                        precision: 2
                    },
                    {
                        label: 'triggerLevelRefCurAbsolute',
                        value: 'triggerLevelRefCurAbsolute',
                        precision: 2
                    },
                    {
                        label: 'distance2triggerLevelRefCurrencyPercent',
                        value: 'distance2triggerLevelRefCurrencyPercent'
                    }
                ]
            }
        });
})();