(function() {
    'use strict';

    angular
        .module('app.filters')
        .filter('serverErrorFilter', serverErrorFilter);

    // Inject dependencies.
    serverErrorFilter.$inject = ['$filter'];

    function serverErrorFilter($filter) {

        /**
         *  this filter is responsible of creating an Html error massage to be displayed
         *  from any backend Zend validation.
         *
         *  There are several form of backend error massage include the Form validation Error messages
         *  the direct string error massages.
         *
         *  Any future case of backend error maessages should be included here
         *
         *  All messages are translated. so the massage could be a direct massage or translation key
         */
        return function(errorMessage) {

            var errorMassageCreated = '', input;
            switch (typeof errorMessage) {
                case 'object':
                    for (input in errorMessage) {
                        if (errorMessage.hasOwnProperty(input)) {
                            for (var key in errorMessage[input]) {
                                if (
                                    errorMessage[input].hasOwnProperty(key) &&
                                    errorMessage[input][key]
                                ) {
                                    errorMassageCreated +=
                                        '<p>' + $filter('translate')(errorMessage[input][key]) + '</p>';
                                }
                            }
                        }
                    }
                    
                    break;
                case 'string':
                    errorMassageCreated = '<p>' + $filter('translate')(errorMessage) + '</p>';
                    break;
            }

            return errorMassageCreated;
        };
    }
})();