// subscriptionProductsMobile.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('subscriptionProductsMobile', subscriptionProductsMobile);

    // Inject dependencies.
    subscriptionProductsMobile.$inject = [
        'ApplicationService',
        'MediaQueryService',
        'InstrumentService',
        '$timeout',
        'HelperService'
    ];

    function subscriptionProductsMobile(
        ApplicationService,
        MediaQueryService,
        InstrumentService,
        $timeout,
        HelperService
    ) {
        var directive = {
            restrict: 'E',
            controller: controller,
            templateUrl: '/templates/subscriptionProductsMobile.html',
            scope: {
                data: '=',
                menuFunc: '='
            }
        };

        function controller($scope) {
            var list, width, liWidth,
                offset = 20;

            $scope.index = 0;
            $scope.media = MediaQueryService.getMedia(); // Set mobile flag
            $scope.showDocuments = false;

            // Update list position
            function animateList() {
                list.css('transform', 'translate3d(' + ($scope.index * -width) + 'px, 0, 0)');
            }

            /**
             * Change pattern field to next/previous
             * @param {Boolean} flag 
             */
            $scope.changePattern = function(flag) {
                var entries = $('subscription-products-mobile ul.pattern li');

                $scope.showDocuments = false;

                // Move all list items forward or backward
                if (flag && $scope.index < entries.length - 1) {
                    $scope.index++;
                } else if (!flag && $scope.index) {
                    $scope.index--;
                }

                // Update list position
                animateList();
            };

            /**
             * Jump to index
             * @param {Integer} index - Index of current pattern
             */
            $scope.jumpToIndex = function(index) {
                $scope.index = index;
                animateList();
            };

            // Show or hide documents
            $scope.toggleDocuments = function(event, row) {
                $scope.showDocuments = !$scope.showDocuments;

                InstrumentService.getDocuments(row.sin.value).then(function(data) {
                    row.documents = data;
                });

            };

            // Open external file
            $scope.openDocument = function(xuuid, documentType) {
                HelperService.openDocument(xuuid, documentType);
            };

            // Get current locale for sorting of language keys
            ApplicationService.getSiteConfig().then(function(data) {
                $scope.currentLocale = data.locale.slice(0, 2).toUpperCase();
            });

            /**
             * Initially calculate width and list element
             */
            $timeout(function() {

                if ($scope.media === 'medium') {
                    offset = -10;
                }

                list = $('subscription-products-mobile ul.pattern');
                liWidth = $('subscription-products-mobile ul.pattern li.active').width() + offset;
                width = $(window).width() - ($(window).width() - liWidth);
            });
        }

        return directive;
    }
})();