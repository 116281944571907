// spacer.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('spacer', spacer);

    // Inject dependencies.
    spacer.$inject = [];

    function spacer() {
        var directive = {
            restrict: 'E',
            templateUrl: '/templates/spacer.html'
        };
        
        return directive; 
    }
})();