// menu.service.js
/**
 * the menuService is used to retrieve the navigation information from server side
 */
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('MenuService', MenuService);

    MenuService.$inject = [
        'HttpWrapper',
        '$q'
    ];

    function MenuService(HttpWrapper, $q) {
        return {

            // Get preloaded data
            data: window.AppConfig.navigation,

            //add parent and child menu item in the following map in order
            menuMap: {
                "productList": [
                    'amcOffering',
                    'investmentIdea',
                    'recovery'
                ]
            },

            _findUnlinkedState: function(state) {
                var unlinked = null;
                if (this.data && this.data.unlinked) {
                    unlinked = this.data.unlinked.find(function(x) {
                        return x.stateName === state;
                    });
                }

                return unlinked;
            },


            addCustomItemToSubmenu: function(data) {
                var instance = this;
                try {
                    var states = Object.keys(instance.menuMap);
                    angular.forEach(states, function(state) {
                        var children = instance.menuMap[state];
                        for (var i = 0; i < children.length; i += 1) {

                            // Find state to be added in menu in unlinked
                            var childrenState = instance._findUnlinkedState(children[i]);
                            if (!childrenState) {
                                continue;
                            }
                            
                            if (data && data.main) {

                                // Remove duplication in case link was already added
                                angular.forEach(data.main, function(entry) {
                                    if (entry.stateName === state && entry.submenu && Array.isArray(entry.submenu)) {
                                        angular.forEach(entry.submenu, function(sub, index) {
                                            if (sub.stateName === children[i]) {
                                                entry.submenu.splice(index, 1);
                                            }
                                        });
                                    }
                                });

                                // Insert new children link under defined state menu
                                angular.forEach(data.main, function(entry) {
                                    if (entry.stateName === state && entry.submenu && Array.isArray(entry.submenu)) {
                                        entry.submenu.push(childrenState);
                                    }
                                });
                            }

                        }
                    });

                } catch (e) {
                    console.log(e);
                }

                return data;
            },

            getMenu: function() {
                var deferred = $q.defer(),
                    instance = this;

                // Return already loaded data
                if (instance.data) {
                    deferred.resolve(instance.addCustomItemToSubmenu(instance.data));
                    return deferred.promise;
                }

                HttpWrapper({
                    method: 'GET',
                    url: '/navigation',
                    refreshProfiler: false
                },
                    function(response) {
                        instance.data = response;
                        deferred.resolve(instance.addCustomItemToSubmenu(instance.data));
                    },
                    function(rejection) {
                        deferred.reject(rejection);
                    }
                );

                return deferred.promise;
            }
        };
    }
})();