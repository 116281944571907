// TemporaryProduct.constants.js
(function() {
    'use strict';

    angular
        .module('app.constants')
        .constant("TemporaryProduct", {
            "mobile": {
                "inSubscription": [{
                    label: 'valor',
                    value: 'valor',
                    notSortable: true
                },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames',
                        icon: 'settlementMethodIcon'
                    },
                    {
                        label: 'currency',
                        value: 'currency',
                        icon: 'quantoIcon'
                    },
                    {
                        label: 'maturityDate',
                        value: 'maturityDate',
                        icon: 'earlyRedemptionIcon',
                        format: 'mediumDate'
                    },
                    {
                        label: 'dividendHandling',
                        value: 'dividendHandling'
                    },
                    {
                        label: 'subscriptionPeriodEndDateMobile',
                        value: 'subscriptionPeriodEndDate',
                        format: 'customDateTime'
                    },
                    {
                        label: ' ',
                        value: 'menu',
                        align: 'right',
                        notSortable: true
                    }
                ],
                "secondaryMarket": [
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames',
                        icon: 'settlementMethodIcon',
                        minWidth: 200 // Minimum column width in pixels in the instrument table
                    },
                    {
                        label: 'currency',
                        value: 'currency',
                        icon: 'quantoIcon'
                    },
                    {
                        label: 'maturityDate',
                        value: 'maturityDate',
                        icon: 'earlyRedemptionIcon',
                        format: 'mediumDate'
                    },
                    {
                        label: 'dividendHandling',
                        value: 'dividendHandling'
                    },
                    {
                        label: 'bid',
                        value: 'bid'
                    },
                    {
                        label: 'ask',
                        value: 'ask'
                    },
                    {
                        label: 'valor',
                        value: 'valor',
                        notSortable: true
                    },
                    {
                        label: ' ',
                        value: 'menu',
                        align: 'right',
                        notSortable: true
                    }
                ]
            },
            "inSubscription": [
                {
                    label: 'underlyingNames',
                    value: 'underlyingNames',
                    icon: 'settlementMethodIcon',
                    minWidth: 200 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'currency',
                    value: 'currency',
                    icon: 'quantoIcon'
                },
                {
                    label: 'maturityDate',
                    value: 'maturityDate',
                    icon: 'earlyRedemptionIcon',
                    format: 'mediumDate'
                },
                {
                    label: 'dividendHandling',
                    value: 'dividendHandling'
                },
                {
                    label: 'subscriptionPeriodEndDate',
                    value: 'subscriptionPeriodEndDate',
                    format: 'customDateTime',
                    minWidth: 160 // Minimum column width in pixels in the instrument table
                },
                {
                    label: ' ',
                    value: 'menu',
                    align: 'right',
                    notSortable: true
                }
            ],
            "secondaryMarket": [
                {
                    label: 'underlyingNames',
                    value: 'underlyingNames',
                    icon: 'settlementMethodIcon',
                    minWidth: 200 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'currency',
                    value: 'currency',
                    icon: 'quantoIcon'
                },
                {
                    label: 'maturityDate',
                    value: 'maturityDate',
                    icon: 'earlyRedemptionIcon',
                    format: 'mediumDate'
                },
                {
                    label: 'dividendHandling',
                    value: 'dividendHandling'
                },
                {
                    label: 'bid',
                    value: 'bid'
                },
                {
                    label: 'ask',
                    value: 'ask'
                },
                {
                    label: 'valor',
                    value: 'valor',
                    notSortable: true
                },
                {
                    label: ' ',
                    value: 'menu',
                    align: 'right',
                    notSortable: true
                }
            ],
            "productDetail": {
                "lifecycle": [{
                    label: 'product_detail_subscriptionPeriodEndDate',
                    value: 'subscriptionPeriodEndDate',
                    visibleIf: 'inSubscription'
                },
                    {
                        label: 'product_detail_fixingDate',
                        value: 'fixingDate'
                    },
                    {
                        label: 'product_detail_issueDate',
                        value: 'issueDate'
                    },
                    {
                        label: 'product_detail_maturityDate',
                        value: 'maturityDate'
                    },
                    {
                        label: 'product_detail_redemptionDate',
                        value: 'redemptionDate'
                    }
                ],
                "staticData": [
                    {
                        label: 'issuer',
                        value: 'issuer'
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames'
                    },
                    {
                        label: 'issuePrice',
                        value: 'issuePrice',
                        precision: 2
                    },
                    {
                        label: 'currency',
                        value: 'currency'
                    },
                    {
                        label: 'isQuanto',
                        value: 'isQuanto'
                    },
                    {
                        label: 'settlementMethod',
                        value: 'settlementMethod'
                    },
                    {
                        label: 'listing',
                        value: 'inListing'
                    }
                ],

                "pattern": [
                    {
                        label: 'performanceSinceIssuePercent',
                        value: 'performanceSinceIssuePercent'
                    }
                ]
            }
        });
})();
