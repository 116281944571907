// textPicture.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('textPicture', textPicture);

    // Inject dependencies.
    textPicture.$inject = ['MediaQueryService'];

    function textPicture(MediaQueryService) {
        var directive = {
            controller: controller,
            restrict: 'E',
            scope: {
                data: '='
            },
            templateUrl: '/templates/textPicture.html'
        };
        
        return directive;
        
        function controller($scope) {
            $scope.media = MediaQueryService.getMedia();
        }
    }
})();