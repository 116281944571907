// page.controller.js
(function() {
    'use strict';

    angular
        .module('app.controllers')
        .controller('PageController', PageController);

    // Inject dependencies.
    PageController.$inject = [
        '$scope',
        'CmsPageService',
        '$location',
        'ProfilerService'
    ];

    function PageController(
        vm,
        CmsPageService,
        $location,
        ProfilerService
    ) {
        var container = '#ui-view',
            url = $location.$$url.substr(1);
    
        ProfilerService.resetProfiler();

        // Set loader.
        $(container).addClass('icon-loading');
        
        CmsPageService.get(url).then(
            function(data) {
                vm.gridData = new Object(data);
            },
            function() {
                vm.error = 'Error Happen';
                vm.gridData = [];
            }
        ).finally(function() {
            ProfilerService.setTimeLoad();
            
            // Remove loader.
            $(container).removeClass('icon-loading');
        });
    }
})();