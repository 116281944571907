// productFilterUnderlying.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('productFilterUnderlying', productFilterUnderlying);

    // Inject dependencies.
    productFilterUnderlying.$inject = [
        '$timeout',
        'MediaQueryService'
    ];

    function productFilterUnderlying(
        $timeout,
        MediaQueryService
    ) {
        var directive = {
            controller: controller,
            link: link,
            restrict: 'E',
            templateUrl: '/templates/productsFilterUnderlying.html',
            scope: {
                data: '=',
                filterData: '=',
                submit: '=',
                toggle: '=',
                isRenderAllowed: '='
            }
        };

        function link(scope, element) {

            // Submit filter if data has
            scope.$watch(function() {
                return element.attr('class');
            }, function() {
                if (scope.changed) {
                    scope.clickOutside();
                }
            });
        }

        function controller($scope) {
            var container = 'product-filter-underlying',
                jContainer = $(container);
            
            // Scope vars
            $scope.changed = false;
            $scope.filterTemp = [];
            $scope.alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
            $scope.activeLetter = '';
            $scope.media = MediaQueryService.getMedia();

            // Update filter object
            $scope.change = function() {
                $scope.changed = true;
                synchronizeData();
            };

            // Close on mouseleave
            $scope.clickOutside = function() {

                // If something changed apply changes also on click outside
                if ($scope.changed) {
                    $scope.apply();
                }

                jContainer.removeClass('open');

                // Reset filter after a while to prevent flickering 
                $timeout(function() {
                    $scope.searchFilter = '';
                    $scope.resetActiveLetter();
                }, 5);
            };

            // Cancel button event
            $scope.clickCancel = function() {

                // Make sure the scope is not changed to prevent re-fetching from API
                $scope.changed = false;

                // Close the underlying container
                jContainer.removeClass('open');

                // Clear Selections
                var field,
                    currentSelections = [],
                    selection;

                // Get active checkboxes
                for (selection in $scope.data.active) {
                    currentSelections.push($scope.data.active[selection].value);
                }

                // Uncheck only those checkboxes that were not checked previously / active
                for (field in $scope.data.list) {
                     if (!currentSelections.includes($scope.data.list[field].value)){
                         $scope.data.list[field].selected = false;
                     }
                }

                // Make sure our active selections still remain.
                for (selection in $scope.data.active) {
                    if (currentSelections.includes($scope.data.list[selection].value)){
                        $scope.data.list[selection].selected = true;
                    }
                }


            };

            // Submit changes
            $scope.apply = function() {
                jContainer.removeClass('open');
                $scope.filterData = $scope.filterTemp;
                $scope.changed = false;

                // Submit changes
                $timeout(function() {
                    $scope.submit();
                    $scope.searchFilter = '';
                }, 5);
            };

            /**
             * Filter by first letter.
             * Enable alphabetic entry.
             * @param {object} alphabetEntry - Alphabetic entry.
             * @returns {object} Row with term and description.
             */
            $scope.filterByFirstLetter = function(letter) {
                return function(entry) {
                    if (entry.label.charAt(0).toLowerCase() === letter) {
                        return entry;
                    }
                };
            };

            // Reset active letter.
            $scope.resetActiveLetter = function() {
                $scope.activeLetter = '';
            };

            /**
             * Set active letter.
             * @param {object} $event - Alphabet entry dom node.
             */
            $scope.setActiveLetter = function($event) {
                if ($event && $event.currentTarget.getAttribute('data-letter')) {
                    var target = $event.currentTarget,
                        jTarget = $(target);

                    if (!jTarget.hasClass('active') && !jTarget.hasClass('disabled')) {
                        $scope.activeLetter = target.getAttribute('data-letter');

                        // Reset search field.
                        $scope.searchFilter = '';
                    }
                }
            };

            // Synchronize filter with data
            function synchronizeData() {
                var field;

                $scope.filterTemp = [];

                // Get actvie checkboxes
                for (field in $scope.data.list) {
                    if ($scope.data.list[field].selected) {
                        $scope.filterTemp.push(field);
                    }
                }
            }

            // Initially synchronize data
            synchronizeData();
        }

        return directive;
    }
})();