// accordion.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('dblClickSelect', dblClickSelect);
    
    function dblClickSelect() {
        return {
            restrict: 'A',
            link: function(scope, element) {
                element.on('mousedown', function(event) {
                    event.preventDefault();
                });

                element.on('dblclick', function() {
                    if (window.getSelection) {
                        var selection = window.getSelection(),
                        range = document.createRange();
                        range.selectNodeContents(element[0]);
                        selection.removeAllRanges();
                        selection.addRange(range);
                    }
                });
            }
        };
    }
    
})();