// app.module.js
(function() {
    'use strict';

    angular
        .module('app', [
            'app.templates',
            'ngAnimate',
            'ngSanitize',
            'ui.router',
            'bw.paging',
            'ngCookies',
            'ngTouch',
            'pascalprecht.translate',
            '720kb.datepicker',
            'app.constants',
            'app.controllers',
            'app.components',
            'app.directives',
            'app.services',
            'app.filters'
        ])
        .run(applicationRun);

    applicationRun.$inject = [
        '$rootScope',
        '$state',
        '$location',
        '$translate',
        'ApplicationService',
        'MediaQueryService',
        'DisclaimerService',
        'StatesService',
        '$timeout',
        'LocaleService',
        '$transitions'
    ];

    function applicationRun(
        $rootScope,
        $state,
        $location,
        $translate,
        ApplicationService,
        MediaQueryService,
        DisclaimerService,
        StatesService,
        $timeout,
        LocaleService,
        $transitions
    ) {
        $rootScope.browserTitle = 'lukb-structuredproducts-portal';

        // Add states dynamically to the state provider.
        StatesService.init(window.AppConfig.navigation);

        // Add short urls states dynamically to the state provider.
        StatesService.initShortUrls(window.AppConfig.shortUrls);

        // Setup current site's locale
        $rootScope.currentLocale = window.AppConfig.locale;


        // Redirect to a defined state in the route config.
        $transitions.onStart({}, function(transition) {

            // Scroll to top.
            window.scrollTo(0, 0);

            var to = transition.to();
            if (to.redirectTo) {

                // event.preventDefault();
                $timeout(function() {
                    $state.go(to.redirectTo, transition.params());
                });
            }
        });

        $transitions.onSuccess({}, function(transition) {
            $timeout(function() {
                LocaleService.updateLanguageUrls(transition.to().name);
            }, 1000);
        });

        if ($location.search().disclaimerSet === 'true') {
            DisclaimerService.setDisclaimer(true);
            $location.search('disclaimerSet', null);
        }



        ApplicationService.getSiteConfig().then(function(data) {
            $translate.use(data.locale);

            // Set translations ready state
            $timeout(function() {
                $rootScope.translationsReady = true;
                var shouldShowDisclaimer = DisclaimerService.checkShowDisclaimer();
                if (!DisclaimerService.isUserAgentBot(navigator.userAgent) &&
                    shouldShowDisclaimer) {
                    $rootScope.showDisclaimerPopup = shouldShowDisclaimer;
                }
            });

            // Remove show disclaimer class from body.
            if (!DisclaimerService.checkShowDisclaimer()) {
                $('body').removeClass('show-disclaimer');
            }
        });

        // Set up media query
        MediaQueryService.getMedia();
        MediaQueryService.reloadOnResize();

        // Setting iOS classes on load
        $rootScope.iOS = MediaQueryService.getIosClasses();
    }

    // Initially set app modules.
    angular
        .module('app.constants', []);

    angular
        .module('app.providers', []);

    angular
        .module('app.filters', []);

    angular
        .module('app.components', []);

    angular
        .module('app.directives', []);

    angular
        .module('app.services', []);

    angular
        .module('app.controllers', []);

    angular
        .module('app.templates', []);

})();
