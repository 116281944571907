// mainHeader.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('mainHeader', mainHeader);

    // Inject dependencies.
    mainHeader.$inject = [
        'BASEURL',
        'IS_BETA_VERSION',
        'MediaQueryService'
    ];

    function mainHeader(BASEURL, IS_BETA_VERSION, MediaQueryService) {
        var directive = {
            controller: controller,
            restrict: 'E',
            templateUrl: '/templates/mainHeader.html'
        };

        return directive;

        function controller($rootScope, $scope) {
            var scrollTop = 47; // mainheader height mobile
            $scope.BASEURL = BASEURL;
            $scope.isBetaVersion = IS_BETA_VERSION;

            /**
             * Get media.
             * @returns {string} Media query as string.
             */
            $scope.getMedia = function() {
                return MediaQueryService.getMedia();
            };

            /**
             * Show menu toggles a trigger css class to the body of the page.
             */
            $scope.showMenu = function() {
                $scope.$broadcast("showMenuEvent");
            };

            //Set sticky header
            $(document).on('scroll', function() {
                if ($(document).width() > 960) {
                    scrollTop = 40; // mainheader height regular
                }

                if ($(document).scrollTop() > scrollTop) {
                    $('main-header').addClass('sticky');
                } else {
                    $('main-header').removeClass('sticky');
                }
            });
        }
    }
})();
