// chartCustomSettings.js
(function() {
    'use strict';

    angular
        .module('app.constants')
        .constant('ChartCustomSettings', {
            defaultSettings: {
                categoryAxesSettingsEqualSpacing: true,
                categoryAxesSettingsTickLength: 13,
                chartCursorSettingsOneBalloonOnly: true
            }
        });
})();