// accordion.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('accordion', accordion);

    // Inject dependencies.
    accordion.$inject = ['$timeout'];

    function accordion($timeout) {
        var directive = {
            link: link,
            restrict: 'E',
            scope: {
                data: '='
            },
            templateUrl: '/templates/accordion.html'
        };
        
        return directive;
        
        function link(vm, element) {
            $timeout(function() {
                element.find('ul li').each(function(index) {
                    var entry = $(this);
                    
                    // Open first accordion entry.
                    if (!vm.data.isClosed && index === 0) {
                        entry.addClass('open');
                    }
                    
                    // Add click listener to toggle accordion entries.
                    $(this).find('header').click(function() {
                        if (!entry.hasClass('open')) {
                            element.find('ul li.open').removeClass('open');
                        }

                        entry.toggleClass('open');
                    });

                    // Download links should be opened in _self to be ignored by angular
                    $(this).find('a.download-pdf').attr('target', '_self');
                });
            });
        }
    }
})();