// groupNavigation.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('groupNavigation', groupNavigation);

    // Inject dependencies.
    groupNavigation.$inject = ['$timeout'];

    function groupNavigation($timeout) {
        var directive = {
            link: link,
            restrict: 'E',
            templateUrl: '/templates/groupNavigation.html'
        };

        return directive;

        function link(vm) {
            var groups,
                i,
                groupOffsets = [],
                offset = {
                    scroll: 215, // Space between group navigation and top of page.
                    jump: 80 // Height of sticky header.
                },
                update = true,
                holder = false, // A time holder for performance purpose;
                tweakFactor = 50, // Time between each scroll callback to be executed
                lateCheck,
                lateActiveState;

            // Get offset for all groups
            $timeout(function() {
                initOffsets();

                // Init scroll listener.
                $(document).on('scroll', onScroll);
            });
            
            /**
             * Init all offsets (should be executed after any height changes in the page)
             */
            function initOffsets() {
                groupOffsets = [];

                // Choose between tabs and single mode
                if (!vm.activeTab) {
                    groups = $('section[data-id]');
                } else {
                    groups = $('tab[index="' + vm.activeTab.index + '"] [data-id]');
                }

                for (i = 0; i < groups.length; i++) {

                    // Get calculated offset.
                    var groupOffset = calculateOffset($(groups[i]), offset.scroll);
                    
                    groupOffsets.push(groupOffset);
                }
            }
            
            /**
             * Calculate offset.
             * @param {object} group - jQuery element array of the group.
             * @param {int} offset - Default offset, scroll or jump.
             * @returns {int} - Calculated offset.
             */
            function calculateOffset(group, offset) {

                /**
                 * Check for separator and reduce offset with separator height and headline margin.
                 * Separator is in every second group.
                 * There is a margin within the headline only under tabs.
                 */
                if (group.find('separator').length > 0) {
                    var separatorHeight = group.children('separator').height();
                    offset -= separatorHeight;
                } else if (vm.activeTab) {
                    offset += 55;
                }
                
                return group.offset().top - offset;
            }
            

            // Get closest value / index
            function getCurrentIndex() {
                var documentOffset = $(document).scrollTop(),
                    closest,
                    index;
                    
                /**
                 * Set index state
                 * Top of page is index 0.
                 */
                if (documentOffset < groupOffsets[0]) {
                    index = 0;
                } else {
                    
                    /**
                     * Get closest group.
                     * jQuery closest() wont work. You will get the next group on half way, not at the end as expected.
                     */
                    var i;
                    for (i = 0; i <= groupOffsets.length; i++) {
                        
                        
                        /**
                         * Check if scroll position is lower than next group.
                         * Set one group before to stay in the correct group.
                         */
                        if (documentOffset <= groupOffsets[i]) {
                            closest = groupOffsets[i - 1];
                            break;
                        }
                        
                        // Check for the last group in the list.
                        if (i === groupOffsets.length - 1) {
                            closest = groupOffsets[i];
                            break;
                        }
                    }

                    index = groupOffsets.indexOf(closest) + 1;
                }

                return index;
            }
            
            
            /**
             * On scroll handler.
             */
            function onScroll() {
                if (holder && update) {
                    return;
                }

                holder = true;

                // Execute the desired instructions when scrolled
                $timeout(function() {
                    initOffsets();
                    updateActiveState(getCurrentIndex());
                });

                // Performance holder clearer
                setTimeout(function() {
                    holder = false;
                }, tweakFactor);
            }


            // Update active state
            function updateActiveState(index) {
                if (lateActiveState) {
                    clearTimeout(lateActiveState);
                }
                
                lateActiveState = setTimeout(function() {
                    $('group-navigation [data-group-id]').removeClass('active');
                    $('group-navigation [data-group-id="' + index + '"]').addClass('active');
                }, 100);
            }
            
            
            /**
             * Destroy directive.
             */
            vm.$on('$destroy', function() {
                $(document).off('scroll', onScroll);
            });
            

            /**
             * Jumps to instrument group
             * @param {object} group - Instrument group data
             */
            vm.jumpToGroup = function(group, $event, isSilentCheck) {
                var elOffset;

                // Stop scroll update
                update = false;

                // Jump to page header
                if (!group) {
                    elOffset = 0;
                } else if (!vm.activeTab) { // Check for single mode.

                    // Get calculated offset.
                    elOffset = calculateOffset($('[data-id="' + group.id + '"]'), offset.jump);
                } else { // Check for tabs.

                    // Get calculated offset.
                    elOffset = calculateOffset($('tab[index="' + vm.activeTab.index + '"] [data-id="' + group.id + '"]'), offset.jump);
                }

                // Animate without changing active state.
                $('body, html').animate({
                    scrollTop: elOffset
                }, 200);

                updateActiveState($($event.target).data('group-id'));

                $timeout(function() {
                    update = true;
                }, 250);
                
                
                if (lateCheck) {
                    clearTimeout(lateCheck);
                }

                lateCheck = setTimeout(function() {
                    if (!isSilentCheck) {

                        initOffsets();
                        vm.jumpToGroup(group, $event, true);

                        $timeout(function() {
                            $(document).scroll();
                        }, 50);
                    }
                }, 1000);
            };
        }
    }
})();