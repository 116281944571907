// productFilterDropdown.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('productFilterDropdown', productFilterDropdown);

    // Inject dependencies.
    productFilterDropdown.$inject = [
        '$timeout',
        'MediaQueryService'
    ];

    function productFilterDropdown(
        $timeout,
        MediaQueryService
    ) {
        var directive = {
            controller: controller,
            link: link,
            restrict: 'E',
            templateUrl: '/templates/productsFilterDropdown.html',
            scope: {
                data: '=',
                filterData: '=',
                submit: '=',
                toggle: '=',
                isRenderAllowed: '='
            }
        };

        function controller($scope) {
            $scope.changed = false;
            $scope.filterTemp = [];
            $scope.media = MediaQueryService.getMedia();

            // Submit changes
            $scope.apply = function() {
                $($scope.container).removeClass('open');
                $scope.filterData = $scope.filterTemp;
                $scope.changed = false;

                // Submit changes
                $timeout(function() {
                    $scope.submit();
                }, 5);
            };

            // Update filter data object
            $scope.change = function() {
                $scope.changed = true; // Set changed state to force submit
                synchronizeData();
            };



            // Synchronize filter with data
            function synchronizeData() {
                var field;
                $scope.filterTemp = [];

                // Get active checkboxes
                for (field in $scope.data.list) {
                    if ($scope.data.list[field].selected) {
                        $scope.filterTemp.push(field);
                    }
                }
            }

            synchronizeData();
        }

        function link(vm, element) {
            var jContainer = $(element);
            vm.container = jContainer;
            vm.cssClass = element.attr('class').split(' ')[0];

            // Submit filter if data has
            vm.$watch(function() {
                return element.attr('class');
            }, function() {
                if (vm.changed) {
                    vm.clickOutside();
                }
            });
            
            // Close on mouseleave
            vm.clickOutside = function() {

                // If something changed apply changes also on click outside
                if (vm.changed) {
                    jContainer.removeClass('open');
                    vm.apply();
                }

                jContainer.removeClass('open');
            };

            // Cancel button event
            vm.clickCancel = function() {

                // Make sure the scope is not changed to prevent re-fetching from API
                vm.changed = false;

                // Close the underlying container
                jContainer.removeClass('open');

                // Clear Selections
                var field,
                    currentSelections = [],
                    selection;

                // Get active checkboxes
                for (selection in vm.data.active) {
                    currentSelections.push(vm.data.active[selection].value);
                }

                // Uncheck only those checkboxes that were not checked previously / active
                for (field in vm.data.list) {
                    if (!currentSelections.includes(vm.data.list[field].value)){
                        vm.data.list[field].selected = false;
                    }
                }

                // Make sure our active selections still remain.
                for (selection in vm.data.active) {
                    if (currentSelections.includes(vm.data.list[selection].value)){
                        vm.data.list[selection].selected = true;
                    }
                }


            };
        }

        return directive;
    }

})();