// additionalBar.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('additionalBar', additionalBar);

    // Inject dependencies.
    additionalBar.$inject = ['MediaQueryService'];

    function additionalBar(MediaQueryService) {
        var directive = {
            controller: controller,
            restrict: 'E',
            templateUrl: '/templates/additionalBar.html'
        };
        
        return directive;
        
        function controller($scope) {
            $scope.media = MediaQueryService.getMedia();
            
            
            /**
             * Go to top of page.
             */
            $scope.gotToTop = function() {
                window.scrollTo(0, 0);
            };
        }
    }
})();