// productFilterMaturity.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('productFilterMaturity', productFilterMaturity);

    // Inject dependencies.
    productFilterMaturity.$inject = [
        '$timeout',
        'MediaQueryService'
    ];

    function productFilterMaturity(
        $timeout,
        MediaQueryService
    ) {
        var directive = {
            controller: controller,
            link: link,
            restrict: 'E',
            templateUrl: '/templates/productsFilterMaturity.html',
            scope: {
                data: '=',
                filterData: '=',
                submit: '=',
                toggle: '=',
                isRenderAllowed: '='
            }
        };

        function link(scope, element) {

            // Submit filter if data has
            scope.$watch(function() {
                return element.attr('class');
            }, function() {
                if (scope.changed) {
                    scope.clickOutside();
                }
            });
        }

        function controller($scope) {
            var container = 'product-filter-maturity',
                jContainer = $(container);

            $scope.changed = false;
            $scope.filterTemp = [];
            $scope.media = MediaQueryService.getMedia();

            // Submit changes
            function apply() {
                jContainer.removeClass('open');
                $scope.filterData = $scope.filterTemp;
                $scope.changed = false;

                // Submit changes
                $timeout(function() {
                    $scope.submit();
                }, 5);
            }

            // Update filter data object
            $scope.change = function(item) {
                var field;

                // Remove active checkboxes, behaviour of a radiobutton
                for (field in $scope.data.list) {
                    $scope.data.list[field].selected = false;
                }

                //Set current item
                item.selected = true;

                $scope.changed = true;
                synchronizeData(item);
            };

            // Update filter data with calendar inputs
            $scope.changeCalendar = function() {
                var field;

                // Remove active checkboxes
                for (field in $scope.data.list) {
                    $scope.data.list[field].selected = false;
                }

                $scope.filterTemp = {
                    start: $scope.calendar.start,
                    end: $scope.calendar.end
                };

                $scope.changed = true;
            };

            // Check for valid fields
            $scope.checkForm = function() {
                if (!jContainer.find('.ng-invalid').length) {
                    apply();
                }
            };

            // Close on mouseleave
            $scope.clickOutside = function() {

                // If something changed apply changes also on click outside
                if ($scope.changed) {
                    apply();
                }

                jContainer.removeClass('open');
            };

            // Cancel button event
            $scope.clickCancel = function() {

                // Because it is a radio therefore we know its a single value at first index
                var currentSelection = ($scope.data.active ? $scope.data.active[0].value : null),
                    field;
                
                // Make sure to specify scope is not changed
                $scope.changed = false;

                // Close the container
                jContainer.removeClass('open');

                for (field in $scope.data.list) {
                    if (currentSelection !== $scope.data.list[field].value){
                        $scope.data.list[field].selected = false;
                    } else {
                        $scope.data.list[field].selected = true;
                    }
                }
            };

            // Focus the inputfield to open calendar
            $scope.focusInput = function(inputName, event) {

                // Return on wrong target
                if (!$(event.target).hasClass('calendar-trigger')) {
                    return;
                }

                $('input[name="' + inputName + '"]').focus();
            };

            // Synchronize filter with data
            function synchronizeData(item) {
                var field;

                $scope.filterTemp = [];

                // Update calendar
                if (item && !isNaN(item.start)) {
                    $scope.calendar = {
                        start: item.start,
                        end: item.end
                    };
                } else { 
                    
                    // First load or for "open end" set default min/max values
                    $scope.calendar = {
                        start: new Date().getTime() / 1000,
                        end: $scope.data.end
                    };
                }

                // Get active checkboxes
                for (field in $scope.data.list) {
                    if ($scope.data.list[field].selected) {
                        $scope.filterTemp.push(field);
                    }
                }
            }

            synchronizeData();
        }

        return directive;
    }
})();