// modal.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('modal', modal);

    // Inject dependencies.
    modal.$inject = ['ApplicationService', 'HelperService'];

    function modal(ApplicationService, HelperService) {
        var directive = {
            link: link,
            restrict: 'E',
            templateUrl: '/templates/modal.html'
        };

        return directive;

        function link(vm, element, attr) {

            // Get current locale for sortation of language keys
            ApplicationService.getSiteConfig().then(function(data) {
                vm.currentLocale = data.locale.slice(0, 2);
            });

            // Close modal
            vm.closeModal = function() {
                if (attr.scope) {
                    vm[attr.scope].showModal = false;
                } else {
                    vm.showModal = false;
                }
            };

            // Open external file
            vm.openDocument = function(xuuid, documentType) {
                HelperService.openDocument(xuuid, documentType);
            };
        }
    }
})();