(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('personQuoteComponent', [
            function() {

                return {
                    restrict: 'E',
                    scope: {
                        name: '@?',
                        url: '@?',
                        data: '='
                    },
                    templateUrl: '/templates/personQuoteComponent.html'
                };
            }]);
})();
