(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('TabsService', TabsService);

	TabsService.$inject = ['MediaQueryService'];

    function TabsService(MediaQueryService) {
        return {

			/**
             * Calculate dimensions of navigation tabs
			 * @param vm
			 * @param element
			 */
			setNavigationDimensions: function(vm, element) {

				if (MediaQueryService.getMedia() !== 'regular') {
					var tabsElement = $(element),
						tabsNavsUl = tabsElement.find('nav > ul'),
						tabsNavsLi = tabsElement.find('nav > ul > li'),
						liWidth = 'calc(100vw)';

					if (tabsNavsLi.length === 2) {
						liWidth = 'calc(50vw - 2px)';
						tabsNavsUl.width('calc(100vw)');
					} else if (tabsNavsLi.length >= 3) {
						liWidth = 'calc(42vw - 2px)';
						tabsNavsUl.width('calc(42vw * ' + tabsNavsLi.length + ')');
					}

					tabsNavsLi.width(liWidth);
				}
			}
        };
    }
})();