//tabs.directive.js
(function() {

    'use strict';

    angular
        .module('app.directives')
        .directive('tabs', tabs);

    tabs.$inject = [
        '$state',
        '$timeout',
		'MediaQueryService',
		'TabsService'
    ];

    function tabs(
        $state, 
        $timeout,
		MediaQueryService,
		TabsService
    ) {
        var directive = {
            link: link,
            restrict: 'EA'
        };
        return directive;

        //Init
        function link(vm, element, attrs) {

            vm.index = attrs.start;
            vm.changeFunc = vm.$eval(attrs.changeFunc);

            // Set current state when no start is defined.
            if (!attrs.start) {
                vm.index = $state.current.name;
            }

            //Set start tab/nav
            $(element).find('tab[index="' + vm.index + '"]').first().show();

            var tab = $($(element).find('nav')).first().find('ul li[index="' + vm.index + '"]');
            tab.addClass('active');

            // Call url if exists.
            if (tab.find('a').length > 0 && tab.find('a').attr('ui-sref')) {
                $timeout(function() {
                    $state.go(tab.find('a').attr('ui-sref'));
                });
            }

            //set navigation
            $timeout(function() {
                setNavigation(vm, element);
				TabsService.setNavigationDimensions(vm, element);
            }, 10);

        }

        function setNavigation(vm, element) {

            var nav = $($(element).find('nav')).first().find('ul li');

            $.each(nav, function() {

                $(this).click(function() {
                    var index = $(this).attr('index'),
						position = nav.index($(this));

					// Horizontally Scroll to clicked label
					try {
						if (MediaQueryService.getMedia() !== 'regular') {
							$(this).parent().parent().animate({scrollLeft: ($(this).parent().width() / nav.length) * (position - 1) + 50}, 200);
						}
					} catch (e) {
						console.log(e);
					}

                    if (index === vm.index) {
                        return;
                    } else { //Toggle active states

                        $(element).find('ul li[index="' + vm.index + '"]').removeClass('active');
                        $(element).find('ul li[index="' + index + '"]').addClass('active');
                        $(element).find('tab[index="' + vm.index + '"]').hide();
                        vm.index = index;
                        $(element).find('tab[index="' + index + '"]').show(); //Show new tab
                    }

                    if (vm.changeFunc) { //Trigger change function
                        vm.changeFunc(index);
                    }
                });
            });
        }
    }
})();
