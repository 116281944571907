// multipleLinks.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('multipleLinks', multipleLinks);

    // Inject dependencies.
    multipleLinks.$inject = [];

    function multipleLinks() {
        var directive = {
            controller: controller,
            restrict: 'E',
            scope: {
                data: '='
            },
            templateUrl: '/templates/multipleLinks.html'
        };

        return directive;

        function controller($scope) {

            $scope.toggleTitle = function(event, set) {
                var el = angular.element(event.target);

                if (set) {  // set title
                    el.attr('title', el.attr('data-title'));
                } else {    // unset title
                    el.attr('data-title', el.attr('title'));
                    el.removeAttr('title');
                }
            };
        }
    }
})();