(function() {
    'use strict';

    angular
        .module('app.filters')
        .filter('formatNumber', formatNumber);

    function formatNumber() {
        return function(number, minPrecision, maxPrecision, decimalSeparator, thousandSeparator) {
            var floatNumber = parseFloat(number);

            // As first step the precision formatting
            var max = Number(floatNumber.toFixed(maxPrecision)),
                min = Number(floatNumber.toFixed(minPrecision)),
                diff = max - min,
                result = String(min + diff),
                numberMin = floatNumber.toFixed(minPrecision);

            if (numberMin.length > result.length) {
                result = numberMin;
            }

            var resultParts = result.split('.'),
                formattedResult = '',
                preDecimal = resultParts[0],
                
                // ThousandSeparator in preDecimal
                formattedPreDecimal = '',
                i;

            // Prefix
            if (resultParts[0].charAt(0) === '-') {
                formattedResult = '-';
                preDecimal = resultParts[0].slice(1);
            }

            for (i = (preDecimal.length - 1); i >= 0; i -= 1) {
                if (((preDecimal.length - i) % 3 === 1) && i !== preDecimal.length - 1) {
                    formattedPreDecimal = thousandSeparator + formattedPreDecimal;
                }

                formattedPreDecimal = preDecimal.charAt(i) + formattedPreDecimal;
            }

            formattedResult += formattedPreDecimal;

            // Postfix
            if (resultParts.length === 2) {
                formattedResult += decimalSeparator + resultParts[1];
            }

            return formattedResult;
        };
    }
}());