// separator.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('separator', separator);

    // Inject dependencies.
    separator.$inject = [];

    function separator() {
        var directive = {
            controller: controller,
            restrict: 'E',
            templateUrl: '/templates/separator.html',
            scope: {
                data: '='
            }
        };

        function controller($scope) {

            var cssClass = '';
            if ($scope.data && $scope.data.mobile) {
                cssClass += 'smartphone-only ';
            }

            if ($scope.data && $scope.data.tablet) {
                cssClass += 'tablet-only ';
            }

            if ($scope.data && $scope.data.desktop) {
                cssClass += 'desktop-only';
            }

            if ($scope.data && $scope.data.mobile && $scope.data.tablet && $scope.data.desktop) {
                cssClass = '';
            }

            $scope.cssClass = cssClass;
        }
        
        return directive;
    }
})();