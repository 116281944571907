//simpleTable.directive.js
(function() {

    'use strict';

    angular
        .module('app.directives')
        .directive('simpleTable', simpleTable);

    simpleTable.$inject = [
        'HelperService',
		'TooltipService'
    ];

    function simpleTable() {
        var directive = {
            controller: controller,
            templateUrl: '/templates/simpleTable.html',
            restrict: 'EA',
            scope: {
                data: '=',
                tpl: '='
            }
        };
        return directive;

        function controller($scope, HelperService, TooltipService) {

            /**
             * Apply decimal digits Min Max as requested in #62363 internal note #20
             * @param field
             * @param rawValue
             * @returns {*}
             */
            $scope.getSpecialFormat = function(field, rawValue) {
                return HelperService.getSpecialFormat(field, rawValue);
            };

            $scope.getFixedPrecision = function(field, value, precision) {
                return HelperService.getFixedPrecision(field, value, precision);
            };

            $scope.shouldRenderRow = function(data, column) {
                if (column && column.visibleIf && data[column.visibleIf] === false) {
                    return false;
                }

                if (data && data.fields && column && column.value && data.fields[column.value] && data.fields[column.value].value === '___HIDDEN___') {
                    return false;
                }

                 return true;
            };

			$scope.getTooltip = TooltipService.getTooltip;
        }

    }
})();