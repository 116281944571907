//Helper
(function() {

    'use strict';

    /* jshint ignore: start */

    String.prototype.replaceAll = function(search, replacement) {
        var target = this;
        return target.replace(new RegExp(search, 'g'), replacement);
    };

    //Search object by key 
    Array.prototype.searchObjectsByKey = function(key, value) {
        var results = [],
            i;

        for (i = 0; i < this.length; i++) {
            if (this[i][key] && this[i][key] == value) {
                results.push(this[i]);
            }
        }

        return results;
    };

    /**
     * Array prototype for finding the closes value in array
     * @param {float} num - Float
     * @return {float} closest - Closest index wihtin array
     */
    Array.prototype.closest = function(num) {
        var i = 0,
            minDiff = 1000,
            closest;

        for (i in this) {
            var m = Math.abs(num - this[i]);
            if (m < minDiff) {
                minDiff = m;
                closest = this[i];
            }
        }

        return closest;
    }

    /**
     * Description of the function.
     * this function is responsible of extract the url parameters
     * @param {String} url
     * @returns {object} array of key, values pair
     */
    window.getParamsFromUrl = function(url) {
        var result = {},
            keyValue = true,
            key = null,
            value = null,
            separators = ['/', '\\\?', '=', '&', '~2F'],
            re = new RegExp(separators.join('|'), 'g');
        url = decodeURIComponent(url);

        url.split(re).forEach(function(part) {
            if (part && part !== '') {
                if (keyValue) {
                    key = part;
                    keyValue = false;
                } else {
                    value = part;
                    keyValue = true;
                }

                if (key && value) {
                    if ((key.lastIndexOf('[]') !== -1 && key.lastIndexOf('[]') + '[]'.length === key.length)) {
                        key = key.substring(0, key.length - 2);
                        result[key] = result[key] && result[key] instanceof Array ? result[key] : [];
                        result[key].push(value);
                    } else {
                        result[key] = value;
                    }

                    key = null;
                    value = null;
                }
            }
        });

        return result;
    };

    /**
     * Sort object by key
     * @param {Object} obj – Data
     */
    window.sortObjectByKey = function(obj) {
        var key,
            tempArry = [],
            i,
            tempObj = {};

        for (key in obj) {
            tempArry.push(key);
        }

        tempArry.sort(
            function(a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            }
        );

        for (i = 0; i < tempArry.length; i++) {
            tempObj[tempArry[i]] = obj[tempArry[i]];
        }

        return tempObj;
    }

    /**
     * Load Google Tag Manager
     */
    window.loadGTM = function() {

        if (window.isProduction && !window.loadedGTM) {
            var script = '<!-- Google Tag Manager -->' +
                    '<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':' +
                    'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],' +
                    'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=' +
                    '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);' +
                    '})(window,document,\'script\',\'dataLayer\',\'GTM-55K3J5N\');</script>' +
                    '<!-- End Google Tag Manager -->',

                noscript = '<!-- Google Tag Manager (noscript) -->' +
                    '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-55K3J5N"' +
                    'height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>' +
                    '<!-- End Google Tag Manager (noscript) -->';

            $('head').append(script);
            $('body').append(noscript);
            window.loadedGTM = true;
        }

    }

    /* jshint ignore: end */

})();