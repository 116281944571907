//instrumentTablePaging.directive.js
(function() {

    'use strict';

    angular
        .module('app.directives')
        .directive('instrumentTablePaging', instrumentTablePaging);

    instrumentTablePaging.$inject = [
        'HistoryService'
    ];

    function instrumentTablePaging(HistoryService) {
        var directive = {
            controller: controller,
            restrict: 'E',
            templateUrl: '/templates/instrumentTablePaging.html',
            scope: {
                data: '=',
                changeFunc: '=',
                activeTab: '='
            }
        };
        return directive;

        function controller($scope) {

            /**
             * Builds array from a number
             * Used to generate paging pages for ng-repeat.
             * @param {integer} num - Integer.
             * @returns {array} - Array with number.
             */
            $scope.getNumberArray = function(num) {
                return new Array(num);
            };

            /**
             * Jumps to instrument group
             * @param {object} group - Instrument group data
             */
            $scope.jumpToGroup = function() {
                var elOffset;

                try {

                    // Jump to page header
                    if ($scope.activeTab){ //Get active tab and element offset
                        if ($('tab[index="' + $scope.activeTab.index + '"] [data-id="' + $scope.data.id + '"]').length) {
                            elOffset = $('tab[index="' + $scope.activeTab.index + '"] [data-id="' + $scope.data.id + '"]').offset().top - 100;
                        } else {
                            elOffset = $('[data-id="' + $scope.data.id.toString() + '"]').offset().top - 100;
                        }

                    } else {
                        elOffset = $('[data-id="' + $scope.data.id + '"]').offset().top - 100;
                    }

                    // Animate without changing active state
                    $('body, html').animate({
                        scrollTop: elOffset
                    }, 200);
                } catch (e) {
                    console.log(e);
                }
            };

            /**
             * Set new page
             * @param {any} index - String or integer
             */
            $scope.setPage = function(index) {
                try {

                    // Update current page index
                    if (index === 'next' && $scope.data.paging.page < $scope.data.paging.pages) {
                        $scope.data.paging.page++;
                    } else if (index === 'previous' && $scope.data.paging.page > 1) {
                        $scope.data.paging.page--;
                    } else if (typeof index === 'number') {
                        $scope.data.paging.page = index;
                    }

                    // Store action for history
                    HistoryService.storePagedGroup($scope.data.id, index);

                    // Trigger update function
                    $scope.changeFunc($scope.data);

                    // Jump to group
                    $scope.jumpToGroup();
                } catch (e) {
                    console.log(e);
                }
            };
        }
    }
})();