// CapitalProtectionWithParticipationSumBasket.constants.js
(function() {
    'use strict';

    angular
        .module('app.constants')
        .constant("CapitalProtectionWithParticipationSumBasket", {
            "mobile": {
                "inSubscription": [
                    {
                        label: 'valor',
                        value: 'valor',
                        notSortable: true
                    },
                    {
                        label: 'protectionLevelRelativePercent',
                        value: 'protectionLevelRelativePercent',
                        precision: 2
                    },
                    {
                        label: 'relativeStrike2ReferencePrice',
                        value: 'relativeStrike2ReferencePrice',
                        precision: 2
                    },
                    {
                        label: 'participationRatePercent',
                        value: 'participationRatePercent',
                        precision: 2
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames',
                        icon: 'settlementMethodIcon'
                    },
                    {
                        label: 'currency',
                        value: 'currency',
                        icon: 'quantoIcon'
                    },
                    {
                        label: 'maturityDate',
                        value: 'maturityDate',
                        icon: 'earlyRedemptionIcon',
                        format: 'mediumDate'
                    },
                    {
                        label: 'subscriptionPeriodEndDateMobile',
                        value: 'subscriptionPeriodEndDate',
                        format: 'customDateTime'
                    },
                    {
                        label: ' ',
                        value: 'menu',
                        align: 'right',
                        notSortable: true
                    }
                ],
                "secondaryMarket": [
                    {
                        label: 'valor',
                        value: 'valor',
                        notSortable: true
                    },
                    {
                        label: 'protectionLevelRelativePercent',
                        value: 'protectionLevelRelativePercent'
                    },
                    {
                        label: 'distance2StrikePercent',
                        value: 'distance2StrikePercent'
                    },
                    {
                        label: 'participationRatePercent',
                        value: 'participationRatePercent',
                        precision: 2
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames',
                        icon: 'settlementMethodIcon'
                    },
                    {
                        label: 'currency',
                        value: 'currency',
                        icon: 'quantoIcon'
                    },
                    {
                        label: 'maturityDate',
                        value: 'maturityDate',
                        icon: 'earlyRedemptionIcon',
                        format: 'mediumDate'
                    },
                    {
                        label: 'bid',
                        value: 'bid'
                    },
                    {
                        label: 'ask',
                        value: 'ask'
                    },
                    {
                        label: ' ',
                        value: 'menu',
                        align: 'right',
                        notSortable: true
                    }
                ]
            },
            "inSubscription": [
                {
                    label: 'protectionLevelRelativePercent',
                    value: 'protectionLevelRelativePercent',
                    precision: 2
                },
                {
                    label: 'relativeStrike2ReferencePrice',
                    value: 'relativeStrike2ReferencePrice',
                    precision: 2
                },
                {
                    label: 'participationRatePercent',
                    value: 'participationRatePercent',
                    precision: 2
                },
                {
                    label: 'underlyingNames',
                    value: 'underlyingNames',
                    icon: 'settlementMethodIcon',
                    minWidth: 200 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'currency',
                    value: 'currency',
                    icon: 'quantoIcon'
                },
                {
                    label: 'maturityDate',
                    value: 'maturityDate',
                    icon: 'earlyRedemptionIcon',
                    format: 'mediumDate'
                },
                {
                    label: 'subscriptionPeriodEndDate',
                    value: 'subscriptionPeriodEndDate',
                    format: 'customDateTime',
                    minWidth: 160 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'valor',
                    value: 'valor',
                    notSortable: true
                },
                {
                    label: ' ',
                    value: 'menu',
                    align: 'right',
                    notSortable: true
                }
            ],
            "secondaryMarket": [
                {
                    label: 'protectionLevelRelativePercent',
                    value: 'protectionLevelRelativePercent'
                },
                {
                    label: 'distance2StrikePercent',
                    value: 'distance2StrikePercent'
                },
                {
                    label: 'participationRatePercent',
                    value: 'participationRatePercent',
                    precision: 2
                },
                {
                    label: 'underlyingNames',
                    value: 'underlyingReferenceBasketComponentsNames',
                    icon: 'settlementMethodIcon',
                    minWidth: 200 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'currency',
                    value: 'currency',
                    icon: 'quantoIcon'
                },
                {
                    label: 'maturityDate',
                    value: 'maturityDate',
                    icon: 'earlyRedemptionIcon',
                    format: 'mediumDate'
                },
                {
                    label: 'subscriptionPeriodEndDate',
                    value: 'subscriptionPeriodEndDate',
                    format: 'customDateTime',
                    minWidth: 160 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'bid',
                    value: 'bid'
                },
                {
                    label: 'ask',
                    value: 'ask'
                },
                {
                    label: ' ',
                    value: 'menu',
                    align: 'right',
                    notSortable: true
                }
            ],
            "productDetail": {
                "categorization": [
                    {
                        label: 'product_category',
                        value: 'metaMetaGroupName'
                    },
                    {
                        label: 'metaGroupName',
                        value: 'metaGroupName'
                    },
                    {
                        label: 'groupName',
                        value: 'groupName'
                    }
                ],
                "lifecycle": [
                    {
                        label: 'product_detail_subscriptionPeriodEndDate',
                        value: 'subscriptionPeriodEndDate',
                        visibleIf: 'inSubscription'
                    },
                    {
                        label: 'product_detail_fixingDate',
                        value: 'fixingDate'
                    },
                    {
                        label: 'product_detail_issueDate',
                        value: 'issueDate'
                    },
                    {
                        label: 'lastTradingDate',
                        value: 'lastTradingDate'
                    },
                    {
                        label: 'product_detail_maturityDate',
                        value: 'maturityDate'
                    },
                    {
                        label: 'product_detail_redemptionDate',
                        value: 'redemptionDate'
                    },
                    {
                        label: 'days2MaturityDate',
                        value: 'days2MaturityDate',
                        precision: 0
                    }
                ],
                "staticData": [
                    {
                        label: 'issuer',
                        value: 'issuer'
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingReferenceBasketComponentsNames'
                    },
                    {
                        label: 'issuePrice',
                        value: 'issuePrice',
                        precision: 2
                    },
                    {
                        label: 'currency',
                        value: 'currency'
                    },
                    {
                        label: 'isQuanto',
                        value: 'isQuanto'
                    },
                    {
                        label: 'settlementMethod',
                        value: 'settlementMethod'
                    },
                    {
                        label: 'notionalValue',
                        value: 'notionalValue',
                        precision: 0
                    },
                    {
                        label: 'listing',
                        value: 'inListing'
                    }
                ],
                "barrierLabel": 'capitalProtectionAndStrike',
                "barrier": [
                    {
                        label: 'protectionLevelRelativePercent',
                        value: 'protectionLevelRelativePercent'
                    },
                    {
                        label: 'relativeStrike2ReferencePrice',
                        value: 'relativeStrike2ReferencePrice',
                        precision: 2
                    }
                ],
                "participationAndCap": [
                    {
                        label: 'participationRatePercent',
                        value: 'participationRatePercent'
                    },
                    {
                        label: 'capLevel',
                        value: 'static',
                        staticValue: 'false'
                    }
                ],
                "underlyings": [
                    {
                        label: 'underlying',
                        value: 'underlying'
                    },
                    {
                        label: 'price',
                        value: 'price',
                        precision: 2
                    },
                    {
                        label: 'performance_since_issue_percent',
                        value: 'performanceSinceIssuePercent'
                    },
                    {
                        label: 'strike',
                        value: 'strike',
                        precision: 2
                    }
                ],
                "pattern": [
                    {
                        label: 'performanceSinceIssuePercent',
                        value: 'performanceSinceIssuePercent'
                    },
                    {
                        label: 'underlyingPerformance2ReferencePriceRelative',
                        value: 'underlyingPerformance2ReferencePriceRelative'
                    },
                    {
                        label: 'distance2StrikePercent',
                        value: 'distance2StrikePercent'
                    }],
                "basketComponents": [ ]
            }
        });
})();