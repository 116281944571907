// TrackerCertificateOpenEnd.constants.js
(function() {
    'use strict';

    angular
        .module('app.constants')
        .constant("TrackerCertificateOpenEnd", {
            "mobile": {
                "inSubscription": [{
                    label: 'valor',
                    value: 'valor',
                    notSortable: true
                },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames',
                        icon: 'settlementMethodIcon'
                    },
                    {
                        label: 'currency',
                        value: 'currency',
                        icon: 'quantoIcon'
                    },
                    {
                        label: 'maturityDate',
                        value: 'maturityDate',
                        icon: 'earlyRedemptionIcon',
                        format: 'mediumDate'
                    },
                    {
                        label: 'subscriptionPeriodEndDateMobile',
                        value: 'subscriptionPeriodEndDate',
                        format: 'customDateTime'
                    },
                    {
                        label: ' ',
                        value: 'menu',
                        align: 'right',
                        notSortable: true
                    }
                ],
                "secondaryMarket": [
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames',
                        icon: 'settlementMethodIcon',
                        minWidth: 200 // Minimum column width in pixels in the instrument table
                    },
                    {
                        label: 'currency',
                        value: 'currency',
                        icon: 'quantoIcon'
                    },
                    {
                        label: 'maturityDate',
                        value: 'maturityDate',
                        icon: 'earlyRedemptionIcon',
                        format: 'mediumDate'
                    },
                    {
                        label: 'bid',
                        value: 'bid'
                    },
                    {
                        label: 'ask',
                        value: 'ask'
                    },
                    {
                        label: 'valor',
                        value: 'valor',
                        notSortable: true
                    },
                    {
                        label: ' ',
                        value: 'menu',
                        align: 'right',
                        notSortable: true
                    }
                ]
            },
            "inSubscription": [
                {
                    label: 'underlyingNames',
                    value: 'underlyingNames',
                    icon: 'settlementMethodIcon',
                    minWidth: 200 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'currency',
                    value: 'currency',
                    icon: 'quantoIcon'
                },
                {
                    label: 'maturityDate',
                    value: 'maturityDate',
                    icon: 'earlyRedemptionIcon',
                    format: 'mediumDate'
                },
                {
                    label: 'subscriptionPeriodEndDate',
                    value: 'subscriptionPeriodEndDate',
                    format: 'customDateTime',
                    minWidth: 160 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'valor',
                    value: 'valor',
                    notSortable: true
                },
                {
                    label: ' ',
                    value: 'menu',
                    align: 'right',
                    notSortable: true
                }
            ],
            "secondaryMarket": [
                {
                    label: 'underlyingNames',
                    value: 'underlyingNames',
                    icon: 'settlementMethodIcon',
                    minWidth: 200 // Minimum column width in pixels in the instrument table
                },
                {
                    label: 'currency',
                    value: 'currency',
                    icon: 'quantoIcon'
                },
                {
                    label: 'maturityDate',
                    value: 'maturityDate',
                    icon: 'earlyRedemptionIcon',
                    format: 'mediumDate'
                },
                {
                    label: 'bid',
                    value: 'bid'
                },
                {
                    label: 'ask',
                    value: 'ask'
                },
                {
                    label: 'valor',
                    value: 'valor',
                    notSortable: true
                },
                {
                    label: ' ',
                    value: 'menu',
                    align: 'right',
                    notSortable: true
                }
            ],
            "productDetail": {
                "categorization": [{
                    label: 'product_category',
                    value: 'metaMetaGroupName'
                },
                    {
                        label: 'metaGroupName',
                        value: 'metaGroupName'
                    },
                    {
                        label: 'groupName',
                        value: 'groupName'
                    }
                ],
                "lifecycle": [{
                    label: 'product_detail_subscriptionPeriodEndDate',
                    value: 'subscriptionPeriodEndDate',
                    visibleIf: 'inSubscription'
                },
                    {
                        label: 'product_detail_fixingDate',
                        value: 'fixingDate'
                    },
                    {
                        label: 'product_detail_issueDate',
                        value: 'issueDate'
                    },
                    {
                        label: 'product_detail_maturityDate',
                        value: 'maturityDate'
                    }
                ],
                "staticData": [
                    {
                        label: 'issuer',
                        value: 'issuer'
                    },
                    {
                        label: 'underlyingNames',
                        value: 'underlyingNames'
                    },
                    {
                        label: 'issuePrice',
                        value: 'issuePrice',
                        precision: 2
                    },
                    {
                        label: 'currency',
                        value: 'currency'
                    },
                    {
                        label: 'isQuanto',
                        value: 'isQuanto'
                    },
                    {
                        label: 'settlementMethod',
                        value: 'settlementMethod'
                    },
                    {
                        label: 'quotingMethod',
                        value: 'quotingMethod'
                    },
                    {
                        label: 'listing',
                        value: 'inListing'
                    },
                    {
                        label: 'dividendHandling',
                        value: 'dividendHandling'
                    }
                ],

                "underlyings": [
                    {
                        label: 'underlying',
                        value: 'underlying'
                    },
                    {
                        label: 'price',
                        value: 'price',
                        precision: 2
                    },
                    {
                        label: 'performance_since_issue_percent',
                        value: 'performanceSinceIssuePercent'
                    }
                ],

                "pattern": [
                    {
                        label: 'performanceSinceIssuePercentWithoutDividends',
                        value: 'performanceSinceIssuePercent'
                    },
                    {
                        label: 'performanceSinceIssuePercent',
                        value: 'performanceSinceIssuePercentWithDividends'
                    }
                ],
                "dividends": [
                    {
                    label: 'Payment_date_dividend',
                    value: 'date'
                    },
                    {
                    label: 'absolute_payment',
                    value: 'absolutePayment',
                    precision: 2
                    }
                ]
            }
        });
})();
