// appConfig.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('AppConfigService', AppConfigService);

    AppConfigService.$inject = [
        '$window'
    ];

    function AppConfigService($window) {
        var appConfig = $window && $window.AppConfig;
        return {
            getLocale: function() {
                if (appConfig && appConfig.locale) {
                    return appConfig.locale;
                }

                return '';
            },
            isInternalNetwork: function() {
                if (appConfig && appConfig.isRequestFromSolvians) {
                    return appConfig.isRequestFromSolvians;
                }
                
                return false;
            }
        };
    }
})();