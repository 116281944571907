// servicesGlossary.controller.js
(function() {
    'use strict';

    angular
        .module('app.controllers')
        .controller('ServicesGlossaryController', ServicesGlossaryController);

    // Inject dependencies.
    ServicesGlossaryController.$inject = [
        'ProfilerService',
        'GlossaryService',
        'MediaQueryService',
        '$timeout',
        '$location'
    ];

    function ServicesGlossaryController(
        ProfilerService,
        GlossaryService,
        MediaQueryService,
        $timeout,
        $location
    ) {
        var vm = this,
            container = '#glossary',
            timeoutHolder = null;
        
        vm.activeLetter = '';
        vm.alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
        
        if (MediaQueryService.getMedia() === 'mobile' || MediaQueryService.getMedia() === 'medium') {
            vm.activeLetter = 'a';
        }
        
        ProfilerService.resetProfiler();
        
        // Add loader.
        $(container).addClass('icon-loading');
        
        // Get glossary data.
        GlossaryService.get().then(function(data) {
            vm.data = data;

            /// Check if url param provided and scroll to term
            $timeout(function() {

                if ($location.search() && $location.search().term) {
                    vm.scrollToTerm(decodeURI($location.search().term));
                }
            }, 500);

        }).finally(function() {
            ProfilerService.setTimeLoad();

            // Remove loader.
            $(container).removeClass('icon-loading');
        });
        
        //Set sticky alphabet.
        if (MediaQueryService.getMedia() === 'mobile' || MediaQueryService.getMedia() === 'medium') {
            $(document).on('scroll', function() {
                if ($(document).scrollTop() > 174) {
                    $(container).find('.glossary-alphabet').addClass('sticky');
                    $(container).find('.floating-letter').addClass('sticky');
                } else {
                    $(container).find('.glossary-alphabet').removeClass('sticky');
                    $(container).find('.floating-letter').removeClass('sticky');
                }
            });
        }
        
        
        /**
         * Get media.
         * @returns {string} Media query as string.
         */
        vm.getMedia = function() {
            return MediaQueryService.getMedia();
        };
        
        
        /**
         * Filter by first letter.
         * Enable alphabetic entry.
         * @param {object} alphabetEntry - Alphabetic entry.
         * @returns {object} Row with term and description.
         */
        vm.filterByFirstLetter = function(letter) {
            return function(row) {
                if (row.term_sort.charAt(0).toLowerCase() === letter) {
                    return row;
                }
            };
        };
        
        
        /**
         * Reset active letter.
         */
        vm.resetActiveLetter = function() {
            vm.activeLetter = '';
        };

        /**
         * Handle click on button "ALL".
         */
        vm.handleTheAllButton = function() {

            // Reset active letter
            vm.activeLetter = '';

            // Reset search field.
            vm.search = '';
        };

        /**
         * Scroll to letter.
         * @param {object} $event - Alphabet entry element.
         */
        vm.scrollToLetter = function($event) {
            if ($event && $event.currentTarget.getAttribute('data-letter')) {
                var target = $event.currentTarget,
                    floatingLetter = $('.floating-letter');

                if (!$(target).hasClass('disabled')) {

                    if (timeoutHolder !== null) {
                        clearTimeout(timeoutHolder);
                    }

                    if (floatingLetter.is(':animated')) {
                        floatingLetter.stop().animate(
                            {
                                opacity: '100'
                            }
                        );
                    }

                    floatingLetter.show();
                    timeoutHolder = setTimeout(function() {
                        floatingLetter.fadeOut('slow');
                    }, 1000);

                }

                if (!$(target).hasClass('active') && !$(target).hasClass('disabled')) {
                    vm.activeLetter = target.getAttribute('data-letter');
                    
                    // Reset search field.
                    vm.search = '';
                    
                    $timeout(function() {

                        // Scroll to element and fix position because of sticky header.
                        $(container).find('.glossary-list-group[data-letter=' + target.getAttribute('data-letter') + ']')[0].scrollIntoView();
                        window.scrollBy(0, -72);
                    }, 0);
                }

            }
        };

        /**
         * Scroll to term if provided in url params
         * @param {type} termId
         * @returns {undefined}
         */
        vm.scrollToTerm = function(termId) {
            $timeout(function() {
                try {

                    // Escaping double quotes char after URL decoded
                    termId = GlossaryService.escapeStr(termId);

                    var termElem = $(container).find('li[data-term="' + termId + '"]')[0];
                    $('html, body').animate({
                        scrollTop: $(termElem).offset().top - 80
                    }, 500);
                } catch (e) {
                    console.log(e);
                }
            }, 0);
        };

        /**
         * Set active letter.
         * @param {object} $event - Alphabet entry dom node.
         */
        vm.setActiveLetter = function($event) {
            if ($event && $event.currentTarget.getAttribute('data-letter')) {
                var target = $event.currentTarget;
                
                if (!$(target).hasClass('active') && !$(target).hasClass('disabled')) {
                    vm.activeLetter = target.getAttribute('data-letter');
                
                    // Reset search field.
                    vm.search = '';
                }
            }
        };


        /**
         * For mobile, blur search field when submit with keyboard
         */
        vm.blurSearch = function() {
            $('#glossary input[type=search]').blur();
        };
    }
})();