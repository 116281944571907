(function() {
    'use strict';

    angular
        .module('app.filters')
        .filter('toArray', toArray);

    function toArray() {
        return function(input) {
            if (!input) {
                return;
            }

            if (input instanceof Array) {
                return input;
            }

            return $.map(input, function(val) {
                return val;
            });
        };
    }
}());