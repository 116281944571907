(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('imageComponent', [
            function() {

                return {
                    restrict: 'E',
                    scope: {
                        name: '@?',
                        url: '@?',
                        data: '='
                    },
                    templateUrl: '/templates/imageComponent.html',
                    link: function(scope) {

                        scope.isFullscreenOpen = false;

                        scope.showFullScreen = function() {
                            scope.isFullscreenOpen = true;
                        };

                        scope.closeFullScreen = function() {
                            scope.isFullscreenOpen = false;
                        };
                    }
                };
            }]);
})();
