// component.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('component', component);

    component.$inject = ['$compile'];

    /**
     *  This directive is responsible of replacing the component tag with the corresponding
     *  component directive tag and compile it.
     *  component information coming from the backend and injected in the cms page, AngulerJS dose
     *  not trigger the corresponding directive with out compile the element again
     *
     * @returns {{priority: number, terminal: boolean, restrict: string, compile: compile}}
     * @constructor
     */
    function component($compile) {
        var directive = {
            link: link,
            priority: 999,
            terminal: true,
            restrict: 'E',
            scope: {
                template: '@?',
                name: '@?',
                url: '@?',
                data: '@?'
            }
        };

        return directive;

        function link(vm, element) {

            var newElem = angular.element('<' + vm.template + '></' + vm.template + '>');

            newElem.attr('name', vm.name);
            newElem.attr('url', vm.url);
            newElem.attr('data', vm.data);

            element.replaceWith($compile(newElem)(vm));
        }
    }

})();
