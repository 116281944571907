// profiler.directive.js
(function() {
    'use strict';

    angular
        .module('app.directives')
        .directive('profiler', profiler);

    // Inject dependencies.
    profiler.$inject = [
        '$rootScope',
        'ProfilerService'
    ];

    function profiler() {
        var directive = {
            controller: controller,
            restrict: 'E',
            templateUrl: '/templates/profiler.html'
        };

        return directive;

        function controller(
            $scope,
            $rootScope,
            ProfilerService
        ) {

            /**
             * function to switch the profiling functionality on and off
             */
            $scope.switchProfiling = function() {
                ProfilerService.switchProfilerState();
                $scope.status = ProfilerService.getProfilerState() ? 'on' : 'off';
            };

            /**
             * ProfilerIdChange event handler,this function is handling
             * the event broadcast be profiler services
             */
            $rootScope.$on('ProfilerIdChange', function(event, data) {
                if (data) {
                    $scope.profilingEnable = true;
                }

                $scope.profilingLink = data;
            });

            /**
             * PageLoadEnd event handler,this function is handling
             * the event broadcast be profiler services
             */
            $rootScope.$on('PageLoadEnd', function(event, data) {
                $scope.loadingTime = Math.floor(data);
            });

        }
    }
})();