// productFilter.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('ProductFilterService', ProductFilterService);

    // Inject dependencies.
    ProductFilterService.$inject = [
        'HttpWrapper',
        '$q',
        '$location',
        'PAGING'
    ];

    // Init ProductFilterService.
    function ProductFilterService(
        HttpWrapper,
        $q,
        $location,
        PAGING
    ) {

        return {

            productGroups: {},
            queryUrlFull: '',
            tabIndex: 1,
            queryData: {},
            thirdLevelFilter: [
                'ask',
                'couponPercentPa',
                'distance2BarrierLevelPercent',
                'distance2ClosestBarrierLevelPercent',
                'distance2StrikePercent',
                'performance2NextObservationDatePriceCurrency',
                'maxPerformancePercentStrikeCurrency',
                'maxPerformancePercentPaPriceCurrency',
                'distance2triggerLevelRefCurrencyPercent'
            ],
            rowsPerPage: PAGING.rowsPerPage,
			debugMode: false,


            /**
             * Format slider values back to object
             * @param {object} - Filter data object.
             * @return {object} - Formatted filter object.
             */
            formatURLParamsToFilterObject: function(data) {
                var filterData = data,
                    instance = this,
                    i;

                // Create real filter object for slider values
                for (i = 0; i < instance.thirdLevelFilter.length; i++) {
                    var values = [
                        filterData.hasOwnProperty(instance.thirdLevelFilter[i] + '[start]'),
                        filterData.hasOwnProperty(instance.thirdLevelFilter[i] + '[end]')
                    ];

                    if (values[0] || values[1]) {
                        filterData[instance.thirdLevelFilter[i]] = {};
                        if (values[0]) {
                            filterData[instance.thirdLevelFilter[i]].start = filterData[instance.thirdLevelFilter[i] + '[start]'];
                            delete filterData[instance.thirdLevelFilter[i] + '[start]'];
                        }

                        if (values[1]) {
                            filterData[instance.thirdLevelFilter[i]].end = filterData[instance.thirdLevelFilter[i] + '[end]'];
                            delete filterData[instance.thirdLevelFilter[i] + '[end]'];
                        }
                    }
                }

                return filterData;
            },


            /**
             * Get list query url.
             * Include filter, tab object
             * "group" filter is removed because already set by the controller
             * @param {object} additionalParams - Additional query params (sorting)
             */
            getListQueryUrl: function(additionalParams) {
                var data,
                    instance = this,
                    url;

                data = angular.copy(instance.queryData.list);

                // Get list data and merge with filter params
                angular.extend(data, instance.queryData.filter);

                // Add instrumentgroup specific params
                if (additionalParams) {
                    angular.extend(data, additionalParams);
                }

                // Remove filter group data because already set in the url
                if (data.group) {
                    delete data.group;
                }

                // Prepare url
                url = decodeURIComponent($.param(data));
                return url;
            },


            /**
             * Get query url.
             * @param {object} data - Product filter object.
			 * @return {string} url with serialized filter parameters.
			 * @return {oject} current filter data.
             */
            getQueryURL: function(data, setURL) {
                var url,
                    instance = this;

                // Update query data
                instance.queryData.filter = angular.extend(instance.queryData.filter, data);

                // Prepare url
                url = decodeURIComponent(
                    $.param(instance.queryData.filter)
                );

                // If isset update browser url
                if (setURL) {
                    $location.search('search=' + encodeURIComponent($.param(data)));
                }

                return url;
            },

			/**
			 * Check the API filterData for active filters and remove old filters
			 * from instance.queryData.filter (which have no active in API response)
			 * This will correctly generate filter url using getQueryURL(...)
			 *
			 * @param currentFilterData
			 */
			syncWithActiveFilters: function(currentFilterData) {
                var instance = this;

                try {
					instance.log("currentFilterData", currentFilterData);
					instance.log("queryData", instance.queryData.filter);

					angular.forEach(instance.queryData.filter, function(values, key) {
						if (typeof values === 'object') {
							angular.forEach(values, function(value, subKey) {
								if (Array.isArray(values)) {
									if (!currentFilterData[key] || !currentFilterData[key].active) {
										var index;
										if (!currentFilterData[key].active || !currentFilterData[key].active[value]) {
											instance.log('Deleting instance.queryData.filter[key][value] :', key, value, instance.queryData.filter[key][value]);
											index = instance.queryData.filter[key].indexOf(value);
											instance.queryData.filter[key].splice(index, 1);

										} else if (Array.isArray(currentFilterData[key].active)) {
											var exist = false;
											angular.forEach(currentFilterData[key].active, function(entryValue) {
												if (entryValue.value === value) {
													exist = true;
												}
											});

											if (exist === false) {
												instance.log('Deleting instance.queryData.filter[key][value] :', key, value, instance.queryData.filter[key][value]);
												index = instance.queryData.filter[key].indexOf(value);
												instance.queryData.filter[key].splice(index, 1);
											}
										}
									}
								} else {
									if (!(currentFilterData[key] && currentFilterData[key].active && currentFilterData[key].active[subKey])) {
										instance.log('Deleting instance.queryData.filter[key][subKey] :', key, subKey, instance.queryData.filter[key][subKey]);
										delete instance.queryData.filter[key][subKey];
									}
								}
							});
						} else {
							instance.log("String/Num : ", values);
						}
					});
                } catch (e) {
					instance.log(e);
				}

            },

			/**
			 * Handle logging within this service
			 * Enable logs if this.debugMode is true
			 */
			log: function() {
				var instance = this;
				if (instance.debugMode) {
					console.log(arguments);
				}
			},

            /**
             * Reset all filter values
             * @param {string} type - Filter or yield matrix
             */
            reset: function(type) {
                var deferred = $q.defer(),
                    instance = this;

                // Set default query data
                instance.setQueryData(type);

                deferred.resolve();
                return deferred.promise;
            },


            /**
             * Remove active filter value.
             * @param {object} filterData - Current filter object.
             * @param {string} type - Type of active filter tag.
             * @param {string} value - Value of active filter tag.
             * @returns {promise}
             */
            removeActiveFilterValue: function(filterData, type, value) {
                var deferred = $q.defer(),
                    filterDataType = filterData[type],
                    index;

                // Check for filter type.
                switch (type) {
                    case 'underlying':
                    case 'group':
                    case 'currency':
                    case 'barrierObservationMethod':
                    case 'distanceRange2BarrierLevel':

                        // Check if only one value issset (string) or multiple values (object/array)
                        if (typeof filterDataType === 'string') {
                            delete filterData[type];
                        } else {
                            index = filterDataType.indexOf(value.toString());
                            filterDataType.splice(index, 1);
                        }

                        break;
                    case 'maturityDate':

                        // For manually entered date, delete whole active key
                        if (value === 'start') {
                            filterData.maturityDate = [];
                            delete filterData[type];
                            delete filterData['maturityDate[start]'];
                            delete filterData['maturityDate[end]'];
                        } else {
                            index = filterDataType.indexOf(value.toString());
                            filterDataType.splice(index, 1);
                        }

                        break;
                    case 'ask':
                    case 'couponPercentPa':
                    case 'distance2BarrierLevelPercent':
                    case 'distance2ClosestBarrierLevelPercent':
                    case 'distance2StrikePercent':
                    case 'maxPerformancePercentStrikeCurrency':
                    case 'performance2NextObservationDatePriceCurrency':
                    case 'maxPerformancePercentPaPriceCurrency':
                    case 'distance2triggerLevelRefCurrencyPercent':
                        delete filterData[type][value];
                        break;
                    default:
                        filterDataType.list[value].selected = false;
                        break;
                }

                deferred.resolve(filterData);
                return deferred.promise;
            },


            /**
             * Set paging
             * @param {object} - Set or update group paging.
             * @return {object} - Updated paging object.
             */
            setPaging: function(tabs) {
                var instance = this,
                    tab,
                    i;

                // For tabs
                if (tabs) {
                    for (tab in tabs) {

                        // For groups in tabs
                        if (tabs[tab] && tabs[tab].groups && tabs[tab].groups.length) {
                            for (i = 0; i < tabs[tab].groups.length; i++) {
                                var paging = {
                                    page: 1,
                                    rowsPerPage: instance.rowsPerPage
                                };

                                // Set instrumnent count
                                paging.count = tabs[tab].groups[i].count;

                                // Calculate pages
                                paging.pages = Math.ceil(paging.count / instance.rowsPerPage);

                                // Set intital paging object
                                tabs[tab].groups[i].paging = paging;
                            }
                        }
                    }
                }
            },


            /**
             * Set query data defaults
             * @param {string} type - Filter or yield matrix
             */
            setQueryData: function(type) {
                var instance = this;
                if (type === 'filter') {
                    instance.queryData = {
                        list: {
                            inSubscription: 0
                        },
                        filter: {}
                    };
                } else {
                    instance.queryData = {
                        list: {},
                        filter: {}
                    };
                }
            },


            /**
             * Submit product filter values.
             * @param {object} form - Product filter values.
             */
            submit: function(url, filterValuesQuery) {
                var deferred = $q.defer(),
					instance = this;

                // Add filter params if exists
                if (filterValuesQuery) {
                    url += '?' + filterValuesQuery;
                }

                HttpWrapper({
                        method: 'GET',
                        url: url,
                        refreshProfiler: true
                    },
                    function(response) {

                		// Synchronize query data with actually active filters
						instance.syncWithActiveFilters(response.filters);

                        deferred.resolve(response);
                    },
                    function(rejection) {
                        deferred.reject(rejection);
                    }
                );

                return deferred.promise;
            }
        };
    }
})();