//helper.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('HelperService', HelperService);

    HelperService.$inject = [
        'SpecialFormatMinMaxFields',
        '$q'
    ];

    function HelperService(SpecialFormatMinMaxFields, $q) {

        /**
         * Check if field is in SpecialFormat fields and return formattedValue of FALSE if not
         * @param field
         * @param rawValue
         * @returns {*}
         */
        this.getSpecialFormat = function(field, rawValue) {

            try {
                if (SpecialFormatMinMaxFields.fields && SpecialFormatMinMaxFields.fields.length > 0 && SpecialFormatMinMaxFields.fields.indexOf(field) > -1) {
                    return this.formatWithPrecisionMinMax(rawValue);
                }
            } catch (e) {
                console.log(e);
            }

            return false;
        };

        /**
         * Format number to decimal digits between Min and Max
         * @param value
         * @param minDecimals
         * @param maxDecimals
         * @returns {*}
         */
        this.formatWithPrecisionMinMax = function(value, minDecimals, maxDecimals) {

            if (typeof minDecimals === 'undefined') {
                minDecimals = SpecialFormatMinMaxFields.minDecimals;
            }

            if (typeof maxDecimals === 'undefined') {
                maxDecimals = SpecialFormatMinMaxFields.maxDecimals;
            }

            value = Number(Number(value).toFixed(maxDecimals)); // The decimal precision can go maximum up till 4 digits therefore whatever the incoming value, we set the precision to max

            var digitsCount = this.countDecimalDigits(value);


            // Decimal digits should be minimum defaultMin and maximum defaultMax
            if (digitsCount <= minDecimals) {
                return Number(value).toFixed(minDecimals);
            } else {
                if (digitsCount > maxDecimals){
                    return Number(value).toFixed(maxDecimals);
                } else {
                    return Number(value).toFixed(digitsCount);
                }

            }
        };

        /**
         * Count decimal digits
         * @param {type} number
         * @returns {Number}
         */
        this.countDecimalDigits = function(number) {
            try {
                if (!number || Math.floor(number.valueOf()) === number.valueOf()) {
                    return 0;
                }

                return number.toString().split(".")[1].length || 0;

            } catch (e) {
                return 0;
            }
        };

        /**
         * Helper method to open a url in a new window
         * @param xuuid
         * @param documentType
         */
        this.openDocument = function(xuuid, documentType) {
            switch (documentType) {
                case 'kid':
                    window.open(xuuid);
                    break;
                default:
                    window.open('/filedb/deliver/xuuid/' + xuuid);
            }
        };

        /**
         * Get Fixed Precision
         * @param field
         * @param value
         * @param precision
         * @returns {*}
         */
        this.getFixedPrecision = function(field, value, precision) {
            try {

                    var val = parseFloat(value).toFixed(precision);
                    return this.getSpecialFormat(field, val);

            } catch (e) {
                console.log(e);
                return 0;
            }
        };

        /**
         * Convert SVG to Canvas then to PNG
         * @param jSvg - jQuery SVG Element
         * @return Promise
         * @documentation: https://canvg.github.io/canvg/index.html
         */
        this.getPngFromSvg = function(jSvg) {
            var dfd = $q.defer();

            try {
                var svg = jSvg.get(0),
                    canvas = document.createElement('canvas'),
                    svgSize = svg.getBBox();

                canvas.width = svgSize.width;
                canvas.height = svgSize.height;

                var ctx = canvas.getContext('2d'),
                    canvgInstance = window.canvg.Canvg.fromString(ctx, svg.parentNode.innerHTML.trim(), {
                    ignoreMouse: true,
                    ignoreAnimation: true
                });

                canvgInstance.render().then(function() {
                    var dataURL = canvas.toDataURL('image/png'),
                        data = atob(dataURL.substring('data:image/png;base64,'.length)),
                        asArray = new Uint8Array(data.length);

                    for (var i = 0, len = data.length; i < len; ++i) {
                        asArray[i] = data.charCodeAt(i);
                    }

                    var blob = new Blob([asArray.buffer], {type: 'image/png'}),
                        reader = new FileReader();
                    reader.readAsDataURL(blob);
                    reader.onloadend = function() {
                        dfd.resolve(reader.result);
                    };
                }, function(ex) {
                    dfd.reject(ex);
                    console.log(ex);
                });



            } catch (e) {
                dfd.reject(e);
                console.log(e);
            }

            return dfd.promise;
        };

        /**
         * Convert Hex to Rgb value
         * @param hex - Hex value for example: #007db2
         * @return {*} or null
         */
        this.hexToRgb = function(hex) {

            // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
            var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, function(m, r, g, b) {
                return r + r + g + g + b + b;
            });

            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        };

        return this;
    }
})();