// lightbox.controller.js
(function() {
    'use strict';

    angular
        .module('app.controllers')
        .controller('LightboxController', LightboxController);

    // Inject dependencies.
    LightboxController.$inject = ['$state', '$scope', '$timeout'];

    function LightboxController($state, $scope, $timeout) {
        var vm = this;
        
        /**
         * Set lightbox title.
         * Based on current state name.
         * lightbox.contact => contact.
         */
        vm.title = $state.current.name.split('lightbox.')[1];
        
        // Set css class to hide page scroller.
        if (!$('body').hasClass('show-lightbox')) {
            $('body').addClass('show-lightbox');
        }
        
        
        /**
         * Close lightbox.
         */
        function close() {
            $(document).off('keyup');
            
            if (history.length === 1) {
                $timeout(function() {
                    $state.go('home');
                });
            } else {
                history.back();
            }
            
            // Remove css class to show page scroller.
            $('body').removeClass('show-lightbox');
        }
        
        vm.close = close;
        
        $scope.$on("$destroy", function() {
            $timeout(function() {

                // Remove css class to show page scroller.
                $('body').removeClass('show-lightbox');
            });
        });
    }
})();