(function() {

    'use strict';

    angular.module('app.services')
        .factory('ChartGuidesService', ChartGuidesService);

    ChartGuidesService.$inject = [];

    function ChartGuidesService() {
        return {
            
            /**
             * Set value guide
             * @param obj {Object}
             */
            setGuide: function(obj) {
                var guide = new AmCharts.Guide();
                guide.balloonColor = obj.balloonColor;
                guide.color = obj.color;
                guide.dashLength = obj.dashLength;
                guide.fontFamily = obj.fontFamily;
                guide.fontSize = obj.fontSize;
                guide.inside = obj.inside;
                guide.label = obj.label;
                guide.lineAlpha = 1;
                guide.lineColor = obj.color;
                guide.lineThickness = obj.lineThickness;
                guide.offset = obj.offset;
                guide.padding = obj.padding;
                guide.position = obj.position;
                guide.showTriangle = obj.showTriangle;
                guide.textAlign = obj.textAlign;
                guide.value = obj.data;
                guide.width = obj.width;
                obj.axes.addGuide(guide);
                return true;
            }
        };
    }
})();