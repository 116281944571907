var lang_en_CH = {
    "1D": "1D",
    "1M": "1M",
    "1Y": "1Y",
    "3M": "3M",
    "6M": "6M",
    "30Y": "MAX",
    "abort": "Refuse",
    "accept_legal_information": "I accept the Structured Products legal disclaimers.",
    "accept": "Accept",
    "access_restriction": "Limited access",
    "accruedInterestPriCurPercent": "Accrued interest",
    "active_filter": "Active Filters",
    "adjust": "Adjust",
    "filter_count_mobile": "Adjust filter",
    "advanced_filters": "Additional Filters",
    "all": "All",
    "annually": "Annually",
    "apply": "Apply",
    "ask_criteria": "Ask",
    "ask": "Ask",
    "mainmenu": "Main menu",
    "at_expiry": "At expiry",
    "Autocallable_SoftCallable_MultiBarrierReverseConvertible": "Autocallable / Softcallable <br/>Multi Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertible": "Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibles": "Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibleWithConditionalCoupon": "Autocallable Barrier Reverse Convertible with Conditional Coupon",
    "AutocallableBarrierReverseConvertiblesWithConditionalCoupon": "Autocallable Barrier Reverse Convertible with Conditional Coupon",
    "AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Barrier Reverse Convertible with Conditional Memory Coupon",
    "AutocallableBarrierReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Barrier Reverse Convertible with Conditional Memory Coupon",
    "AutocallableMultiBarrierReverseConvertible": "Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibles": "Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible with Conditional Coupon",
    "AutocallableMultiBarrierReverseConvertiblesWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible with Conditional Coupon",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Multi Barrier Reverse Convertible with Conditional Memory Coupon",
    "AutocallableMultiBarrierReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Multi Barrier Reverse Convertible with Conditional Memory Coupon",
    "AutocallableMultiReverseConvertible": "Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibles": "Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibleWithConditionalCoupon": "Autocallable Multi Reverse Convertible with Conditional Coupon",
    "AutocallableMultiReverseConvertiblesWithConditionalCoupon": "Autocallable Multi Reverse Convertible with Conditional Coupon",
    "AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Multi Reverse Convertible with Conditional Memory Coupon",
    "AutocallableMultiReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Multi Reverse Convertible with Conditional Memory Coupon",
    "AutocallableReverseConvertible": "Autocallable Reverse Convertible",
    "AutocallableReverseConvertibles": "Autocallable Reverse Convertible",
    "AutocallableReverseConvertibleWithConditionalCoupon": "Autocallable Reverse Convertible with Conditional Coupon",
    "AutocallableReverseConvertibleWithConditionalCoupons": "AutocallableReverseConvertibleWithConditionalCoupons",
    "AutocallableReverseConvertiblesWithConditionalCoupon": "Autocallable Reverse Convertible with Conditional Coupon",
    "AutocallableReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Reverse Convertible with Conditional Memory Coupon",
    "AutocallableReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Reverse Convertible with Conditional Memory Coupon",
    "back": "Back",
    "barrier_too_close_multi_notification": "The Worst Performing Underlying is approaching its Barrier Level (distance <5.00%).",
    "show_alternative_products": "Show alternative products",
    "barrier_too_close_single_notification": "The Underlying is approaching its Barrier Level (distance <5.00%).",
    "barrier": "Barrier",
    "barrierHitProbability2MaturityPercent": "Barrier Hit Probability",
    "barrierLevelAbsoluteReferenceCurrency": "Barrier",
    "barrierLevelRelativePercent": "Barrier Level",
    "barrierObservationMethod": "Barrier type",
    "BarrierReverseConvertible": "Barrier Reverse Convertible",
    "BarrierReverseConvertibles": "Barrier Reverse Convertible",
    "barrierTouched_criteria": "Barrier touched",
    "barrierTouched": "Barrier touched",
    "betaVersion": "Beta Version",
    "betaVersionText": "Beta version with limited functionalities",
    "bi-monthly": "Every 2 months",
    "bi-weekly": "Biweekly",
    "bid": "Bid",
    "BonusCertificate": "Bonus Certificate",
    "TrackerCertificates": "Tracker Certificate",
    "TrackerCertificate": "Tracker Certificate (static)",
    "TrackerCertificateName": "LUKB Tracker Certificate",
    "TrackerCertificateCategory": "SSPA Category: 1300 - Participation",
    "TrackerCertificateOpenEnd": "Tracker Certificate Open End (static)",
    "TrackerCertificateOpenEndName": "LUKB Tracker Certificate",
    "TrackerCertificateOpenEndCategory": "SSPA Category: 1300 - Participation",
    "TemporaryProduct": "Temporary Product",
    "browser_title_product_detail": "Product page",
    "call": "Call us",
    "cancel": "Cancel",
    "CapitalProtectionCertificatesWithParticipation": "Capital Protected Certificate with Participation",
    "CapitalProtectionCertificateWithParticipation": "Capital Protected Certificate with Participation",
    "CapitalProtectionCertificateWithParticipationWorstOf": "Capital Protected Certificate with Participation on worst of",
    "CapitalProtectionCertificatesWithParticipationWorstOf": "Capital Protected Certificate with Participation on worst of",
    "CapitalProtectionProduct": "Capital Protected Certificate",
    "CapitalProtectionProducts": "Capital Protected Certificate",
    "CapitalProtections": "Capital protection",
    "CapitalProtection": "Capital protection",
    "CapitalProtectionWithParticipation": "Capital Protection Note with Participation",
    "CapitalProtectionWithParticipationWorstOf": "Capital Protection Note with Participation on worst of",
    "CapitalProtectionWithParticipationCapped": "Capital Protection Note with Capped Participation",
    "CapitalProtectionWithParticipationSumBasket": "Capital Protection Note with Participation on",
    "CapitalProtectionWithParticipationSumBasket_SPPNameGeneration": "Capital Protection Note with Participation on",
    "CapitalProtectionWithParticipationCappedSumBasket": "Capital Protection Note with Capped Participation on",
    "CapitalProtectionWithParticipationCappedSumBasket_SPPNameGeneration": "Capital Protection Note with Capped Participation on",
    "CapitalProtectionWithConditionalCouponWorstOf": "Capital Protection Note with Conditional Coupon",
    "CapitalProtectionWithConditionalCouponWorstOf_SPPNameGeneration": "Capital Protection Note with %s Conditional Coupon",
    "CapitalProtectionWithConditionalMemoryCouponWorstOf_SPPNameGeneration": "Capital Protection Note with %s Conditional Memory Coupon",
    "cash_settlement": "Cash settlement",
    "cash": "Cash settlement",
    "cashorphysical": "Cash or physical delivery",
    "categorization": "Categorization",
    "change_criteria": "Change criteria",
    "session_close": "Daily close",
    "chart_disclaimer_BarrierReverseConvertible": "Product performance, max. yield at expiry, max. yield p.a. and barrier hit probability are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_DiscountCertificate": "Product performance, max. yield at expiry and max. yield p.a. are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price.",
    "chart_disclaimer_AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon": "Product performance, max. yield at expiry, max. yield p.a. and barrier hit probability are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_AutocallableBarrierReverseConvertibleWithConditionalCoupon": "Product performance, max. yield at expiry, max. yield p.a. and barrier hit probability are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_MultiBarrierReverseConvertible": "Product performance, max. yield at expiry, max. yield p.a. and barrier hit probability are based on current prices.<br/>Worst underlying performance, worst performing underlying and underlyings prices are based on previous day's closing prices.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon": "Product performance, max. yield at expiry, max. yield p.a. and barrier hit probability are based on current prices.<br/>Worst underlying performance, worst performing underlying and underlyings prices are based on previous day's closing prices.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon": "Product performance, max. yield at expiry, max. yield p.a. and barrier hit probability are based on current prices.<br/>Worst underlying performance, worst performing underlying and underlyings prices are based on previous day's closing prices.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_AutocallableBarrierReverseConvertible": "Product performance, max. yield at expiry, max. yield p.a., yield to call and barrier hit probability are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_SoftCallableBarrierReverseConvertible": "Product performance, max. yield at expiry, max. yield p.a., yield to call and barrier hit probability are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertible": "Product performance, max. yield at expiry, max. yield p.a., yield to call and barrier hit probability are based on current prices.<br/>Worst underlying performance, worst performing underlying and underlyings prices are based on previous day's closing prices.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_SoftCallableMultiBarrierReverseConvertible": "Product performance, max. yield at expiry, max. yield p.a., yield to call and barrier hit probability are based on current prices.<br/>Worst underlying performance, worst performing underlying and underlyings prices are based on previous day's closing prices.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_ReverseConvertible": "Product performance, max. yield at expiry and max. yield p.a. are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_AutocallableReverseConvertibleWithConditionalMemoryCoupon": "Product performance, max. yield at expiry and max. yield p.a. are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_AutocallableReverseConvertiblesWithConditionalCoupon": "Product performance, max. yield at expiry and max. yield p.a. are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_MultiReverseConvertible": "Product performance, max. yield at expiry and max. yield p.a. are based on current prices.<br/>Worst underlying performance, worst performing underlying and underlyings prices are based on previous day's closing prices.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon": "Product performance, max. yield at expiry and max. yield p.a. are based on current prices.<br/>Worst underlying performance, worst performing underlying and underlyings prices are based on previous day's closing prices.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_AutocallableMultiReverseConvertibleWithConditionalCoupon": "Product performance, max. yield at expiry and max. yield p.a. are based on current prices.<br/>Worst underlying performance, worst performing underlying and underlyings prices are based on previous day's closing prices.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_AutocallableReverseConvertible": "Product performance, max. yield at expiry, max. yield p.a. and yield to call are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_SoftCallableReverseConvertible": "Product performance, max. yield at expiry, max. yield p.a. and yield to call are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_AutocallableMultiReverseConvertible": "Product performance, max. yield at expiry, max. yield p.a. and yield to call are based on current prices.<br/>Worst underlying performance, worst performing underlying and underlyings prices are based on previous day's closing prices.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_SoftCallableMultiReverseConvertible": "Product performance, max. yield at expiry, max. yield p.a. and yield to call are based on current prices.<br/>Worst underlying performance, worst performing underlying and underlyings prices are based on previous day's closing prices.<br/>The key indicator \"Product performance since issuance\" takes into account both the price development of the product and the coupons paid out since issuance.",
    "chart_disclaimer_CapitalProtectionWithParticipation": "Product performance is based on current prices.<br/>Underlying performance, distance to strike and underlying price are based on previous day's closing price.",
    "chart_disclaimer_CapitalProtectionWithParticipationCapped": "Product performance is based on current prices.<br/>Underlying performance, distance to strike and underlying price are based on previous day's closing price.",
    "chart_disclaimer_CapitalProtectionWithParticipationCappedSumBasket": "Product performance is based on current prices.<br/>Underlying performance, distance to strike and underlying price are based on previous day's closing price.",
    "chart_disclaimer_CapitalProtectionWithParticipationWorstOf": "Product performance is based on current prices.<br/>Worst underlying performance, worst performing underlying, distance to strike and underlyings prices are based on previous day's closing prices.",
    "chart_disclaimer_CapitalProtectionWithParticipationCappedWorstOf": "Product performance is based on current prices.<br/>Worst underlying performance, worst performing underlying, distance to strike and underlyings prices are based on previous day's closing prices.",
    "chart_disclaimer_TrackerCertificate": "Product performance is based on current prices. Underlyings price are based on previous day's closing price.",
    "chart_disclaimer_ActivelyManagedTrackerCertificate": "Product performance is based on current prices.",
    "chart_disclaimer_CapitalProtectionWithParticipationSumBasket": "Product performance is based on current prices.<br/>Underlying performance, distance to strike and underlying price are based on previous day's closing price.",
    "chart_disclaimer_CapitalProtectionWithConditionalCouponWorstOf": "Product performance is based on current prices.<br/>Worst underlying performance, worst performing underlying, distance to strike and underlyings prices are based on previous day's closing prices.",
    "chart_disclaimer_CapitalProtectionWithConditionalMemoryCouponWorstOf": "Product performance is based on current prices.<br/>Worst underlying performance, worst performing underlying, distance to strike and underlyings prices are based on previous day's closing prices.",
    "conditionalCouponPercentPa": "Coupon p.a.",
    "confirm_residences": "I confirm that my domicile is in Switzerland.",
    "contact_person": "Contact persons",
    "contact": "Contacts",
    "continuous": "continuous",
    "couponInterest": "Coupon",
    "couponPaymentDate": "Payment Date",
    "couponPaymentDateAndInterest": "Coupon payment dates",
    "couponPaymentFrequency": "Coupon frequency",
    "couponPercentPa": "Coupon p.a.",
    "couponPercentPa_criteria": "Coupon p.a. %",
    "coupons": "Coupon",
    "create_pdf_from_site": "Create PDF of this page",
    "criteria": "Criteria",
    "currency": "Currency",
    "daily_close_prices": "Daily Close",
    "daily": "Daily",
    "days2MaturityDate": "Remaining time to final fixing in days",
    "disclaimer_footer": "If you are domiciled in another country, you are not authorised to access information about Structured Products issued by LUKB. In this case please interrupt the process.",
    "disclaimer_text": "In order to be able to access the product information, you must meet the following conditions:",
    "DiscountCertificate": "Discount Certificate",
    "display_all_underlyings": "Show all underlyings",
    "display_all": "Show all",
    "display_all_products": "Show all products",
    "distance2BarrierLevelPercent": "Distance Barrier",
    "distance2BarrierLevelPercent_criteria": "Distance Barrier %",
    "distance2ClosestBarrierLevelPercent": "Distance Barrier",
    "distance2StrikePercent": "Distance Strike",
    "distance2StrikePercent_criteria": "Distance Strike %",
    "distance2triggerLevelRefCurrencyPercent": "Distance Autocall",
    "distance2triggerLevelRefCurrencyPercent_criteria": "Distance Autocall %",
    "distanceRange2BarrierLevel": "Distance Barrier",
    "download": "Download",
    "downloads": "Downloads",
    "dr": "Dr.",
    "early_redemption": "Early Redemption",
    "earlyRedemptionList": "Early Redemption",
    "email_address": "E-Mail address",
    "error_email": "Please insert a valid E-Mail address.",
    "error_full_name": "Please insert a name and surname.",
    "error_message": "Please insert your message.",
    "error_phone": "Please insert a phone number.",
    "error_salutation": "Please select a form of address.",
    "every": "each",
    "factsheet_dl": "Download Factsheet",
    "factsheet": "Factsheet",
    "false": "No",
    "filter": "Filter",
    "filter_barrierObservationMethod": "Barrier type",
    "filter_currency": "Currency",
    "filter_distanceRange2BarrierLevel": "Risk buffer",
    "filter_group": "Product group / Product type",
    "filter_maturityDate": "Final Fixing Date",
    "filter_products": "Filter products",
    "filter_underlying": "Underlying",
    "first_last_name": "Name and Surname",
    "found_products": "Found Products",
    "glossary_disclaimer": "*For products with several underlyings it takes into account the worst performing underlying",
    "glossary": "Glossary",
    "group_names": "Product type",
    "groupName": "Product type",
    "hasTouchedBarrier": "Barrier touched",
    "header_structured_products": "Structured Products",
    "hide_all_underlyings": "Hide all underlyings",
    "id_ex_swx_exists_criteria": "EX SWX ID exists",
    "id_ex_swx_exists": "EX SWX ID exists",
    "in_subscription_notification": "This Product is in subscription until {{subscriptionPeriodEndDate}}, {{subscriptionPeriodEndDateTime}} CET.",
    "inListing_criteria": "Listed product",
    "inListing": "Listed products",
    "isin": "ISIN",
    "isQuanto": "Quanto",
    "issued_products": "Issued Products",
    "issuePrice": "Issue price",
    "issuer": "Issuer",
    "issuerCallNoticePeriodEndDate": "Observation Date",
    "key_figures": "Key Indicators",
    "lang_DE": "German",
    "lang_EN": "English",
    "lang_FR": "French",
    "lang_IT": "Italian",
    "lastTradingDate": "Last trading date",
    "less_then_one_month": "< 1 month",
    "lifecycle": "Life Cycle",
    "light_footer_title": "We are here for you",
    "listing": "Listing",
    "market_data_source": "Market data is provided by Morningstar and <a href='http://www.solvians.com' title='Solvians' target='_blank'>Solvians</a>.",
    "maturityDate": "Final Fixing Date",
    "max_performance_too_low_notification": "This Product is approaching the maximum yield (<1.50%).",
    "max": "Max.",
    "MAX": "Max.",
    "maxPerformancePercentPaPriceCurrency": "Max. Yield p.a.",
    "maxPerformancePercentPaPriceCurrency_criteria": "Max. Yield p.a. %",
    "maxPerformancePercentPaStrikeCurrency": "Conditional Memory Coupon p.a.",
    "maxPerformancePercentStrikeCurrency_criteria": "Max. Yield at expiry %",
    "maxPerformancePercentStrikeCurrency": "Max. Yield at expiry",
    "maxPerformancePercentPriceCurrency": "Max. Yield at expiry",
    "distance2CapPercentStrikeCurrency_criteria": "Distance Cap %",
    "distance2CapPercentStrikeCurrency": "Distance Cap",
    "distance2CapAbsoluteStrikeCurrency_level": "Distance Cap Level",
    "relativeCapStrCur2ReferencePrice_criteria": "Cap Level in %",
    "relativeCapStrCur2ReferencePrice": "Cap Level",
    "reference_price_absolute": "Initial Fixing",
    "discount": "Discount",
    "strikeLevelAndDiscount": "Cap Level and Discount",
    "metaGroup": "Product group",
    "metaGroupName": "Product group",
    "min": "Min.",
    "month": "month",
    "monthly": "monthly",
    "months_dat": "months",
    "months": "months",
    "more_then_2_years": "> 2 years",
    "mr": "Mr.",
    "ms": "Mrs.",
    "MultiBarrierReverseConvertible": "Multi Barrier Reverse Convertible",
    "MultiBarrierReverseConvertibles": "Multi Barrier Reverse Convertible",
    "MultiReverseConvertible": "Multi Reverse Convertible",
    "MultiReverseConvertibles": "Multi Reverse Convertible",
    "Max_Yeild_At_Expiry": "Max. Yield at expiry",
    "name_term": "Name, definition...",
    "no_document_available": "No document available",
    "no_results": "No results",
    "no_search_results": "No search results",
    "no": "No",
    "notification_link": "Here can you find a selection of products.",
    "notionalValue": "Nominal",
    "observationDate": "Observation Date",
    "observationDateTrim": "Observation Date",
    "on": "on",
    "one_to_three_months": "1 - 3 months",
    "one_to_two_years": "1 - 2 years",
    "onlyQuotedProducts": "Show only listed products",
    "open_end": "Open-end",
    "participationRatePercent": "Participation",
    "Participations": "Participation",
    "pdf_create": "Product Report",
    "performance_since_issue_percent": "Performance since issuance",
    "performance": "Performance",
    "performance2NextObservationDatePriceCurrency_criteria": "Yield to Call %",
    "performance2NextObservationDatePriceCurrency": "Yield to Call",
    "performanceSinceIssuePercent": "Product performance since issuance",
    "distance2StrikePercentForWorstPerformanceUnderlying": "Distance to Strike worst performing Underlying",
    "phone_by_day": "Phone number (during the day)",
    "phone": "Phone",
    "physical_settlement": "Physical ETF delivery",
    "physical": "Physical delivery",
    "price": "Price",
    "product_barrier_observed_on_expiry": "Barrier observation at expiry",
    "product_cash_settlement": "Cash settlement",
    "product_category": "Product category",
    "capitalProtectionAndStrike": "Capital Protection Level and Strike Level",
    "participationAndCap": "Participation and Cap Level",
    "barrierAndStrike": "Barrier and Strike Level",
    "product_detail_fixingDate": "Initial fixing date",
    "product_detail_issueDate": "Issue Date",
    "product_detail_maturityDate": "Final fixing date",
    "product_detail_redemptionDate": "Redemption Date",
    "product_detail_subscriptionPeriodEndDate": "Subscription end date",
    "product_early_redemption_option": "Early Redemption feature",
    "product_group_product_type": "Product group / Product type",
    "product_overview_performance_key_indicators": "Product Overview, Performance and Key Indicators",
    "product_overview": "Product Overview",
    "product_physical_delivery": "Physical ETF delivery",
    "product_quanto": "The Product is protected against exchange rate risk",
    "product_type_criteria": "Product group / Product type",
    "product_type_search_placeholder": "Product group / Product type…",
    "product_type": "Product type",
    "product_underlying_search_placeholder": "Name, definition...",
    "product": "Product",
    "products_documents": "Show product details",
    "products_in_subscription_of_lukb": "Products of Luzerner Kantonalbank in subscription",
    "products_in_subscription": "Products in subscription",
    "products_range_0": "3 months",
    "products_range_1": "6 months",
    "products_range_2": "9 months",
    "products_range_3": "1 year",
    "products_range_4": "18 months",
    "products_range_5": "> 18 months",
    "products": "Products",
    "protectionLevelRelativePercent": "Capital Protection Level",
    "quanto": "Quanto",
    "quarterly": "quarterly",
    "quotingMethod": "Quoting",
    "clean": "Clean",
    "dirty": "Dirty",
    "realPriceCurrency": "Currency",
    "redemptionDate": "Final Fixing Date",
    "relativeStrike2ReferencePrice": "Strike Level",
    "repayment": "Early Redemption feature",
    "repaymentLevelPriCurPercent": "Next Autocall observation date",
    "repaymentLevelPriCurPercentSoftCallable": "Next observation date",
    "repaymentValue": "Redemption",
    "reset_all_filter": "Delete all filters",
    "ReverseConvertible": "Reverse Convertible",
    "ReverseConvertibles": "Reverse Convertible",
    "salutation": "Form of address",
    "search_groups_on": " on",
    "search_placeholder": "Valor / ISIN / Symbol / Underlying / Product type",
    "search": "Product search",
    "select_language": "Select a language",
    "semi-annually": "semi-Annually",
    "send_copy_to_email": "Send a copy to my E-Mail address",
    "send_message": "Send message",
    "settlementMethod": "Settlement type",
    "show_documents (not used anymore)": "Show documents",
    "show_product_detail_page": "Show product details",
    "show_product": "Show Product",
    "show_products": "Show Products",
    "shown_for_7_days": "Do not show this disclaimer for the next 7 days.",
    "six_symbol": "Symbol",
    "six_to_twelve_months": "6 - 12 months",
    "SoftCallableBarrierReverseConvertible": "Softcallable Barrier Reverse Convertible",
    "SoftCallableBarrierReverseConvertibles": "Softcallable Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertible": "Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertibles": "Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiReverseConvertible": "Softcallable Multi Reverse Convertible",
    "SoftCallableMultiReverseConvertibles": "Softcallable Multi Reverse Convertible",
    "SoftCallableReverseConvertible": "Softcallable Reverse Convertible",
    "SoftCallableReverseConvertibles": "Softcallable Reverse Convertible",
    "starting_after": "first time after",
    "static_data": "Basic data",
    "strike": "Strike",
    "structured_products_lukb": "Luzerner Kantonalbank Structured Products",
    "subscriptionPeriodEndDate": "Subscription End ",
    "subscriptionPeriodEndDateMobile": "Subscription End ",
    "tab_find_products": "Find<br/>products",
    "tab_opportunities_in_the_secondary_market": "Opportunities in<br/>the secondary market",
    "tab_opportunities_secondary_market_header": "Opportunities on the seconday market:  On this page you will find a selection of Barrier Reverse Convertibles, also with Early Redemption feature. The selection is based on predefined criteria (Price, Barrier and Yield to Call). All Products can be negotiated directly at the Exchange.",
    "tab_products_in_subscription": "Products<br/>in subscription",
    "tab_recently_issued": "Recently<br/>issued",
    "tab_yield_matrix_title": "The Yield Matrix is an added value working tool which allows you to find the most attractive Barrier Reverse Convertibles, Multi Barrier Reverse Convertibles as well as Autocallable/Softcallable Barrier Reverse Convertibles in the secondary market. Combine Underlyings, Risk Buffer and Tenor to find out the optimal investment solution for your needs. Click on the below yields sorted by remaining time and get directly to the corresponding product.",
    "tab_yield_matrix": "Yield Matrix",
    "termsheet_dl": "Download Termsheet",
    "final_terms_dl": "Download Final Terms",
    "termsheet": "Termsheet",
    "final_terms": "Final Terms",
    "third_level_filter_legend": "The available values refer to the minimum and maximum value of all products based on the selected filters",
    "three_to_six_months": "3 - 6 months",
    "top_of_page": "Top of page",
    "triggerLevel": "Autocall Trigger Level",
    "triggerLevelRefCurAbsolute": "Autocall Trigger",
    "true": "Yes",
    "underlying": "Underlying",
    "underlyingNames": "Underlyings",
    "valor": "Valor",
    "weekly": "weekly",
    "worstPerformanceUnderlying": "Worst performing underlying",
    "underlyingPerformance2ReferencePriceRelative": "Underlying Price Development since issuance",
    "worstUnderlyingPerformance2ReferencePriceRelative": "Worst underlying Price Development since issuance ",
    "write_message_desktop": "Write us<br />a message",
    "write_message_mobile": "Write us a message",
    "write_message_tooltip": "Write us a message",
    "write_us": "Write us",
    "year": "year",
    "years_dat": "years",
    "years": "years",
    "yes": "Yes",
    "YieldEnhancements": "Yield Enhancement",
    "YieldEnhancement": "Yield Enhancement",
    "your_message": "Your message",
    "YTD": "YTD",
    "barrier_observation_on_maturity": "Observation at expiry",
    "contact_success_message": "<b>Thanks for your Message</b><br />We will come back to you regarding your request as soon as possible.<br/>Your Luzerner Kantonalbank",
    "contact_error_message": "An error has occurred. Please try again later.",
    "lang_prospectus": "Prospekt",
    "prospectus": "Prospekt",
    "chart_error": "No Data available",
    "corporate_action_executed": "A Corporate Action has occurred. For more details please check the <a href='/services/notices'>Notices</a>.",
    "maturity_notification": "This product is expired.",
    "check_products_in_subscription": "Please check our products in subscription",
    "early_redeemed_notification": "This product was called for early redemption.",
    "customDateTime": "dd.MM.y, HH:mm 'h'",
    "prices_indicative": "Bid and Ask prices are indicative.",
    "footnote_text": "Due to the applied calculation method, jumps may occur in various key figures between the coupon ex date and the coupon payment date.",
    "product_filter_info_no_match": "Currently no products are matching the selected criteria.",
    "subscription_no_products": "Currently no products are in subscription. Take a look at our products in the secondary market.",
    "issued_no_products": "Currently there are no recently issued products available. Take a look at our products in the secondary market.",
    "barrier_touched_notification": "The barrier level was touched by {{touchedBarrierUnderlyingName}} on {{firstTouchedBarrierDate}}.",
    "product_details": "Product Details",
    "phone_disclaimer": "*Calls to this number are recorded.",
    "phone_disclaimer_contact": "*Calls to these numbers are recorded.",
    "cookiesBannerDisclaimer": "By clicking the OK button, you agree to the use of statistics and marketing cookies on our website. Further information can be found <a href='/cookies'>here</a>.",
    "contact_form": "Contact form: structuredproducts.lukb.ch",
    "capLevel": "Cap Level",
    "product_report": "Product report",
    "hours": "h",
    "tel": "Tel",
    "Hit": "Hit",
    "customDateTimePhpFormat": "d.m.Y, H:i \\h",
    "BarrierReverseConvertibleName": "LUKB Barrier Reverse Convertible",
    "BarrierReverseConvertibleCategory": "SSPA Category: 1230 - Yield Enhancement",
    "BarrierReverseConvertibleDescription": "LUKB Barrier Reverse Convertibles offer Investors the opportunity to take advantage from sideways or above the Barrier Level movements of the Underlying. These Products pay a guaranteed Coupon and offer a risk buffer until the Barrier Level. These Products do not contain any early redemption schedule.",
    "MultiBarrierReverseConvertibleName": "LUKB Multi Barrier Reverse Convertible",
    "MultiBarrierReverseConvertibleCategory": "SSPA Category: 1230 - Yield Enhancement",
    "MultiBarrierReverseConvertibleDescription": "LUKB Multi Barrier Reverse Convertibles offer Investors the opportunity to take advantage from sideways or above the Barrier Level movements of the Underlyings. These Products pay a guaranteed Coupon and offer a risk buffer until the Barrier Level, applied on the Worst Performing Underlying. These Products do not contain any early redemption schedule.",
    "AutocallableBarrierReverseConvertibleName": "LUKB Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibleCategory": "SSPA Category: 1230 - Yield Enhancement",
    "AutocallableBarrierReverseConvertibleDescription": "LUKB Autocallable Barrier Reverse Convertibles offer Investors the opportunity to take advantage from sideways or above the Barrier Level movements of the Underlying. These Products pay a guaranteed Coupon and offer a risk buffer until the Barrier Level. These Products can be automatically called for Early Redemption at 100.00% of the Nominal, depending on the level of the Underlying on any of the Autocall Observation Dates.",
    "AutocallableMultiBarrierReverseConvertibleName": "LUKB Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibleCategory": "SSPA Category: 1230 - Yield Enhancement",
    "AutocallableMultiBarrierReverseConvertibleDescription": "LUKB Autocallable Multi Barrier Reverse Convertibles offer Investors the opportunity to take advantage from sideways or above the Barrier Level movements of the Underlyings. These Products pay a guaranteed Coupon and offer a risk buffer until the Barrier Level, applied on the Worst Performing Underlying. These Products can be automatically called for Early Redemption at 100.00% of the Nominal, depending on the level of the Worst Performing Underlying on any of the Autocall Observation Dates.",
    "SoftCallableBarrierReverseConvertibleName": "LUKB Softcallable Barrier Reverse Convertible",
    "SoftCallableBarrierReverseConvertibleCategory": "SSPA Category: 1230 - Yield Enhancement",
    "SoftCallableBarrierReverseConvertibleDescription": "LUKB Softcallable Barrier Reverse Convertibles offer Investors the opportunity to take advantage from sideways or above the Barrier Level movements of the Underlying. These Products pay a guaranteed Coupon and offer a risk buffer until the Barrier Level. The Issuer has the right to call all Products for Early Redemption at 100.00% of the Nominal.",
    "SoftCallableMultiBarrierReverseConvertibleName": "LUKB Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertibleCategory": "SSPA Category: 1230 - Yield Enhancement",
    "SoftCallableMultiBarrierReverseConvertibleDescription": "LUKB Softcallable Multi Barrier Reverse Convertibles offer Investors the opportunity to take advantage from sideways or above the Barrier Level movements of the Underlyings. These Products pay a guaranteed Coupon and offer a risk buffer until the Barrier Level, applied on the Worst Performing Underlying. The Issuer has the right to call all Products for Early Redemption at 100.00% of the Nominal.",
    "ReverseConvertibleName": "LUKB Reverse Convertible",
    "ReverseConvertibleCategory": "SSPA Category: 1220 - Yield Enhancement",
    "ReverseConvertibleDescription": "LUKB Reverse Convertibles offer Investors the opportunity to take advantage from sideways or above the Strike Level movements of the Underlying. These Products pay a guaranteed Coupon and offer a risk buffer until the Strike Level. These Products do not contain any early redemption schedule.",
    "MultiReverseConvertibleName": "LUKB Multi Reverse Convertible",
    "MultiReverseConvertibleCategory": "SSPA Category: 1220 - Yield Enhancement",
    "MultiReverseConvertibleDescription": "LUKB Multi Reverse Convertibles offer Investors the opportunity to take advantage from sideways or above the Strike Level movements of the Underlyings. These Products pay a guaranteed Coupon and offer a risk buffer until the Strike Level, applied on the Worst Performing Underlying. These Products do not contain any early redemption schedule.",
    "AutocallableMultiReverseConvertibleName": "LUKB Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibleCategory": "SSPA Category: 1220 - Yield Enhancement",
    "AutocallableMultiReverseConvertibleDescription": "LUKB Autocallable Multi Reverse Convertibles offer Investors the opportunity to take advantage from sideways or above the Strike Level movements of the Underlyings. These Products pay a guaranteed Coupon and offer a risk buffer until the Strike Level, applied on the Worst Performing Underlying. These Products can be automatically called for Early Redemption at 100.00% of the Nominal, depending on the level of the Worst Performing Underlying on any of the Autocall Observation Dates.",
    "SoftCallableReverseConvertibleName": "LUKB Softcallable Reverse Convertible",
    "SoftCallableReverseConvertibleCategory": "SSPA Category: 1220 - Yield Enhancement",
    "SoftCallableReverseConvertibleDescription": "LUKB Softcallable Reverse Convertibles offer Investors the opportunity to take advantage from sideways or above the Strike Level movements of the Underlying. These Products pay a guaranteed Coupon and offer a risk buffer until the Strike Level. The Issuer has the right to call all Products for Early Redemption at 100.00% of the Nominal.",
    "SoftCallableMultiReverseConvertibleName": "LUKB Softcallable Multi Reverse Convertible",
    "SoftCallableMultiReverseConvertibleCategory": "SSPA Category: 1220 - Yield Enhancement",
    "SoftCallableMultiReverseConvertibleDescription": "LUKB Multi Softcallable Reverse Convertibles offer Investors the opportunity to take advantage from sideways or above the Strike Level movements of the Underlyings. These Products pay a guaranteed Coupon and offer a risk buffer until the Strike Level, applied on the Worst Performing Underlying. The Issuer has the right to call all Products for Early Redemption at 100.00% of the Nominal.",
    "CapitalProtectionWithParticipationName": "LUKB Capital Protected Certificates with Participation",
    "CapitalProtectionWithParticipationCategory": "SSPA Category: 1100 - Capital Protection",
    "CapitalProtectionWithParticipationDescription": "LUKB Capital Protected Certificates with Participation offer Investors the opportunity to take advantage from a rising Underlying, combined with a partial capital protection at maturity. These Products pay a minimum Redemption Amount equal to the Capital Protection Level plus an uncapped Participation on the performance of the Underlying above the Strike Level, both at Redemption Date.",
    "CapitalProtectionWithParticipationWorstOfName": "LUKB Capital Protected Certificates with Participation on Worst of",
    "CapitalProtectionWithParticipationWorstOfCategory": "SSPA Category: 1100 - Capital Protection",
    "CapitalProtectionWithParticipationWorstOfDescription": "LUKB Capital Protected Certificates with Participation on Worst of offer Investors the opportunity to take advantage from rising Underlyings, combined with a partial capital protection at maturity. These Products pay a minimum Redemption Amount equal to the Capital Protection Level plus an uncapped Participation on the performance of the Worst Performing Underlying above its Strike Level, both at Redemption Date.",
    "CapitalProtectionWithParticipationCappedWorstOf": "Capital Protection Note with Capped Participation on worst of",
    "CapitalProtectionWithConditionalMemoryCouponWorstOf": "Capital Protection Note with Conditional Memory Coupon",
    "range_0": "< 10.00%",
    "range_1": "10.00% - 20.00%",
    "range_2": "20.00% - 30.00%",
    "range_3": "30.00% - 40.00%",
    "range_4": "40.00% - 50.00%",
    "range_5": "> 50.00%",
    "php_date_iso8601_format": "Y-m-d\\TH:i:sP",
    "pdf_report_disclaimer": "The information contained herein is intended for informational purposes only and does not constitute an offer. Despite careful processing, Luzerner Kantonalbank offers no guarantee and accepts no liability for its accuracy.",
    "once_after": "once after",
    "observationDaysList": "Conditional Coupon Observation Dates",
    "conditionalCouponPa": "Conditional Coupon p.a.",
    "triggerObservationEndDate": "Conditional Coupon Observation Date",
    "triggerLevelRefCurPercent": "Coupon Trigger Level",
    "paymentDate": "Conditional Coupon Payment Dates",
    "paymentAmount": "Conditional Coupon Amounts",
    "paidAmount": "",
    "paid": "Paid",
    "paid_not": "Not paid",
    "maturity": "At maturity",
    "key_information_document": "KID",
    "kid_dl": "Download KID",
    "dividendHandling": "Dividend Handling",
    "Participation": "Participation",
    "distributing": "Cash compensation",
    "reinvesting": "Reinvested",
    "discounting": "Discounted",
    "ActivelyManagedTrackerCertificate": "Actively Managed Tracker Certificate",
    "ActivelyManagedTrackerCertificateName": "LUKB Actively Managed Tracker Certificate",
    "ActivelyManagedTrackerCertificateCategory": "SSPA Category: 1300 - Participation",
    "basketComponent": "Underlying",
    "weight": "Initial Weighting",
    "basketCompPerf2RefPriceRelative": "Performance since issuance",
    "coupon_has_not_triggered": "Coupon not triggered",
    "coupon_has_triggered": "Coupon triggered",
    "lifespan_text_callable": "Max. duration",
    "lifespan_text_non_callable": "duration",
    "newsletter_title": "Subscribe to the Newsletter",
    "newsletter_subtitle": "Subscribe to our weekly Newsletter and our monthly Trade Idea and stay always up to date.",
    "newsletter_please_choose_type": "Please select your desired newsletter:",
    "newsletter_example": "Example",
    "newsletter_products_in_subscription": "Products in Subscription (weekly)",
    "newsletter_products_in_subscription_link": "https://nbild.lukb.ch/gallery/K7FBhDT0IgPnAbNTI6/Demo_NL_KSP_EN.html",
    "newsletter_secondary_market_opportunities": "Secondary Market Opportunities (weekly)",
    "newsletter_secondary_market_opportunities_link": "https://nbild.lukb.ch/gallery/K7FBhDT0IgPnAbNTI6/Demo_NL_KSP_SEK_EN.html",
    "newsletter_form_field_gotoUrl": "https://structuredproducts.lukb.ch/services/newsletter-authentication",
    "newsletter_form_field_gotoConfirmationUrl": "https://structuredproducts.lukb.ch/services/newsletter-confirmation",
    "newsletter_investment_ideas": "Monthly Trade Idea",
    "newsletter_languages": "Newsletter language",
    "newsletter_languages_DE": "German",
    "newsletter_languages_EN": "English",
    "newsletter_languages_IT": "Italian",
    "newsletter_languages_FR": "French",
    "newsletter_please_choose_contact": "Please enter your contact details:",
    "newsletter_contact_title": "Salutation*",
    "newsletter_contact_mr": "Mr.",
    "newsletter_contact_mrs": "Ms.",
    "newsletter_contact_firstname": "Name*",
    "newsletter_contact_lastname": "Surname*",
    "newsletter_contact_company": "Company",
    "newsletter_contact_company_name": "Company",
    "newsletter_contact_email": "E-Mail*",
    "newsletter_privacy": "I confirm that I agree with the <a target=\"_blank\" href=\"/legal-disclaimers#terms-of-use-copyright\">terms of use</a> and the <a target=\"_blank\" href=\"/legal-disclaimers#-data-protection-security\">privacy policy</a>.",
    "newsletter_required": "All fields marked with * are mandatory fields. The transmission of your data is encrypted.",
    "newsletter_submit": "Subscribe",
    "newsletter_error_type": "Please select at least one newsletter",
    "newsletter_error_salutation": "Please select a form of address.",
    "newsletter_error_firstname": "Please insert a name.",
    "newsletter_error_lastname": "Please insert a surname.",
    "newsletter_error_email": "Please insert a valid e-mail-address.",
    "newsletter_error_privacy": "Please confirm the Privacy Policy",
    "newsletter_form_field_gotoErrorUrl": "https://structuredproducts.lukb.ch/services/mail-error",
    "quoting_method_value": "in units",
    "basket": "Basket",
    "am": " ",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCouponName": "LUKB Autocallable Multi Barrier Reverse Convertible with Conditional Memory Coupon",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCouponCategory": "SSPA Category: 1260 - Yield Enhancement",
    "CapitalProtectionWithConditionalMemoryCouponWorstOfName": "LUKB Capital Protection Note with Conditional Memory Coupon",
    "CapitalProtectionWithConditionalMemoryCouponWorstOfCategory": "SSPA Category: 1140 - Capital Protection",
    "CapitalProtectionWithConditionalCouponWorstOfName": "LUKB Capital Protection Note with Conditional Coupon",
    "CapitalProtectionWithConditionalCouponWorstOfCategory": "SSPA Category: 1140 - Capital Protection",
    "CapitalProtectionWithParticipationCappedName": "LUKB Capital Protection Note with Capped Participation",
    "CapitalProtectionWithParticipationCappedCategory": "SSPA Category: 1100 - Capital Protection",
    "CapitalProtectionWithParticipationCappedWorstOfName": "LUKB Capital Protection Note with Capped Participation on worst of",
    "CapitalProtectionWithParticipationCappedWorstOfCategory": "SSPA Category: 1100 - Capital Protection",
    "CapitalProtectionWithParticipationSumBasketName": "LUKB Capital Protection Note with Participation on",
    "CapitalProtectionWithParticipationSumBasketCategory": "SSPA Category: 1100 - Capital Protection",
    "CapitalProtectionWithParticipationCappedSumBasketName": "LUKB Capital Protection Note with Capped Participation on",
    "CapitalProtectionWithParticipationCappedSumBasketCategory": "SSPA Category: 1100 - Capital Protection",
    "Payment_date_dividend": "Payment Date",
    "absolute_payment": "Compensation Payment Amount",
    "dividends": "Compensation Payments",
    "performanceSinceIssuePercentWithoutDividends": "Price development since issuance",
    "DualCurrencyNote": "Dual Currency Note",
    "strikeLevelAndMaxReturn": "Cap Level and maximum Return",
    "maxReturn": "max. Rendite",
    "investmentCurrency": "Investment Currency",
    "alternativeCurrency": "Alternative Currency",
    "cashorphysicalForDualCurrencyNote": "Cash (in the Investment Currency) or conversion into the Alternative Currency",
    "physicalForDualCurrencyNote": "Conversion into the Alternative Currency",
    "chart_disclaimer_DualCurrencyNote": "Product performance, max. yield at expiry and max. yield p.a. are based on current prices.<br/>Underlying performance and underlying price are based on previous day's closing price."
};