//instrumentTable.directive.js
(function() {

    'use strict';

    angular
        .module('app.directives')
        .directive('instrumentTable', instrumentTable);

    instrumentTable.$inject = [
        '$timeout',
        '$state',
        'HistoryService',
        'TooltipService'
    ];

    function instrumentTable(
        $timeout,
        $state,
        HistoryService,
        TooltipService
    ) {
        var directive = {
            controller: controller,
            link: link,
            templateUrl: '/templates/instrumentTable.html',
            restrict: 'EA',
            scope: {
                data: '=',
                tpl: '=',
                menuFunc: '=',
                initialSorting: '=',
                activeTab: '=',
                applySortForAllGroups: '=',
                sortFunc: '=',
                tabKey: '=',
                tooltips: '='
            }
        };
        return directive;

        //controller
        function controller($scope) {

            //Goto product detail page
            $scope.goToProduct = function(item) {
                if (!item || !item.valor) {
                    return;
                }

                try {
                    if ($scope.data && $scope.data.id) {
                        var activeTab = null;
                        if ($scope.activeTab) {
                            activeTab = $scope.activeTab;
                        }

                        HistoryService.saveHistoryForGroup($scope.data.id, activeTab);
                    }

                } catch (e) {
                    console.log(e);
                }

                $timeout(function() {
                    $state.go('productDetail', {
                        identifier: 'valor',
                        identifierValue: item.valor.value
                    });
                });
            };

			$scope.getTooltip = TooltipService.getTooltip;
        }

        //Init
        function link(scope, el) {

            //Wait for dom is ready
            $timeout(function() {
                var jElement = $(el), btns = jElement.find('th span[order-by]');

                //Setup sort buttons
                $.each(btns, function() {

                    $(this).click(function() {
                        try {
                            var sortIndex; // Index necessary for backend query ('asc' == 1, 'desc' === -1)

                            scope.data.sortingKey = null;

                            //Remove initial active states
                            if (scope.initialSorting) {
                                jElement.find('.initial-active').removeClass('initial-active');
                                jElement.find('.initial-' + scope.initialSorting.sortBy).removeClass('initial-' + scope.initialSorting.sortBy);

                                if ($(jElement).find('th span[order-by="' + scope.initialSorting.noSort + '"]').hasClass('active-once')) {
                                    $(jElement).find('th span[order-by="' + scope.initialSorting.noSort + '"]').removeClass('active active-once');
                                }

                                if ($(jElement).find('td' + '.' + scope.initialSorting.noSort).hasClass('active-once')) {
                                    $(jElement).find('td' + '.' + scope.initialSorting.noSort).removeClass('active active-once');
                                }

                            }

                            scope.type = $(this).attr('order-by');
                            if (scope.sort === 'asc') {
                                scope.sort = 'desc';
                                sortIndex = -1;
                                scope.orderBy = scope.type + '.value';
                            } else {
                                scope.sort = 'asc';
                                sortIndex = 1;
                                scope.orderBy = scope.type + '.value';
                            }

                            // Append current sorting to data object
                            scope.data.sorting = {};
                            scope.data.sorting[scope.type] = sortIndex;


                            scope.data.sortingKey = scope.type;
                            scope.data.sortingIndex = sortIndex;

                            /* Enable / Disable Sorting of all instrument tables
                            if (scope.applySortForAllGroups) {
                                scope.applySortForAllGroups(scope.type, sortIndex);
                            }

                            scope.$apply();
                            */

                        } catch (e) {
                            console.log(e);
                        }


                        try {

                            //Calling SortGroup function from the instrument table directive
                            if (scope.sortFunc) {
                                scope.sortFunc(scope.data);
                            }

                        } catch (e) {
                            console.log(e);
                        }
                    });
                });

                // Set initial css classes if already sorted by the backend
                try {
                    if (scope.data && !(scope.data.sortingKey) && scope.initialSorting) {

                        // if no sort key exists, we do not do frontend sorting, just apply css styles to the given default column as we already have the sorted data from backend.
                        if (scope.initialSorting.noSort && scope.tabKey === 'inSubscription') {

                            // noSort contains the column that needs to be highlighted by default, we add class active once for default column case
                            $(jElement).find('th span[order-by="' + scope.initialSorting.noSort + '"]').addClass('active active-once');
                            $(jElement).find('td' + '.' + scope.initialSorting.noSort).addClass('active active-once');

                        } else {
                            scope.data.sortingKey = scope.initialSorting.orderBy;
                            scope.data.sortingIndex = scope.initialSorting.sortBy === 'desc' ? -1 : 1;
                            scope.sort = scope.initialSorting.sortBy;
                        }

                    }
                } catch (e) {
                    console.log(e);
                }
            });
        }
    }
})();